<template>
  <div class="bindInformation"
       :class="fromSource === 'intelligentAgentConfig' ? 'intelligentAgentBind' : ''">
    <div class="bind-askbot-client-top" v-if="channelType === 'askBot'">
      <div class="evaluate-table-switch">
        <el-switch
            @click.native.prevent="changeBotIdentify('switch')"
            :width="42"
            v-model="enableBotRecognize"
            active-color="#366AFF"
            inactive-color="#E2E2E2">>
        </el-switch>
        <span
            class="switch-open-icon"
            @click="changeBotIdentify('open')"
            v-if="enableBotRecognize">
          <i class="iconfont guoran-a-16-17"></i>
        </span>
        <span
            class="switch-close-icon"
            @click="changeBotIdentify('close')"
            v-if="!enableBotRecognize">
          <i class="arsenal_icon arsenalcuo1"></i>
        </span>
      </div>
      <span class="bind-askbot-client-tips-text">{{$t('botLink.askbotClientDrawer.text')}}</span>
    </div>
    <div class="bind-askBot"  v-if="fromSource !== 'dingGroupTable'">
      <div class="bind-askbot-client-bind-bot-title">
        <div class="bind-askbot-client-bind-bot-title-left">
          {{ $t('channel.intelligentAgent') }}
          <div class="add-bot" @click="addBindBot" v-if="channelType !== 'dingding' && channelType !== 'feishu' && channelType !== 'wechat'">
            <div class="add-btn">
              <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
            </div>
            <span>{{ $t('channel.addAgent') }}</span>
          </div>
        </div>
        <div class="bind-askbot-client-bind-bot-title-left" v-if="channelType === 'dingding' || channelType === 'feishu' || channelType === 'wechat'">
          机器人
        </div>
        <div class="title-center" v-if="fromSource !== 'intelligentAgentConfig'">{{ $t("botLink.consultationScope") }}</div>
        <div class="title-enterprise" v-if="fromSource !== 'intelligentAgentConfig' && isHaveKnowledge && isHaveAskKMS">{{ $t("botLink.enterpriseSearch") }}</div>
        <div class="operate">{{ $t('channel.operate') }}</div>
      </div>
      <template v-if="bindBots.length">
        <template v-if="channelType === 'dingding' || channelType === 'wechat' || channelType === 'feishu'">
          <div class="agentConfig" v-for="(item,index) in bindBots" :key="index">
            <div class="agentName" @click="goIntelligentAgentConfig(item.agentId)">
<!--              <img :src="item.logoUrl" style="width: 40px;height: 40px;object-fit: cover">-->
              <el-avatar shape="square" :size="40" :src="item.logoUrl"></el-avatar>
              <div class="name-des">
                <div class="name">{{item.agentName}}</div>
                <div class="des">{{item.description}}</div>
              </div>
              <i class="iconfont guoran-right"></i>
            </div>
            <bind-bot
                :userAttributeOptions="userAttributeOptions"
                :attrRelations="attrRelations"
                :tagsOptions="tagsOptions"
                :bindBotList="item.botInfos"
                :appCombineNoBindBots="combineNoBindBots"
                :noAskbotBind="true"
                :orgTree="orgTree"
                :fromSource="fromSource"
                @delBindBot="delBindBot"
                :ref="'bindBot' + index"
            ></bind-bot>
            <div class="right-enterprise"
                 v-if="fromSource !== 'intelligentAgentConfig' && isHaveKnowledge && isHaveAskKMS"
                 @click="openIdentification(item)"
                 :class="item.knowledgeScope && item.knowledgeScope.enableKnowledgeSearch ? 'configuration' : 'not-configured'"
            >
              {{ item.knowledgeScope && item.knowledgeScope.enableKnowledgeSearch ? '已配置' : '未配置' }}
            </div>
            <div class="del-bind-bot" v-show="$route.query.type === 'dingding' || $route.query.type === 'wechat' || $route.query.type === 'feishu'">
              <i  class="iconfont guoran-a-18-13"
                  @click="delBindBot(item,index,'')"></i>
            </div>
          </div>
        </template>
        <bind-bot
            v-else
            :userAttributeOptions="userAttributeOptions"
            :attrRelations="attrRelations"
            :tagsOptions="tagsOptions"
            :bindBotList="bindBots"
            :appCombineNoBindBots="combineNoBindBots"
            :noAskbotBind="true"
            :orgTree="orgTree"
            :fromSource="fromSource"
            @delBindBot="delBindBot"
            ref="bindBot"
        ></bind-bot>
      </template>
      <div v-else class="bind-askbot-client-bind-no-data">{{ $t("botLink.noBindBot") }}</div>
    </div>
    <template v-else>
      <template v-if="bindBots.length">
        <bind-bot
            :userAttributeOptions="userAttributeOptions"
            :attrRelations="attrRelations"
            :tagsOptions="tagsOptions"
            :bindBotList="bindBots"
            :appCombineNoBindBots="combineNoBindBots"
            :noAskbotBind="true"
            :orgTree="orgTree"
            :fromSource="fromSource"
            @delBindBot="delBindBot"
            @addBindBot="addBindBot"
            ref="bindBot"
        ></bind-bot>
      </template>
      <div v-else class="bind-askbot-client-bind-no-data">{{ $t("botLink.noBindBot") }}</div>
    </template>
    <div v-if="activeCommonEntry == 1 && askEntry != 1" @click="showMnDdLimitTip" class="bind-askbot-client-bind-mode"></div>
    <popup @closeEvent="identificationPopup = false" v-if="identificationPopup">
      <div slot="popup-name">{{ $t("botLink.setAuthority") }}</div>
      <div slot="popup-con" class="identification-container">
        <div class="identification">
          <div class="bind-askbot-client-top">
            <div class="evaluate-table-switch">
              <el-switch
                  @click.native.prevent="changeKnowledgeSearch('switch')"
                  :width="42"
                  v-model="enableKnowledgeSearch"
                  active-color="#366AFF"
                  inactive-color="#E2E2E2">
              </el-switch>
              <span
                  class="switch-open-icon"
                  @click="changeKnowledgeSearch('open')"
                  v-if="enableKnowledgeSearch">
            <i class="iconfont guoran-a-16-17"></i>
          </span>
              <span
                  class="switch-close-icon"
                  @click="changeKnowledgeSearch('close')"
                  v-if="!enableKnowledgeSearch">
            <i class="arsenal_icon arsenalcuo1"></i>
          </span>
            </div>
            <span class="bind-askbot-client-tips-text">{{$t('botLink.enterpriseKnowledgeSearch')}}</span>
          </div>
          <identification-condition ref="identification" :isAskBot="true" @saveKnowledgeScope="saveKnowledgeScope"></identification-condition>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button style="width: 100px" @click="closeIdentification" plain size="small">{{$t('common.cancel')}}</el-button>
        <el-button style="width: 100px" type="primary"  @click="saveIdentification" size="small">{{$t('common.confirm')}}</el-button>
      </div>
    </popup>
  </div>
</template>

<script>
import BindBot from "@/views/channelManage/components/bindBot";
import IdentificationCondition from "@/components/identificationCondition";
import Popup from "../../../components/popupNew";
export default {
  name: "bindInformation",
  components: { Popup, IdentificationCondition, BindBot },
  data(){
    return{
      userAttributeOptions:[{
        value:"ALL",
        label:"所有人",
      },{
        value:"STAFF",
        label:"员工",
        children:[{
          value:"STAFF_OR_DEPARTMENT",
          label:"员工 / 部门"
        },{
          value:"CUSTOMIZE",
          label:"标签",
          key: 'label',
          type: "SELECT"
        }]
      }],
      attrRelations: [
        { name: "为空", value: "IS_NULL" },
        { name: "不为空", value: "IS_NOT_NULL" },
        { name: "包含任意", value: "CONTAIN_ANY" },
        { name: "不包含任意", value: "NOT_CONTAIN_ANY" },
      ],
      tagsOptions:[],
      bindBots: [],
      combineNoBindBots: [],
      orgTree: [],
      noBindBots: [],
      activeCommonEntry: 0,
      askEntry: 0,
      enableKnowledgeSearch:true, // 是否开启企业知识智能搜索
      channelType:"",
      bindData:{},
      relationIntents:[],
      bindBotsCopy:[],
      isHaveKnowledge:false,
      enableBotRecognize:false,
      identificationPopup:false,
      tagListIds:[],
      knowledgeScope: {
        // 结构与旧的知识权限配置相同
        "bindId": "" ,//当前应用的 id (agent.id) ,
        "bindCode": "", //当前应用的 bindCode(agent.bindCode),
        "enableKnowledgeSearch": false,
        "pluginToolInfo": null,
        "knowledgeSearchItems": null,
        "conditions": [
          {
            "expect": [],
            "type": "ALL",
            "key": null,
            "relation": null
          }
        ],
        "knowledgeScopeConditions": [
          {
            "variable": "",
            "relation": "",
            "value": [],
            "extInfo": {}
          },
        ],
        "enableLlmResponse": null
      },
      editIntelligentAgent:null,
      isHaveAskKMS:false
    }
  },
  props:{
    bindDetail:{
      type:Object,
      default(){
        return {}
      }
    },
    fromSource:{
      type:String,
      default:""
    }
  },
  methods:{
    delBindBot(item,index){
      this.bindBots.splice(index,1);
    },
    handleCheckedBotsChange(value, item, type) {
      console.log(value, item, type)
      let ids = []
      let botType = "";
      if(type !== 'del'){
        this.noBindBots.forEach((v, i) => {
          if (value == v.id && !v.isChecked) {
            item.apiKey = v.apiKey;
            item.name = v.name;
            item.botId = value;
            item.isChecked = true;
            setTimeout(() => {
              this.checkedBots = [];
            }, 200);
          }

        });

        // 新增 知识机器人
        this.combineNoBindBots.forEach(cBots => {
          cBots.children.forEach( v =>{
            if (value == v.id && !v.isChecked) {
              item.apiKey = v.apiKey || "";
              item.name = v.name;
              item.botId = value;
              item.isChecked = true;

              botType = v.botType;

              setTimeout(() => {
                this.checkedBots = [];
              }, 200);
            }
          })
        })
      }
      this.bindBots.forEach(item => {
        console.log(item);
        ids.push(item.botId)
        if(item.botId == value){
          item.botType = botType;
        }
      })
      this.noBindBots.forEach((allItem,allIndex) => {
        if(ids.indexOf(allItem.id) !== -1){
          allItem.isChecked = true
        } else {
          allItem.isChecked = false
        }
      })
    },
    // 获取自定义标签数据
    getTagsOptions(corpId, callBack){
      this.$http.get(this.requestUrl.bindV2.getTagsOptions).then((res) => {
        console.log('getTagsOptions',res)
        if (res.data.code == 0) {
          this.tagsOptions = res.data.data;
          if (callBack) {
            let tagListIds = [];
            if (this.tagsOptions && this.tagsOptions.length != 0) {
              tagListIds = this.tagsOptions.map(item => item.id);
            }
            localStorage.setItem("ask_tagListIds", tagListIds);
            callBack(tagListIds);
          }
        }
      })
    },
    // 获取机器人列表
    getBotList() {
      let combineNoBindBots = [
        {
          name: "意图机器人",
          id: "0",
          children: [],
        },
        {
          name: "知识机器人",
          id: "1",
          children: [],
        }
      ];
      this.$http.get(this.requestUrl.bot.getBotList, {
        page: 1,
        size: 100,
      }).then((res) => {
        console.log('getBotList',res)
        if (res.data.code === "0") {
          this.noBindBots = res.data.data.list;
          this.noBindBots.forEach(bot => {
            bot.isChecked = false;
            bot.scope = 0;
            bot.botType = 0; // 区分 意图机器人 0 知识机器人 10
            bot = JSON.parse(JSON.stringify(bot));
          })
          combineNoBindBots[0].children = this.noBindBots;
          this.$http.get('/api-open/bot/list/llm').then((res) => {
            let tampBots = [...res.data.data];
            tampBots = tampBots.filter(item => {
              return item.llmBot
            })
            let tampKnBots = [];
            tampBots.forEach(bot => {
              bot.bot.isChecked = false;
              bot.bot.scope = 0;
              bot.bot.botType = 10; // 区分 意图机器人 0 知识机器人 1
              bot.bot.llmBot = bot.llmBot;
              bot.bot.name = bot.llmBot ? bot.llmBot.llmBot.name : '';
              tampKnBots.push(JSON.parse(JSON.stringify(bot.bot)))
            })
            combineNoBindBots[1].children = tampKnBots;
            this.combineNoBindBots = [...combineNoBindBots];
            this.initBindBot(this.bindDetail);
          });
          console.log(this.noBindBots,'noBindBotsnoBindBots');
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 获取部门树
    getDepartmentTree(){
      let userId = localStorage.getItem("_uid");
      let url = "/api/department/corpListLazy" + "?userId=" + userId;
      this.$http.get(url).then(res => {
        console.log('getDepartmentTree',res)
        this.orgTree = res;
      })
    },
    // 获取上下游数据
    getOrganization(){
      let url = this.requestUrl.wechatKF.selectOrganization;
      this.$http.get(url).then((res) => {
        console.log('getOrganization',res)
        if (res.data.code === "0") {
          let arr = res.data.data;
          this.defaultExpandAll = true;
          const formatDepartmentTree = (arr) => {
            arr.forEach((item) => {
              item.checked = false;
              if (item.children && item.children != 0) {
                item.children = formatDepartmentTree(item.children)
              }
            })
            return arr;
          }
          this.orgTree = formatDepartmentTree(arr);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    showMnDdLimitTip(){
      this.$message.warning(this.$t('channel.connection'));
    },
    addBindBot(){
      if (this.channelType === 'website'){
        if (this.bindData.bindBot && this.bindData.bindBot.botInfos.length === 0){
          this.$message.error(this.$t('channel.noBindBotTip'));
        } else if (!this.bindData.needLogin && this.bindData.bindBot && this.bindData.bindBot.botInfos.length === 1){
          this.$message.error(this.$t('channel.website.errorMsg'));
        } else {
          this.bindBots.push({
            apiKey: [],
            botId: '',
            name: '',
            scope: 0,
            items: [],
            botType:0,
            conditions:[{
              visible:false,
              type:'ALL',
              expect:[],
              key:"",
              relation:"CONTAIN_ANY",
            }]
          })
        }
        this.scrollToSet();

      } else {
        this.bindBots.push({
          apiKey: [],
          botId: '',
          name: '',
          scope: 0,
          items: [],
          botType:0,
          conditions:[{
            visible:false,
            type:'ALL',
            expect:[],
            key:"",
            relation:"CONTAIN_ANY",
          }]
        })
        this.scrollToSet();
      }
      // else {
      //   this.bindBots.push({
      //     apiKey: [],
      //     botId: '',
      //     name: '',
      //     scope: 0,
      //     items: [],
      //   })
      //   this.scrollToSet();
      // }
      console.log('bindBots',this.bindBots)
    },
    scrollToSet(){
      const id = 'conditions' + (this.bindBots.length - 1);
      let parent = document.getElementById('abilityManage')
      this.$nextTick(() => {
        setTimeout(() => {
          // let target = document.getElementById(id);
          // parent.scrollTo(0,target.offsetTop - parent.offsetTop);
          // console.log('parent',parent.offsetTop,target.offsetTop)
          document.getElementById(id).scrollIntoView({behavior:"smooth"})
        },300)
      })
    },
    // 修改企业知识智能搜索开关
    changeKnowledgeSearch(value){
      if(value === 'open'){
        this.enableKnowledgeSearch = false;
      } else if (value === 'close'){
        this.enableKnowledgeSearch = true;
      }
    },

    initBindBot(item){
      if (this.channelType === 'dingding' || this.channelType === 'feishu' || this.channelType === 'wechat'){
        let agentBindBotInfos = []
        agentBindBotInfos = (item.wechatBindAgent && item.wechatBindAgent.agentBindBotInfos) ? item.wechatBindAgent.agentBindBotInfos : [];
        agentBindBotInfos.forEach((agent,agentIndex) => {
          if (agent.bindBotInfos){
            agent.bindBotInfos.forEach(_item => {
              if(_item.conditions){
                _item.conditions.forEach(_conditionsItem => {
                  _conditionsItem.visible = false;
                })
              } else {
                _item.conditions = [{
                  visible:false,
                  type:'ALL',
                  expect:[],
                  key:"",
                  relation:"CONTAIN_ANY",
                }]
              }
            })
          }
          this.$set(agent,'botInfos',agent.bindBotInfos)
          this.$nextTick(() => {
            setTimeout(() => {
              if (this.$refs['bindBot' + agentIndex]){
                this.$refs['bindBot' + agentIndex][0].initBotStatus()
              }
            },500)
          })
        })
        this.bindBots = JSON.parse(JSON.stringify(agentBindBotInfos));
        this.bindBotsCopy = JSON.parse(JSON.stringify(this.bindBots));
      } else {
        if (item.bindBot && item.bindBot.botInfos){
          if (this.channelType === 'askBot'){
            item.bindBot.botInfos.forEach(items => {
              //   "type": // 范围对应类型 1：部门 0：成员
              //    "source" : // 数据来源 MEMBER（员工），UPDOWN（上下游）
              //  selfType -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
              // scope, // 咨询范围 0：所有人 1：指定范围
              if(items.scope === 1){
                items.items.forEach(v => {
                  v.label = v.name;
                  if(v.source === 'MEMBER' && v.type === 0){
                    v.selfType = 1;
                  } else if(v.source === 'MEMBER' && v.type === 1){
                    v.selfType = 0;
                  } else if(v.source === 'UPDOWN' && v.type === 0){
                    v.selfType = 3;
                  } else if(v.source === 'UPDOWN' && v.type === 1){
                    v.selfType = 2;
                  }
                })
                items.memberInfo4Client = {
                  clientInfo: items.items
                }
              } else {
                items.memberInfo4Client = {
                  clientInfo: []
                }
              }

            })
          } else {
            item.bindBot.botInfos.forEach(_item => {
              if(_item.conditions){
                _item.conditions.forEach(_conditionsItem => {
                  _conditionsItem.visible = false;
                })
              } else {
                _item.conditions = [{
                  visible:false,
                  type:'ALL',
                  expect:[],
                  key:"",
                  relation:"CONTAIN_ANY",
                }]

              }
            })
          }

          this.bindBots = JSON.parse(JSON.stringify(item.bindBot.botInfos));
          this.bindBotsCopy = JSON.parse(JSON.stringify(this.bindBots));
        }
        this.$nextTick(() => {
          console.log('bindBot',this.$refs.bindBot)
          setTimeout(() => {
            if (this.$refs.bindBot){
              this.$refs.bindBot.initBotStatus()
            }
          },500)
        })
      }


      this.getTagsOptions( item.corpId, (tagListIds) => {
        this.tagListIds = tagListIds;
        this.bindBots.forEach((botCell, index) => {
          let flag = false;
          this.noBindBots.forEach((cell, cellIndex) => {
            if (botCell.botId == cell.id || cell.botId == botCell.botId) {
              cell.isChecked = true;
              flag = true;
            }
          })
          if(!flag && botCell.botType != 10){
            this.noBindBots.push({
              name: botCell.name,
              id: botCell.botId
            })
          }
          console.log('botCell',botCell)
          // 条件回显
          if (this.channelType === 'dingding' || this.channelType === 'wechat' || this.channelType === 'feishu'){
            botCell.botInfos.forEach(bot => {
              bot.conditions.forEach(conditionCell => {
                if(conditionCell.type == "CUSTOMIZE") {
                  if(conditionCell.key == 'label'){
                    if (conditionCell.expect && conditionCell.expect.length != 0) {
                      conditionCell.expect = conditionCell.expect.filter(cell => tagListIds.includes(cell));
                      conditionCell.expect = [...new Set(conditionCell.expect)];
                    } else {
                      conditionCell.expect = [];
                    }
                  }

                  if(conditionCell.key != 'label' && conditionCell.key != 'personalJobLevel' && conditionCell.key != '入职时间'){
                    conditionCell.expect = conditionCell.expect[0];
                  }
                  if(conditionCell.key == '入职时间'){
                    if(conditionCell.relation == 'BEFORE' || conditionCell.relation == 'AFTER'){
                      conditionCell.expect = conditionCell.expect[0];
                    }
                  }
                }
              })
            })
            // botCell.botInfos = botCell.bindBotInfos
          } else {
            botCell.conditions.forEach(conditionCell => {
              if(conditionCell.type == "CUSTOMIZE") {
                if(conditionCell.key == 'label'){
                  if (conditionCell.expect && conditionCell.expect.length != 0) {
                    conditionCell.expect = conditionCell.expect.filter(cell => tagListIds.includes(cell));
                    conditionCell.expect = [...new Set(conditionCell.expect)];
                  } else {
                    conditionCell.expect = [];
                  }
                }

                if(conditionCell.key != 'label' && conditionCell.key != 'personalJobLevel' && conditionCell.key != '入职时间'){
                  conditionCell.expect = conditionCell.expect[0];
                }
                if(conditionCell.key == '入职时间'){
                  if(conditionCell.relation == 'BEFORE' || conditionCell.relation == 'AFTER'){
                    conditionCell.expect = conditionCell.expect[0];
                  }
                }
              }
            })
          }
        })
        this.$nextTick(() => {
          if (this.$refs.identification){
            this.$refs.identification.tagsOptions = this.tagsOptions;
            if (item.knowledgeScope){
              this.enableKnowledgeSearch = item.knowledgeScope.enableKnowledgeSearch;
              if (item.knowledgeScope.conditions){

                // 脏数据处理 回显过滤已删除标签
                let conditions = JSON.parse(JSON.stringify(item.knowledgeScope.conditions));
                conditions.forEach(conditionCell => {
                  if(conditionCell.key == 'label'){
                    if (conditionCell.expect && conditionCell.expect.length != 0) {
                      conditionCell.expect = conditionCell.expect.filter(cell => tagListIds.includes(cell));
                      conditionCell.expect = [...new Set(conditionCell.expect)];
                    } else {
                      conditionCell.expect = [];
                    }
                  }
                })

                this.$refs.identification.pluginCondition = conditions
              }
              if (item.knowledgeScope.knowledgeScopeConditions){
                this.$refs.identification.$refs.dataSet.dataSetCondition = JSON.parse(JSON.stringify(item.knowledgeScope.knowledgeScopeConditions))
              }
              if (item.knowledgeScope.pluginToolInfo){
                this.$refs.identification.pluginToolInfo = JSON.parse(JSON.stringify(item.knowledgeScope.pluginToolInfo))
              }
            }
          }
        })

      });
    },

    openIdentification(item){
      this.identificationPopup = true;
      this.knowledgeScope.bindId = this.bindDetail.id;
      this.knowledgeScope.bindCode = this.bindDetail.bindCode;
      this.editIntelligentAgent = item;
      this.$nextTick(() => {
        if (this.$refs.identification){
          this.$refs.identification.tagsOptions = this.tagsOptions;
          if (!item.knowledgeScope){
            this.$set(item,'knowledgeScope',this.knowledgeScope)
          }
          if (item.knowledgeScope){
            this.knowledgeScope = JSON.parse(JSON.stringify(item.knowledgeScope));
            this.enableKnowledgeSearch = item.knowledgeScope.enableKnowledgeSearch;
            if (item.knowledgeScope.conditions){

              // 脏数据处理 回显过滤已删除标签
              let conditions = JSON.parse(JSON.stringify(item.knowledgeScope.conditions));
              conditions.forEach(conditionCell => {
                if(conditionCell.key == 'label'){
                  if (conditionCell.expect && conditionCell.expect.length != 0) {
                    conditionCell.expect = conditionCell.expect.filter(cell => this.tagListIds.includes(cell));
                    conditionCell.expect = [...new Set(conditionCell.expect)];
                  } else {
                    conditionCell.expect = [];
                  }
                }
              })

              this.$refs.identification.pluginCondition = conditions
            }
            if (item.knowledgeScope.knowledgeScopeConditions){
              this.$refs.identification.$refs.dataSet.dataSetCondition = JSON.parse(JSON.stringify(item.knowledgeScope.knowledgeScopeConditions))
            }
            if (item.knowledgeScope.pluginToolInfo){
              this.$refs.identification.pluginToolInfo = JSON.parse(JSON.stringify(item.knowledgeScope.pluginToolInfo))
            }
          }
        }
      })

    },
    closeIdentification(){
      this.identificationPopup = false;
    },
    saveIdentification(){
      this.editIntelligentAgent.knowledgeScope.enableKnowledgeSearch = this.enableKnowledgeSearch;
      this.editIntelligentAgent.knowledgeScope.conditions = this.$refs.identification.pluginCondition;
      this.editIntelligentAgent.knowledgeScope.knowledgeScopeConditions = this.$refs.identification.$refs.dataSet.dataSetCondition;
      this.editIntelligentAgent.knowledgeScope.pluginToolInfo = this.$refs.identification.pluginToolInfo;
      this.identificationPopup = false;
    },
    setAgentBind(item){
      let obj = {
        agentId:item.id,
        agentName:item.agentName,
        description:item.description,
        logoUrl:item.logoUrl,
        bindBotInfos:(item.bindBot && item.bindBot.agentBindBotInfos) ? item.bindBot.agentBindBotInfos : [{
          "botId": 1494,
          "apiKey": "398ba0d1857644d288a39dff6248ee63",
          "name": "测试用机器人",
          "scope": 0,
          "items": [],
          "conditions": [
            {
              "expect": [],
              "type": "ALL",
              "key": "",
              "relation": "CONTAIN_ANY"
            }
          ],
          "botProfilePhoto": null,
          "mainId": null,
          "botType": 0,
          "plugins": null
        }],
        botInfos:[],
      }
      obj.bindBotInfos.forEach(_item => {
        if(_item.conditions){
          _item.conditions.forEach(_conditionsItem => {
            _conditionsItem.visible = false;
          })
        } else {
          _item.conditions = [{
            visible:false,
            type:'ALL',
            expect:[],
            key:"",
            relation:"CONTAIN_ANY",
          }]
        }
      });
      obj.botInfos = obj.bindBotInfos;
      this.bindBots.push(obj);
      console.log('bindBots',this.bindBots)
    },
    // 是否有知识管理权限
    getUserCompanyModule(){
      this.$http.get(this.requestUrl.bot.getUserCompanyModule).then(res =>{
        if(res.data.code == '0' && res.data.data) {
          res.data.data.modules.forEach(item => {
            if(item.moduleCode === "KNOWLEDGE"){
              this.isHaveKnowledge = true;
            }
          })
        }
      })
    },
    // 修改机器人识别开关
    changeBotIdentify(value){
      if(value === 'open'){
        this.enableBotRecognize = false;
      } else if (value === 'close'){
        this.enableBotRecognize = true;
      }
    },
    goIntelligentAgentConfig(id){
      sessionStorage.setItem('previousActive','ability');
      this.$router.push({
        path:"/intelligentAgentConfig",
        query:{
          id:id
        }
      })
    }
  },
  mounted() {
    this.channelType = this.$route.query.type
    this.getTagsOptions();
    this.getDepartmentTree();
    this.getBotList();
    this.getUserCompanyModule();
    let systemModelList = sessionStorage.getItem('systemModelList') ? JSON.parse(sessionStorage.getItem('systemModelList')) : null;
    if (systemModelList){
      let index = systemModelList.find(item => item.code === 'ASKAI');
      if (index){
        this.isHaveAskKMS = true
      }
    }
  },
  watch:{
    bindDetail:{
      handler(){
        this.bindData = this.bindDetail;
        if (this.bindDetail.bindBot) {
          // this.initBindBot(this.bindDetail);
        }
      },
      immediate:true
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/less/common";
.bindInformation {
  position: relative;
  .bind-askbot-client-top{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .bind-askbot-client-tips-text{
      padding-left: 10px;
    }
  }
  .bind-askBot{
    border: 1px solid #E0E6F7;
    border-radius: 5px;
    .bind-askbot-client-bind-bot-title {
      height: 39px;
      //background: #F6F8FD;
      border-radius: 5px 5px 0px 0px;
      //font-weight: bolder;
      display: flex;
      align-items: center;
      padding: 0 10px 0 10px;
      margin-bottom: 8px;
      background: #F6F8FD;
      .bind-askbot-client-bind-bot-title-left {
        flex: none;
        width: 280px;
        margin-right: 12px;
        display: flex;
        align-items: center;

        .add-bot {
          margin-left: 14px;
          display: flex;
          align-items: center;
          cursor: pointer;
          flex: none;
          color: #366aff;

          .add-btn {
            flex: none;
            width: 20px;
            height: 20px;
            background-color: #366aff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            margin-right: 8px;

            i {
              color: #ffffff;
              font-size: 12px;
            }
          }
        }
      }

      .title-center {
        flex: 1;
      }
      .title-enterprise{
        width: 80px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .operate {
        flex: none;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
    .agentConfig{
      display: flex;
      //align-items: center;
      padding: 10px 0 10px 10px;
      .agentName{
        flex: none;
        width: 280px;
        display: flex;
        margin-right: 12px;
        cursor: pointer;
        .name-des{
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          width: 220px;
          .name{
            margin-bottom: 6px;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
          }
          .des{
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
            color: #A9B3C6;
          }
        }
        .guoran-right{
          color: #366aff;
          margin-top: 12px;
        }
      }
      .bind_bot{
        flex: 1;
      }
      .right-enterprise{
        flex: none;
        display: flex;
        justify-content: flex-start;
        width: 70px;
        margin-top: 10px;
        cursor:pointer;
      }
      .configuration{
        color: #366aff;
      }
      .not-configured{
        color: red;
      }
      .del-bind-bot {
        display: flex;
        //justify-content: center;
        width: 30px;
        flex: none;

        .guoran-tongyichicun-16-09-shanchu2 {
          cursor: pointer;
          color: #366AFF;
        }

        .guoran-a-18-13 {
          color: red;
          cursor: pointer;
          margin-top: 10px;
        }
      }
    }
    .bind-askbot-client-bind-no-data{
      color: #606266;
      text-align: center;
      margin: 14px 0;
    }
  }
  .bind-askbot-client-bind-mode {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .add-bind-bot-btn{
    display: flex;
    align-items: center;

    margin-top: 20px;
    /deep/.el-button{
      width: 34px;
      height: 34px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    span{
      cursor: pointer;
      color: #366AFF;
    }
  }
  .identification{
    margin-top: 40px;
    .bind-askbot-client-tips-text{
      margin-left: 20px;
    }
    .bind-askbot-client-top{
      display: flex;
      align-items: center;
    }
  }
}
.intelligentAgentBind{
  width: 50%;
  .bind-askBot{
    .bind-askbot-client-bind-bot-title{
      justify-content: space-between;
    }
    /deep/.bind-askbot-client-bind-bot-item{
      .bind-askbot-client-bind-bot-item-content{
        justify-content: space-between;
      }
      .del-bind-bot{
        justify-content: center;
      }
    }
  }
}
.el-button--primary {
  background-color: #366aff;
  color: #FFFFFF;
}
</style>