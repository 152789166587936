<template>
  <div class="basicInfo">
    <div class="title">
      <span>接入信息</span>
      <div class="doc"
           v-if="channelType === 'dingdingGroup'"
           @click="goHelp"
      >
        <i class="iconfont guoran-tongyichicun-dianjichakantuli"></i>
        查看接入文档
      </div>
    </div>
    <!--  bindType == 0 表示是第三方的  1 表示自建  -->
    <!--  0 企业微信  -->
    <template v-if="channelType === 'wechat' && Object.keys(bindDetail).length > 0">
      <template v-if="bindDetail.bindType == 0">
        <div class="commonBg">
          <div class="name">
            {{$t('channel.wechat.agentName')}}：{{bindDetail.agentName}}
          </div>
          <div class="unBind" @click="unBindFun" v-if="false">
            <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
            <span>{{$t('channel.unBind')}}</span>
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.corpName')}}：{{bindDetail.corpName}}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.wechat.agentName')}}：{{bindDetail.agentName}}
          </div>
          <div class="unBind" @click="unBindFun">
            <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
            <span>{{$t('channel.unBind')}}</span>
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.corpName')}}：{{bindDetail.corpName}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.corpId')}}：{{bindDetail.corpId}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            AgentID：{{bindDetail.agentId}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            APPSecret：{{bindDetail.secret || '--'}}
          </div>
        </div>
        <access-info :bindDetail="bindDetail"></access-info>
      </template>
    </template>
    <!--  1 公众号  -->
    <template v-else-if="channelType === 'wechatAccount'">
      <div class="commonBg">
        <div class="name">
          {{$t('channel.wechatAccount.agentName')}}：{{bindDetail.agentName}}
        </div>
        <div class="unBind" @click="unBindFun">
          <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
          <span>{{$t('channel.unBind')}}</span>
        </div>
      </div>
      <div class="commonBg domain">
        <div class="name">
          {{$t('channel.wechatAccount.updateTime')}}：{{new Date(bindDetail.updateTime).Format('yyyy-MM-dd hh:mm:ss')}}
        </div>
      </div>
      <access-info :bindDetail="bindDetail"></access-info>
    </template>
    <!--  2 小程序  -->
    <template v-else-if="channelType === 'miniProgram'">
      <div class="commonBg">
        <div class="name">
          {{$t('channel.miniProgram.botName')}}：{{bindDetail.name}}
        </div>
        <div class="unBind" @click="unBindFun">
          <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
          <span>{{$t('channel.unBind')}}</span>
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          {{$t('channel.miniProgram.code')}}
        </div>
      </div>
      <img :src="miniAppCodeUrl" style="height: 200px">
      <div class="download" @click="miniAppCodeHandle('downLoad')">{{$t('channel.miniProgram.downLoad')}}</div>
    </template>
    <!--  3 钉钉  -->
    <template v-else-if="channelType === 'dingding' && Object.keys(bindDetail).length > 0">
      <template v-if="bindDetail.bindType == 0">
        <div class="commonBg">
          <div class="name">
            {{$t('channel.dingding.agentName')}}：{{bindDetail.agentName}}
          </div>
          <div class="unBind"
               v-if="RP_Visible('OPEN_ACCESS_DING') && false"
               @click="unBindFun"
          >
            <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
            <span>{{$t('channel.unBind')}}</span>
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.corpName')}}：{{bindDetail.corpName}}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.dingding.agentName')}}：{{bindDetail.agentName}}
          </div>
          <div class="unBind"
               v-if="RP_Visible('OPEN_ACCESS_DING') && (!bindDetail.thirdId || bindDetail.thirdId === '')"
               @click="unBindFun"
          >
            <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
            <span>{{$t('channel.unBind')}}</span>
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.corpName')}}：{{bindDetail.corpName}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.corpId')}}：{{bindDetail.corpId}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            AgentID：{{bindDetail.agentId}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            AppKey：{{bindDetail.appKey || '--' }}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            APPSecret：{{bindDetail.secret || '--'}}
          </div>
        </div>
        <access-info :bindDetail="bindDetail"></access-info>
      </template>
    </template>
    <!--  4 web网站  -->
    <template v-else-if="channelType === 'website'">
      <div class="bind commonBg">
        <div class="name">
          {{$t('channel.website.agentName')}}：{{bindDetail.agentName}}
        </div>
        <div class="unBind" @click="unBindFun">
          <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
          <span>{{$t('channel.unBind')}}</span>
        </div>
      </div>
      <div class="commonBg domain">
        <div class="name">
          {{$t('channel.website.agentId')}}：{{bindDetail.agentId}}
        </div>
      </div>
      <access-info :bindDetail="bindDetail"></access-info>
    </template>
    <!--  6 钉钉群-->
    <template v-else-if="channelType === 'dingdingGroup' && Object.keys(bindDetail).length > 0">
      <template v-if="bindDetail.bindType == 0">
        <div class="commonBg">
          <div class="name">
            {{$t('channel.dingdingGroup.agentName')}}：{{bindDetail.agentName}}
          </div>
          <div class="unBind" @click="unBindFun" v-if="false">
            <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
            <span>{{$t('channel.unBind')}}</span>
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.corpName')}}：{{bindDetail.corpName}}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.dingdingGroup.agentName')}}：{{bindDetail.agentName}}
          </div>
          <div class="unBind" @click="unBindFun">
            <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
            <span>{{$t('channel.unBind')}}</span>
          </div>
        </div>
        <div class="commonBg" v-if="false">
          <div class="name">
            {{$t('channel.corpName')}}：{{bindDetail.corpName}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.dingdingGroup.groupTemplateId')}}：{{bindDetail.groupTemplateId}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.dingdingGroup.groupRobotCode')}}：{{bindDetail.groupRobotCode}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            {{$t('channel.corpId')}}：{{bindDetail.corpId}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            AgentID：{{bindDetail.agentId}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            AppKey：{{bindDetail.appKey || '--'}}
          </div>
        </div>
        <div class="commonBg">
          <div class="name">
            APPSecret：{{bindDetail.secret || '--'}}
          </div>
        </div>
      </template>
    </template>
    <!--  9钉钉单聊  -->
    <template v-else-if="channelType === 'dingSingleChatBot'">
      <div class="commonBg">
        <div class="name">
          {{$t('channel.dingSingleChatBot.agentName')}}：{{bindDetail.agentName}}
        </div>
        <div class="unBind" @click="unBindFun">
          <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
          <span>{{$t('channel.unBind')}}</span>
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          {{$t('channel.corpName')}}：{{bindDetail.corpName}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          {{$t('channel.corpId')}}：{{bindDetail.corpId}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          AgentID：{{bindDetail.agentId}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          AppKey：{{bindDetail.appKey || '--'}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          APPSecret：{{bindDetail.secret || '--'}}
        </div>
      </div>
      <access-info :bindDetail="bindDetail"></access-info>
    </template>
    <!--  飞书  -->
    <template v-else-if="channelType === 'feishu'">
      <div class="commonBg">
        <div class="name">
          {{$t('channel.feishu.agentName')}}：{{bindDetail.agentName}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          {{$t('cnannel.corpName')}}：{{bindDetail.corpName}}
        </div>
      </div>
    </template>
    <!-- 云之家   -->
    <template v-else-if="channelType === 'yunzhijia'">
      <div class="commonBg">
        <div class="name">
          {{$t('channel.yunzhijia.agentName')}}：{{bindDetail.agentName}}
        </div>
        <div class="unBind" @click="unBindFun">
          <i class="iconfont guoran-tongyichicun-jiechubangding"></i>
          <span>{{$t('channel.unBind')}}</span>
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          {{$t('channel.corpName')}}：{{bindDetail.corpName}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          {{$t('channel.yunzhijia.corpId')}}：{{bindDetail.corpId}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          APP ID：{{bindDetail.agentId}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          App Secret：{{bindDetail.secret}}
        </div>
      </div>
      <div class="commonBg">
        <div class="name">
          Sign Key：{{bindDetail.appKey}}
        </div>
      </div>
      <access-info :bindDetail="bindDetail"></access-info>
    </template>

  </div>
</template>

<script>
import AccessInfo from "../accessInfo";
export default {
  name: "basicInfo",
  components: { AccessInfo },
  data(){
    return{
      channelType:"",
      miniAppCodeUrl:""
    }
  },
  props:{
    bindDetail:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    goHelp(){
      this.$router.push({
        name: "help",
        params: {
          helpSrc:"https://showdoc.askbot.cn/web/#/5?page_id=41",
        },
      });
    },
    // 下载/预览小程序码
    miniAppCodeHandle(type){
      this.$http.get(this.requestUrl.miniProgramBind.wxMiniCode + '?bid=' + this.$route.query.id).then((res) => {
        if (res.data.code === "0") {
          if (type == "downLoad") {
            window.location = res.data.data;
            this.$message.success(this.$t('channel.downLoadSuccess'));
          } else {
            this.miniAppCodeUrl = res.data.data;
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    unBindFun(){
      let url = '';
      if (this.channelType === 'miniProgram'){
        this.$confirm(
            this.$t('botLink.dingGroupListDrawer.unBindMsg')
        )
            .then((_) => {
              let url = '/api-open/bot/' + this.$route.query.id;
              this.$http.put(url,{
                botSetting:{
                  showAppletEntrance:false
                }
              }).then(async res => {
                if (res.data.code == 0 && res.data.data){
                  this.$message({
                    message:this.$t('botLink.dingGroupListDrawer.unBindSuccess'),
                    type:"success"
                  })
                  this.$router.go(-1);
                }
              })
            })
            .catch((_) => {});

      } else if (this.channelType === 'dingding' || this.channelType === 'yunzhijia'){
        url = this.requestUrl.dingdingBind.unBind
        if(this.bindDetail.commonEntry != 1){
          this.unBindFunc(url)
        } else {
          this.showMnDdLimitTip();
        }
      } else if (this.channelType === 'dingdingGroup' || this.channelType === 'dingSingleChatBot'){
        url = this.requestUrl.dingdingBind.unBind;
        this.unBindFunc(url)
      } else if (this.channelType === 'wechat'){
        url = this.requestUrl.eWechatBind.postUnbind
        this.unBindFunc(url)
      } else if (this.channelType === 'wechatAccount'){
        this.$confirm(
            this.$t('channel.unBindWechatAccountMsg')
        )
            .then((_) => {
              this.$http.post(this.requestUrl.wechatBind.unbind + '?bindId=' + this.bindDetail.id).then((res) => {
                if (res.data.code === "0") {
                  this.$message.success(this.$t('channel.unBindSuccess'));
                  this.$router.go(-1);
                } else {
                  this.$message.error(res.data.message);
                }
              });
            })
            .catch((_) => {});
      } else if (this.channelType === 'website'){
        this.$confirm(
            this.$t('botLink.unBindTip'),
            this.$t('channel.unBind'),
            {
              confirmButtonText: this.$t('common.confirm'),
              cancelButtonText: this.$t('common.cancel'),
              type: "warning",
            }
        )
            .then(() => {
              this.$http.post(
                  this.requestUrl.bindV2.unbindApp + '?bindId=' + this.bindDetail.id).then((res) => {
                if (res.data.code === "0") {
                  this.$message.success(this.$t('channel.unBindSuccess'));
                  this.$router.go(-1);
                } else {
                  this.$message.error(res.data.message);
                }
              });
            })
      }
    },
    unBindFunc(url){
      url += '?bindId=' + this.bindDetail.id
      this.$confirm(
          this.$t('botLink.dingGroupListDrawer.unBindMsg')
      )
          .then((_) => {
            this.$http.post(url).then((res) => {
              if (res.data.code === "0") {
                this.$message.success(this.$t('botLink.dingGroupListDrawer.unBindSuccess'));
                this.$router.go(-1);
              } else {
                this.$message.error(res.data.message);
              }
            });
          })
          .catch((_) => {});
    },

    // 绑定机器人能进到绑定页面，但是不允许绑定机器人、修改已绑定机器人、修改可咨询范围
    showMnDdLimitTip(){
      this.$message.warning(this.$t('channel.connection'));
    },
  },
  mounted() {
    this.channelType = this.$route.query.type;
    if (this.channelType === 'miniProgram'){
      this.miniAppCodeHandle();
    }
  }
};
</script>

<style scoped lang="less">
.basicInfo{
  background-color: #FFFFFF;
  height: 100%;
  box-sizing: border-box;
  //padding-right: 20px;
  overflow-y: scroll;
  padding: 20px;
  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    .doc{
      flex: none;
      height: 26px;
      font-size: 12px;
      background-color: #FFFFFF;
      padding: 0 14px;
      border: 1px solid #A1B9FF;
      border-radius: 18px;
      color: #366AFF;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        padding-right: 6px;
      }
    }
  }
  .commonBg{
    background: #FBFCFD;
    border-radius: 5px;
    padding:10px;
    color: #616161;
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 14px;
  }
  .bind{
    margin-top: 14px;
  }
  .domain{
    //margin-top: 14px;
    margin-bottom: 14px;
  }
  .unBind{
    flex: none;
    height: 20px;
    border: 1px solid #A1B9FF;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #366aff;
    background-color: #FFFFFF;
    margin-left: 12px;
    border-radius: 13px;
    cursor: pointer;
    i{
      font-size: 12px;
      margin-right: 4px;
    }
  }
  .download{
    width: 80px;
    height: 28px;
    background-color: #366aff;
    color: #FFFFFF;
    cursor: pointer;
    margin-left: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    margin-top: 20px;
  }
}
</style>