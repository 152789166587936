<template>
    <div @click="clickPage">
        <add-intent
            v-if="addIntentDia"
            ref="addIntentDial"
            :activeBot="activeBot"
            defaultClassification="1_1"
            @switchSkillClass="switchSkillClass"
            @uploadIntentList="uploadIntentList"
            :apiKey="apiKey"
        ></add-intent>
    </div>
</template>
<script>
import AddIntent from "./AddIntent.vue";
import CopyIntent from "./../../../../components/CopyIntent.vue";
import popup from "./../../../../components/popupNew.vue";
export default {
    data() {
        return {
            classList: ['search-intent-list-close'],
            activeBot: "",
            botList: [],
            apiKey: "",

            isSearch: false,
            searchIntentName: "",
            showSearchAllSkillIntent: false,
            searchAllSkillIntentList: [],
            showSearchAllSkillIntentNoData: false,

            openAllSkill: true,

            emptyFlowVisible: false,
            emptyFlowIntentList: [],

            addIntentDia: false,

            activeEmptyIndex: "",
        };
    },
    components: { CopyIntent, popup, AddIntent },
    methods: {
        closeIconBlock(){
            console.log("+++++CLose")
            // this.searchIntentName = "";
            this.showSearchAllSkillIntent = false;
            this.showSearchAllSkillIntentNoData = false;
            this.isSearch = false;
        },
        uploadIntentList(intentId) {
            this.$emit("getIntentListByBotId", this.activeBot, {
                type: "addIntent",
                intentId: intentId,
            });
        },
        addIntent() {
            this.addIntentDia = true;
            this.$nextTick(() => {
                this.$refs.addIntentDial.addIntent(this.getEmptyFlowList);
                this.$refs.addIntentDial.getSkillList();
            });
        },
        tobotIntent() {
            this.$emit("tobotIntent");
        },
        selectBot(val) {
            this.$emit("getIntentListByBotId", val, { type: "" });
            this.activeBot = val;
            this.activeEmptyIndex = "";
        },
        // 获取机器人列表
        getBotList() {
            let bid = this.$route.query.bid;
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    this.botList = res.data.list;
                    if (this.botList.length > 0) {
                        if (!bid) {
                            this.activeBot = Number(this.$route.query.id);
                        } else {
                            this.activeBot = Number(this.$route.query.bid);
                        }
                        this.$emit("getIntentListByBotId", this.activeBot, {
                            type: "",
                        });
                        this.$emit("setBotList", this.botList);
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        // 点击页面时,隐藏搜索的下拉框
        clickPage() {
            if (this.isSearch) {
                this.showSearchAllSkillIntent = !this.showSearchAllSkillIntent;
                this.isSearch = false;
                this.$refs.searchIntent.blur();
            }
            if (this.showSearchAllSkillIntentNoData) {
                this.showSearchAllSkillIntentNoData = false;
                this.showSearchAllSkillIntent = false;
            }
        },
        // 搜索意图
        // 点击/聚焦/
        searchAllSkillFocus(e) {
            console.log('focus+++++')
            
            document
                .querySelector(".search-intent")
                .classList.add("max-search-input");
            if (
                this.searchIntentName !== "") {
                // this.showSearchAllSkillIntent = true;
                // this.showSearchAllSkillIntentNoData = false;
                // this.isSearch = true;
                this.searchAllSkillIntent();
            }
        },
        // 获取搜索意图列表
        searchAllSkillIntent() {
            if (this.searchIntentName === "") {
                this.searchAllSkillIntentList = [];
                this.showSearchAllSkillIntentNoData = false;
            } else {
                this.FetchGet(
                    this.requestUrl.intent.searchAllSkillIntent +
                        "?apiKey=" +
                        this.apiKey +
                        "&keyWord=" +
                        this.searchIntentName +
                        "&needOther=false"
                )
                    .then((res) => {
                        if (res.code === "0") {
                            this.searchAllSkillIntentList = [
                                ...[],
                                ...(res.data || []),
                            ];
                            if (this.searchAllSkillIntentList.length > 0) {
                                this.showSearchAllSkillIntent = true;
                                this.showSearchAllSkillIntentNoData = false;
                            } else {
                                this.showSearchAllSkillIntentNoData = true;
                            }
                            this.isSearch = true;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            }
        },
        // 定位到搜索意图
        switchSkillClass(item, type, index) {
            if (type == "search") {
                this.searchIntentName = item.name;
                this.showSearchAllSkillIntent = false;
                this.showSearchAllSkillIntentNoData = false;
                this.isSearch = false;
            }

            if (type == "empty") {
                // this.emptyFlowVisible = false;
                this.activeEmptyIndex = index;
            }
            if (!this.openAllSkill) {
                this.openAllSkillHandle();
            }

            this.$emit("scrollIntentIntoView", item.id);
        },

        openAllSkillHandle() {
            this.openAllSkill = !this.openAllSkill;
            this.$emit("closeSkillAllNodeList", this.openAllSkill);
        },

        emptyFlowList() {
            this.emptyFlowVisible = !this.emptyFlowVisible;
            if (this.emptyFlowVisible) {
                this.getEmptyFlowList();
            }
        },
        getEmptyFlowList() {
            this.FetchGet(this.requestUrl.intent.getNoprocess + this.activeBot)
                .then((res) => {
                    if (res.code === "0") {
                        this.emptyFlowIntentList = [...[], ...(res.data || [])];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
    },
    computed: {
        getBotProfilePhoto() {
            let url = "";
            this.botList.forEach((item) => {
                if (item.id == this.activeBot) {
                    url = item.botProfilePhoto;
                    this.apiKey = item.apiKey;
                    this.getEmptyFlowList();
                }
            });
            return url;
        },
    },
    mounted() {
        this.getBotList();
    },
};
</script>
<style lang="less" scoped>
/deep/.backIcon{
    // 流程头 特殊处理 修改可能影响意图列表
    height: 55px !important;
    border-radius: 2px !important;
}
.header-r-box {
    // text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    .el-button {
        font-size: 14px;
        height: 35px;
        padding: 0 10px;
        border-radius: 17px;
        margin-left: 20px;
        line-height: 33px;
        border: 1px solid #a1b9ff;
        color: #366aff;
        font-weight: normal;
        &.el-button--primary {
            background-color: #366aff;
            color: #fff;
        }
        span {
            margin-left: 0px;
            vertical-align: top;
        }
        i {
            font-size: 15px;
            margin-right: 3px;
            margin-left: -3px;
        }
        /deep/.el-icon-caret-bottom {
            margin-right: -6px;
        }
        /deep/.el-icon-caret-top {
            margin-right: -6px;
        }
    }
    .empty-flow-badge {
        //line-height: 35px;
        //display: inline-block;
        //width: 83px;
        padding: 0 10px;
        height: 35px;
        background: #f5f7fb;
        border-radius: 17px;
        margin-left: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;

        i {
            display: inline-block;
            margin-right: 3px;
        }
    }
    /deep/.el-badge__content.is-fixed {
        top: 5px;
    }
    .el-input__inner {
        border-radius: 0;
        border: none;
        border-right: 1px solid #e0e6f7;
    }
    .el-badge__content {
        top: 5px;
        right: 15px;
        border: none;
    }
    .notice-box {
        margin-left: 10px;
    }
    .notice-box {
        display: flex;
        height: 30px;
        padding: 0 12px;
        background: #ffffff;
        border: 1px solid #a1b9ff;
        border-radius: 17px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #366aff;
        font-size: 14px;
        .guoran-tongyichicun-24_01-xiaoxitongzhi {
            font-size: 14px;
        }
        span {
            margin-left: 7px;
        }
    }
}
.empty-flow-list {
    position: absolute;
    top: 70px;
    right: -30px;
    width: 301px;
    background: #ffffff;
    box-shadow: 0px 1px 9px 0px rgba(57, 63, 79, 0.21);
    border-radius: 5px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    .empty-flow-list-header {
        flex: none;
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        justify-content: space-between;
        .el-icon-warning-outline {
            color: #fe5965;
            font-size: 13px;
        }
        .guoran-tongyichicun-shouqi {
            font-size: 10px;
            color: #366aff;
            cursor: pointer;
        }
    }
    .empty-flow-list-content {
        flex: auto;
        overflow-y: auto;
        max-height: 250px;
        width: 275px;
        background: #fbfcfd;
        border: 1px solid #edf1fa;
        border-radius: 5px;
        margin: 0 13px 13px;
        .empty-flow-list-c-cell {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;
            height: 38px;
            .empty-flow-list-cc-name {
                flex: auto;
                display: flex;
                align-items: center;
                .active-empty-flow-list-cc-name-inner {
                    color: #366aff;
                    font-weight: 700;
                }
                .empty-flow-list-cc-name-inner {
                    text-align: left;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 230px;
                }
                i {
                    width: 5px;
                    height: 5px;
                    background-color: #a3adc6;
                    margin-right: 5px;
                }
            }
            .empty-flow-list-cc-riq {
                font-size: 12px;
            }
        }
        .empty-flow-list-c-cell:hover {
            color: #366aff;
            font-weight: 700;
            cursor: pointer;
            .empty-flow-list-cc-name {
                i {
                    background-color: #366aff;
                }
            }
        }
    }
}
.search-intent {
    width: 140px;
    display: flex;
    align-items: center;
    .search-intent-list {
        max-height: 220px;
        width: 220px;
        position: absolute;
        background-color: #fff;
        box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.13);
        border-radius: 5px;
        overflow-y: auto;
        overflow-x: hidden;
        top: 50px;
        z-index: 3;
        .item {
            height: 40px;
            border-bottom: 0px solid rgb(245, 248, 251) !important;
            line-height: 40px;
            text-align: left;
            padding: 0 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            font-size: 13px;
            cursor: pointer;
            &:hover {
                background-color: #ecf5ff;
                color: #366aff;
            }
        }
    }
    .no-data {
        height: 100px;
        width: 220px;
        position: absolute;
        background-color: #fff;
        box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.13);
        border-radius: 5px;
        overflow-y: auto;
        overflow-x: hidden;
        top: 50px;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #a3adc6;
    }
    &.max-search-input {
        @keyframes maxSearchInputAnimates {
            0% {
                width: 140px;
            }
            100% {
                width: 220px;
            }
        }
        animation: maxSearchInputAnimates 1s ease 1;
        animation-fill-mode: forwards; /*定义动画结束的状态*/
    }
}
#superIntentConfig-intent-add {
    .guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe {
        color: #ff9555;
        font-size: 50px;
    }
    .tips-dialog-title {
        margin-top: 10px;
    }

    .open-super-intent .right {
        height: 40px;
        background: #fbfcfd;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-left: 12px;

        .desc {
            color: #a9b3c6;
            margin-left: 12px;
            height: 40px;
            line-height: 40px;
            font-size: 12px;
        }
    }
    .evaluate-table-switch {
        display: flex;
        flex-direction: row;
        height: 25px;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        text-align: center;
        align-items: center;

        .el-switch {
            text-align: center;
        }

        .switch-open-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 3px;

            i {
                color: white;
                font-size: 16px;
            }
        }

        .switch-close-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 21px;
            i {
                color: #6e6b7b;
                font-size: 14px;
                font-weight: 600;
            }
        }

        i {
            color: #366aff;
            font-size: 18px;
        }
    }

    .item-config {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 20px 0px;
        font-size: 14px;
        .title {
            color: #616161;
        }
        .guoran-tongyichicun-shili {
            color: #366aff;
        }
        .block-add-intent {
            width: 100%;
            display: flex;
            flex-direction: column;
            .title {
                text-align: left;
                margin-bottom: 10px;
                display: flex;
                align-items: centers;
            }
        }
        .blockdes {
            width: 100%;
            display: flex;
            flex-direction: column;
            .title {
                text-align: left;
                margin-bottom: 4px;
                width: auto;
                color: #616161;
                i {
                    margin-right: 5px;
                }
            }
            .des {
                color: #a9b3c6;
                text-align: left;
            }
            .arsenal_icon {
                color: #366aff;
            }
        }
        .config {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            background: #f6f8fd;
            border: 1px solid #e0e6f7;
            border-radius: 5px;
            position: relative;
            .pattern {
                text-align: left;
                font-size: 14px;
                margin-right: 0;
                padding: 3px 0;
                margin-left: 6px;
                /deep/.el-input {
                    flex: auto;
                    min-width: 120px;
                    max-width: 350px;
                }
                .el-input__inner {
                    // flex: auto;
                    // border-top: none;
                    // border-left: none;
                    // border-right: none;
                    // border-radius: 5px 5px 0 0;
                    // border-color: #366AFF;
                    border-bottom: solid 2px #366aff;
                }
                .word {
                    font-size: 12px;
                    flex: none;
                    text-align: left;
                    white-space: nowrap;
                    margin: 0 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .keyword-recommend {
                    position: relative;
                }
            }
        }
        .example {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            color: #aaaaaa;

            .pattern {
                width: auto;
                text-align: left;
                margin-right: 20px;
                .word {
                    text-align: left;
                    margin-top: 20px;
                }
                .input {
                    //text-decoration:underline;
                    font-size: 14px;
                    border-bottom: 1px solid #aaaaaa;
                }
            }
        }
        .classification {
            width: 100%;
            display: flex;
            flex-direction: column;
            .title {
                text-align: left;
                margin-bottom: 10px;
            }
        }

        .switch {
            text-align: left;
            flex: none;
            width: 150px;
        }
        .titleDes {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            em {
                text-align: right;
                margin-right: 45px;
                font-size: 12px;
            }
        }
        .config-example {
            margin-top: 30px;
            border: 1px solid #e0e6f7;
            border-radius: 5px;
            .title {
                height: 35px;
                border-bottom: 1px solid #e0e6f7;
                line-height: 35px;
                text-align: center;
                width: 100%;
            }
        }
        .bottom-example {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;

            .pattern {
                height: 33px;
                line-height: 33px;
                padding: 0 12px;
                margin: 0 3px;
                &.border {
                    background: #f6f8fd;
                    border: 1px solid #acc2ff;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #366aff;
                }
            }
        }
    }
    .line {
        margin: 30px 0;
        height: 2px;
        border-bottom: 1px dotted #e0e6f7;
    }

    .exmplebox {
        background: #ffffff;
        border: 1px solid #e0e6f7;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 14px;
        .title {
            width: 100%;
            text-align: center;
            height: 35px;
            line-height: 35px;
            border-bottom: 1px solid #e0e6f7;
        }
        .exmple {
            text-align: left;
            flex: none;
            height: 120px;
            overflow-y: scroll;
            overflow-x: hidden;
            width: calc(100% - 20px);
            padding: 10px;
            .text {
                margin: 0px 0px 10px 0px;
                color: #a9b3c6;
                display: flex;
                height: 30px;
                align-items: center;
                > div {
                    width: 6px;
                    height: 6px;
                    background: #a9b3c6;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
        }
    }
    .flexrow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .red-text {
        color: red;
        font-size: 14px;
        margin-left: 3px;
    }
}
</style>