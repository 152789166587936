
// 提交绑定机器人
import { requestUrl } from "../../../api/requestUrl";

function submitBindBot(channelType, bindDetail, bindBots, enableKnowledgeSearch, that, callback) {
    let botsArr = [];
    let conditionsFlag = true;
    //因为钉钉，飞书和企微下bindBot结构有更改，所有要在此处理一下
    if (channelType === 'dingding' || channelType === 'feishu' || channelType === 'wechat'){
        bindBots.forEach(agent => {
            if (agent.botInfos){
                agent.botInfos.forEach(bot => {
                    let itemsCopy = [];
                    let conditionsCopy = []
                    let itemsFlag = false
                    if(bot.conditions && bot.conditions.length > 0){
                        bot.conditions.forEach(conditionsItem => {
                            if(conditionsItem.type == 'ALL'){
                                conditionsCopy.push({
                                    expect:[],
                                    type:conditionsItem.type,
                                    key:"",
                                    relation:null,
                                })
                            } else if(conditionsItem.type == 'STAFF_OR_DEPARTMENT'){
                                conditionsCopy.push({
                                    expect:conditionsItem.expect,
                                    type:conditionsItem.type,
                                    key:"",
                                    relation:conditionsItem.relation,
                                })
                            } else if(conditionsItem.type == 'CUSTOMIZE'){
                                if(conditionsItem.key != 'label' && conditionsItem.key != 'personalJobLevel' && conditionsItem.key !== '入职时间'){
                                    conditionsCopy.push({
                                        expect: Array.isArray(conditionsItem.expect)?conditionsItem.expect:[conditionsItem.expect],
                                        type: conditionsItem.type,
                                        key: conditionsItem.key,
                                        relation: conditionsItem.relation,
                                    })
                                } else if( conditionsItem.key == '入职时间'){
                                    if(conditionsItem.relation == 'BEFORE' || conditionsItem.relation == 'AFTER'){
                                        conditionsCopy.push({
                                            expect: [conditionsItem.expect],
                                            type: conditionsItem.type,
                                            key: conditionsItem.key,
                                            relation: conditionsItem.relation,
                                        })
                                    } else {
                                        conditionsCopy.push({
                                            expect: conditionsItem.expect,
                                            type: conditionsItem.type,
                                            key: conditionsItem.key,
                                            relation: conditionsItem.relation,
                                        })
                                    }
                                } else {
                                    conditionsCopy.push({
                                        expect: conditionsItem.expect,
                                        type: conditionsItem.type,
                                        key: conditionsItem.key,
                                        relation: conditionsItem.relation,
                                    })
                                }
                            }
                        })
                    } else {
                        conditionsFlag = false
                    }
                    let obj = {
                        apiKey: bot.apiKey,
                        botId: bot.id || bot.botId,
                        name: bot.name,
                        scope: bot.scope,
                        items: itemsFlag ? itemsCopy : bot.items,
                        conditions:conditionsCopy,
                        botType: bot.botType || 0,
                        plugins:bot.plugins ? bot.plugins.map((res) => {
                            let obj = {
                                id:res.id,
                                name: res.name,
                                scope:res.scope,
                                conditions:res.conditions.map(ress => {
                                    let conditions = {
                                        expect:[],
                                        type:ress.type,
                                        key:"",
                                        relation:null,
                                    }
                                    if(ress.type == 'ALL'){
                                        conditions.type = ress.type
                                    } else if(ress.type == 'STAFF_OR_DEPARTMENT'){
                                        conditions.expect = ress.expect
                                        conditions.type = ress.type
                                        conditions.relation = ress.relation
                                    } else if(ress.type == 'CUSTOMIZE'){
                                        conditions.expect = ress.expect
                                        conditions.type = ress.type
                                        conditions.key =  ress.key;
                                        conditions.relation = ress.relation
                                    }
                                    return conditions
                                })
                            }
                            return obj
                        }) : []
                    }
                    botsArr.push(obj)
                })
                delete agent.botInfos
            }

        })
        if (botsArr.length == 0) {
            that.$message.error(that.$t('channel.noBindBotTip'));
            return false
        }
        let flag = true;
        botsArr.forEach(item => {
            if(item.botId === ''){
                flag = false;
            }
        })
        if (!flag){
            that.$message.error(that.$t('channel.selectBindBot'));
            return
        }
        if(!conditionsFlag) {
            that.$message.warning(that.$t('channel.conditionsTip'));
            return false;
        }
        if(!flag || !conditionsFlag) return false;

        let bindAgent = {
            agentBindBotInfos:bindBots,
            bindCode: bindDetail.bindCode,
            bindId: bindDetail.id,
        }

        that.$http.post(that.requestUrl.bindV2.bindAgent, bindAgent).then((res) => {

            if (res.data.code === "0") {
                if (callback){
                    callback()
                }
            } else {
                that.$message.error(res.data.message);
            }
        })
        return
    }
    bindBots.forEach(bot => {
        let conditionsCopy = []
        if (channelType === 'website' && !bindDetail.needLogin) {
            botsArr.push({
                apiKey: bot.apiKey,
                botId: bot.id || bot.botId,
                name: bot.name,
                scope: 0,
                botType: bot.botType || 0
            })
        } else {
            let itemsCopy = [];
            let itemsFlag = false
            //  selfType 0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
            if(bindDetail.bindBot == null || (bindDetail.bindBot && bindDetail.bindBot.count == 0)){
                itemsFlag = true
                bot.items && bot.items.forEach(itemsValue => {
                    let obj = {
                        id:itemsValue.id,
                        type:itemsValue.selfType ? itemsValue.selfType === 2 ? 1 : 0 : itemsValue.type, // //  范围对应类型1：部门 0：成员
                        name:itemsValue.name,
                        source:'UPDOWN'
                    }
                    itemsCopy.push(obj)
                })

            }
            if(channelType){
                if(bot.conditions && bot.conditions.length > 0){
                    bot.conditions.forEach(conditionsItem => {
                        if(conditionsItem.type == 'ALL'){
                            conditionsCopy.push({
                                expect:[],
                                type:conditionsItem.type,
                                key:"",
                                relation:null,
                            })
                        } else if(conditionsItem.type == 'STAFF_OR_DEPARTMENT'){
                            conditionsCopy.push({
                                expect:conditionsItem.expect,
                                type:conditionsItem.type,
                                key:"",
                                relation:conditionsItem.relation,
                            })
                        } else if(conditionsItem.type == 'CUSTOMIZE'){
                            if(conditionsItem.key != 'label' && conditionsItem.key != 'personalJobLevel' && conditionsItem.key !== '入职时间'){
                                conditionsCopy.push({
                                    expect: Array.isArray(conditionsItem.expect)?conditionsItem.expect:[conditionsItem.expect],
                                    type: conditionsItem.type,
                                    key: conditionsItem.key,
                                    relation: conditionsItem.relation,
                                })
                            } else if( conditionsItem.key == '入职时间'){
                                if(conditionsItem.relation == 'BEFORE' || conditionsItem.relation == 'AFTER'){
                                    conditionsCopy.push({
                                        expect: [conditionsItem.expect],
                                        type: conditionsItem.type,
                                        key: conditionsItem.key,
                                        relation: conditionsItem.relation,
                                    })
                                } else {
                                    conditionsCopy.push({
                                        expect: conditionsItem.expect,
                                        type: conditionsItem.type,
                                        key: conditionsItem.key,
                                        relation: conditionsItem.relation,
                                    })
                                }
                            } else {
                                conditionsCopy.push({
                                    expect: conditionsItem.expect,
                                    type: conditionsItem.type,
                                    key: conditionsItem.key,
                                    relation: conditionsItem.relation,
                                })
                            }
                        }
                    })
                } else {
                    conditionsFlag = false
                }
                let obj = {
                    apiKey: bot.apiKey,
                    botId: bot.id || bot.botId,
                    name: bot.name,
                    scope: bot.scope,
                    items: itemsFlag ? itemsCopy : bot.items,
                    conditions:conditionsCopy,
                    botType: bot.botType || 0,
                    plugins:bot.plugins ? bot.plugins.map((res) => {
                        let obj = {
                            id:res.id,
                            name: res.name,
                            scope:res.scope,
                            conditions:res.conditions.map(ress => {
                                let conditions = {
                                    expect:[],
                                    type:ress.type,
                                    key:"",
                                    relation:null,
                                }
                                if(ress.type == 'ALL'){
                                    conditions.type = ress.type
                                } else if(ress.type == 'STAFF_OR_DEPARTMENT'){
                                    conditions.expect = ress.expect
                                    conditions.type = ress.type
                                    conditions.relation = ress.relation
                                } else if(ress.type == 'CUSTOMIZE'){
                                    conditions.expect = ress.expect
                                    conditions.type = ress.type
                                    conditions.key =  ress.key;
                                    conditions.relation = ress.relation
                                }
                                return conditions
                            })
                        }
                        return obj
                    }) : []
                }
                botsArr.push(obj)
            } else {
                botsArr.push({
                    apiKey: bot.apiKey,
                    botId: bot.id || bot.botId,
                    name: bot.name,
                    scope: bot.scope,
                    items: itemsFlag ? itemsCopy : bot.items,
                    conditions:conditionsCopy,
                    botType: bot.botType || 0
                })
            }

        }

    })
    if (botsArr.length == 0) {
        that.$message.error(that.$t('channel.noBindBotTip'));
        return false
    }
    let flag = true;
    botsArr.forEach(item => {
        if(item.botId === ''){
            flag = false;
        }
    })
    if (!flag){
        that.$message.error(that.$t('channel.selectBindBot'));
        return
    }
    if(!conditionsFlag) {
        that.$message.warning(that.$t('channel.conditionsTip'));
        return false;
    }
    if(!flag || !conditionsFlag) return false;
    that.$http.post(
        that.requestUrl.bindV2.addBindBot,
        {
            bindCode: bindDetail.bindCode,
            bindId: bindDetail.id,
            botInfos: botsArr,
            enableKnowledgeSearch:enableKnowledgeSearch
        }
    ).then((res) => {
        if (res.data.code === "0") {
            // that.$message.success('保存成功');
            if (callback){
                callback()
            }
        } else {
            that.$message.error(res.data.message);
        }
    });

}

// 网站接入/企微/钉钉/飞书的权限设置
function authoritySetConfirm(channelType,bindDetail,bindInformation,that,callback){
    //  website 网站接入     wechat 企微    dingding 钉钉     feishu 飞书
    if (channelType !== 'IM'){
        if(channelType === 'website' || channelType === 'wechat' || channelType === 'dingding' || channelType === 'feishu'){
            that.$http.post(that.requestUrl.bindV2.knowledgeScope,{
                "bindId": bindDetail.id,
                "enableKnowledgeSearch": bindInformation.enableKnowledgeSearch,
                "knowledgeScopeConditions": bindInformation.dataSetCondition,
                "conditions": bindInformation.pluginCondition,
                "pluginToolInfo": null
            }).then(res => {
                console.log('knowledgeScope',res)
                if (res.data.code == 0){
                    if (callback){
                        callback()
                    }
                }
            })
        }
    } else if(channelType === 'IM'){
        that.bindImBots[that.activeTreeIndex].items = that.activeBindBotsItems.map(item =>{
            if(!item.name) {
                item.name = item.label
            }
            return item
        })
        if(that.bindImBots[that.activeTreeIndex].botId) {
            that.saveImBindBots()
        }
    }
}

export {
    submitBindBot,
    authoritySetConfirm
}