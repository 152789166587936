<template>
    <div id="home">
        <div class="gr-content-container">
            <div class="personalHeader">
                <top-header>
                    <div slot="setup-header-left" class="setup-header-left">
                        <span class="setup-header-left-icon">
                            <i class="iconfont guoran-shouye1"></i
                        ></span>
                        <span>{{$t('index.homePage')}}</span>
                    </div>
                </top-header>
            </div>
            <div class="gr-index-content">
                <div ref="grleftcontent" class="gr-left-content">
                    <div
                        v-if="
                            !(
                                activeServiceObj.OPEN == -1 &&
                                activeServiceObj.WORKORDER == -1 &&
                                activeServiceObj.IM == -1 &&
                                activeServiceObj.KNOWLEDGE == -1 && 
                                activeServiceObj.QUESTION == -1
                            )
                        "
                        :class="userCompany.modules && userCompany.modules.length > 4 ? 'new-glc-left-top' : 'glc-left-top'"
                    >
                        <div class="gr-left-content-bottom-t">
                            <span class="gr-left-cbt-icon"
                                ><i class="iconfont guoran-xianyoufuwu"></i
                            ></span>
                            <span class="gr-left-cbt-text">{{$t('index.quickNavigation')}}</span>
                        </div>
                        <ActiveService :activeServiceObj="activeServiceObj" :botExpiresIn="botExpiresIn" :userCompany="userCompany" :userInfo="userInfo"/>
                    </div>
                    <div class="scroll-box">
                        <div class="glc-left-mid" v-if="modulesList.length > 0 && !(modulesList.length === 1 && modulesList[0] === 'IM')">
                            <div class="gr-left-content-bottom-t">
                                <span class="gr-left-cbt-icon"
                                    ><i
                                        class="
                                            iconfont
                                            guoran-tongyichicun-banbenxinxi
                                        "
                                    ></i
                                ></span>
                                <span class="gr-left-cbt-text">{{$t('index.versionInformation')}}</span>
                            </div>
                            <div class="gr-left-content-bottom-v">
                              <!-- 员工总数 -->
                              <div class="gr-lct-comp-vertion-s">
                                <span class="gr-lct-comp-vertion-s-name gr-lct-comp-vertion-s-name-o">{{$t('companyInfo.totalNumberOfEmployees')}}</span>
                                <span class="gr-lct-comp-vertion-s-icon">
                                  <span class="gr-lct-comp-vertion-t-p-t">{{botLimitData.totalPeopleNum}} {{$t('companyInfo.person')}}</span>
                                </span>
                              </div>
                              <div
                                  v-if="isShowVersionDuration()"
                                  class="gr-lct-comp-vertion-t version-time"
                              >
                                <span
                                    class="gr-lct-comp-vertion-t-name gr-lct-comp-vertion-t-name-o"
                                >{{$t('companyInfo.versionDuration')}}</span>
                                <span
                                  v-if="userCompany.status == 2"
                                  class="gr-lct-comp-vertion-t-p"
                              >
                                    <el-progress
                                        v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                                        type="circle"
                                        width="20"
                                        stroke-width="3"
                                        :show-text="false"
                                        :percentage="
                                            percentageFormat(
                                                activeVersionObj.OPEN.expiresIn,
                                                activeVersionObj.OPEN.config.expiresIn
                                            )
                                        "
                                        :color="colors"
                                    ></el-progress>
                                    <template v-if="Number(activeVersionObj.OPEN.expiresIn) >= 0 || activeVersionObj.OPEN.expiresIn === '无限制'">
                                        <span
                                            v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                                            class="gr-lct-comp-vertion-t-p-t"
                                        >{{$t('companyInfo.surplus')}}</span
                                        >
                                        <span
                                            v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                                            class="gr-lct-comp-vertion-t-p-n"
                                        >{{ activeVersionObj.OPEN.expiresIn }}</span
                                        >
                                        <span
                                            v-if="activeVersionObj.OPEN.expiresIn !== '无限制'"
                                            class="gr-lct-comp-vertion-t-p-t"
                                        >{{$t('companyInfo.overdue')}}</span
                                        >
                                        <span
                                            v-if="activeVersionObj.OPEN.expiresIn == '无限制'"
                                            class="gr-lct-comp-vertion-t-p-t"
                                        >{{ activeVersionObj.OPEN.expiresIn }}</span
                                        >
                                    </template>
                                    <template v-else>
                                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-t">已过期</span>
                                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-n">{{ openBeOverdueTime }}</span>
                                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" class="gr-lct-comp-vertion-t-p-t">天</span >
                                        <span v-if="activeVersionObj.OPEN.expiresIn !== '无限制'" @click="nowRenew" class="now-renew">立即续费</span>
                                    </template>
                                </span>
                                <span
                                    v-if="userCompany.status != 2"
                                    class="gr-lct-comp-vertion-t-p"
                                >{{ statusText[userCompany.status] }}</span
                                >
                              </div>
                            </div>
                            <company-info
                                :serviceExpired="serviceExpired"
                                :userCompany="JSON.stringify(userCompany)"
                                :activeVersionObj="activeVersionObj"
                                :knowdgeData="knowdgeData"
                                :modulesList="modulesList"
                                @retryAuth="retryAuth"
                                @paySuccess="getUserCompany"
                                ref="companyInfo"
                            ></company-info>
                        </div>
                        <div class="glc-left-bottom">
                            <p :class="['carousel-cell', ,uid == 20281 || uid == 117431 ? 'jd' : isE() ? 'El': '']">
                                <span class="sys-name">
                                    {{ isE() ? $t('index.HRIntelligentServiceDesk') : $t('index.staffIntelligentServiceDesk') }}
                                </span>
                                <span class="des">
                                    {{ isE() ? $t('index.HRIntelligentServiceDeskTip') : $t('index.staffIntelligentServiceDeskTip') }}
                                </span>
                            </p>
                        </div>
                    </div>
                   
                </div>
                <div class="gr-right-content">
                    <div :class="['grc-right-top', ,uid == 20281 || uid == 117431 ? 'jd' : '']">
                        <company-infobase
                            :serviceExpired="serviceExpired"
                            :userCompany="JSON.stringify(userCompany)"
                            :activeVersionObj="activeVersionObj"
                            :uid="uid"
                            @retryAuth="retryAuth"
                            @getUserInfo="getUserInfo"
                        ></company-infobase>
                    </div>
                    <div :class="['grc-right-mid-title',uid == 20281 || uid == 117431 ? 'jd' : '']">
                        <div class="notice-list-title">
                            <div class="notice-list-title-l">
                                <span class="gr-left-cbt-icon"
                                    ><i
                                        class="iconfont guoran-gengxingonggao"
                                    ></i
                                ></span>
                                <span class="gr-left-cbt-text">{{$t('index.updateAnnouncement')}}</span>
                            </div>
                            <div class="notice-list-title-r">
                                <span @click="moreNotice"
                                    >{{$t('index.more')}} <i class="el-icon-arrow-right"></i
                                ></span>
                            </div>
                        </div>
                    </div>
                     <!-- :style="{'max-height':economizeOnManpower >= 30 ? '403px' : '630px'}" -->
                    <div
                        :class="['grc-right-mid',uid == 20281 || uid == 117431 ? 'jd' : '']"
                    >
                        <IndexNotice />
                    </div>
                    <div
                        v-if="economizeOnManpower >= 30"
                        class="grc-right-bottom"
                    >
                        <div class="gr-right-content-bottom-t">
                            <span class="gr-right-cbt-icon"
                                ><i class="iconfont guoran-yugujiazhi"></i
                            ></span>
                            <span class="gr-right-cbt-text">{{$t('index.estimatedValue')}}</span>
                        </div>
                        <Predict
                            @changeEconomizeOnManpower="
                                changeEconomizeOnManpower
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="uploadDialogVisible"
            width="600px"
            top="8vh"
            custom-class="dialogImg"
        >
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <el-dialog
            :visible.sync="uploadExampleVisible"
            top="8vh"
            width="600px"
            custom-class="dialogImg"
        >
            <div style="overflow: auto">
                <img :src="uploadExampleUrl" alt="" />
            </div>
        </el-dialog>
        <SideNav
            ref="leftMenuObj"
            @setIsShowTel="setIsShowTel"
            @setHasCompanyName="setHasCompanyName"
        ></SideNav>
        <popup v-if="dialogFormVisible" @closeEvent="closeBaseInfoDia">
            <div slot="popup-name" class="popup-name">
                {{ skillAndBaseinfoTitle }}
            </div>
            <div slot="popup-con">
                <div v-if="false" class="system-list">
                    <div
                        :class="['service-item', item.checked ? 'click' : '']"
                        :id="item.code"
                        v-for="(item, index) in systemModelFilterList"
                        :key="index"
                        @click="onClickService(item)"
                    >
                        <img
                            src="../../assets/images/workorderIcon.png"
                            v-if="item.code === 'WORKORDER'"
                        />
                        <img
                            src="../../assets/images/openIcon.png"
                            v-if="item.code === 'OPEN'"
                        />
                        <span>{{ item.name }}</span>
                        <div class="check-icon" v-if="item.checked">
                            <i class="el-icon-check"></i>
                        </div>
                    </div>
                </div>
                <div v-if="selectStep == 1" class="service-list">
                    <div v-if="false" class="header-name">
                        请选择您需要的服务
                    </div>
                    <div class="service-mode-list">
                        <div
                            class="service-item"
                            v-for="(item, index) in serviceModeList"
                            :key="index"
                            @click="checkTemplate(item)"
                            :class="
                                checkTemplateCode === item.versionCode
                                    ? 'check-template'
                                    : ''
                            "
                        >
                            <div class="item-left">
                                <img
                                    src="../../assets/images/retail.png"
                                    v-if="item.versionCode === 'RETAIL'"
                                />
                                <img
                                    src="../../assets/images/currency.png"
                                    v-else
                                />
                            </div>
                            <div class="item-right">
                                <div class="version-name">
                                    {{ item.versionName }}
                                </div>
                                <div
                                    v-if="item.versionCode === 'RETAIL'"
                                    class="version-detail"
                                >
                                    {{$t('index.retailVersionTip')}}
                                </div>
                                <div v-else class="version-detail">
                                  {{$t('index.anotherVersion')}}
                                </div>
                            </div>
                            <div
                                class="check-icon"
                                v-if="checkTemplateCode === item.versionCode"
                            >
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectStep == 2">
                    <el-form :model="baseInfoForm">
                        <el-form-item>
                            <el-input
                                v-model="baseInfoForm.realName"
                                autocomplete="off"
                                prefix-icon="el-icon-user"
                                :placeholder="$t('index.realNamePlaceholder')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item v-if="isShowTel">
                            <el-input
                                v-model="baseInfoForm.phone"
                                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                @blur="phoneChange"
                                autocomplete="off"
                                maxlength="11"
                                prefix-icon="el-icon-phone-outline"
                                :placeholder="$t('index.phonePlaceholder')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item v-if="isShowTel">
                            <el-input
                                :placeholder="$t('index.checkCodePlaceholder')"
                                v-model="baseInfoForm.checkCode"
                                prefix-icon="el-icon-c-scale-to-original"
                            >
                                <template slot="append">
                                    <el-button
                                        :disabled="verifyIng"
                                        @click="getCheckcode"
                                        style="
                                            background: #366aff;
                                            border-radius: 0 4px 4px 0;
                                            color: white;
                                        "
                                        type="primary"
                                        ><span v-if="verifyIng"
                                            >（{{
                                                verifyTime
                                            }}
                                            S）{{$t('index.resend')}}</span
                                        ><span v-if="!verifyIng">{{
                                            verifyName
                                        }}</span></el-button
                                    ></template
                                >
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                v-model="baseInfoForm.email"
                                autocomplete="off"
                                prefix-icon="el-icon-postcard"
                                :placeholder="$t('index.emailPlaceholder')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                v-if="!this.hasCompanyName"
                                v-model="baseInfoForm.companyName"
                                autocomplete="off"
                                prefix-icon="iconfont guoran-a-14-03"
                                :placeholder="$t('index.companyNamePlaceholder')"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="dialog-footer" class="service-dialog-footer">
                <el-button
                    v-if="certificationVisible"
                    type="primary"
                    @click="addCompanyInfo"
                    >{{$t('index.nestStep')}}</el-button
                >
                <el-button
                    v-if="!certificationVisible"
                    type="primary"
                    @click="addCompanyInfo"
                    >{{$t('index.submit')}}</el-button
                >
            </div>
        </popup>
        <popup v-if="phoneDialogFormVisible" @closeEvent="cancelBind">
            <div slot="popup-name" class="popup-name">{{$t('index.bindPhoneTitle')}}</div>
            <div slot="popup-tip">{{$t('index.bindPhoneTip')}}</div>
            <div slot="popup-con">
                <div>
                    <el-form :model="basePhoneInfoForm">
                        <el-form-item v-if="isShowTel">
                            <el-input
                                v-model="basePhoneInfoForm.phone"
                                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                @blur="phoneChange"
                                autocomplete="off"
                                maxlength="11"
                                prefix-icon="el-icon-phone-outline"
                                :placeholder="$t('index.phonePlaceholder')"
                            ></el-input>
                        </el-form-item>
                        <el-form-item v-if="isShowTel">
                            <el-input
                                :placeholder="$t('index.checkCodePlaceholder')"
                                v-model="basePhoneInfoForm.checkCode"
                                prefix-icon="el-icon-c-scale-to-original"
                            >
                                <template slot="append">
                                    <el-button
                                        :disabled="verifyIng"
                                        @click="onlyPhoneGetCheckcode"
                                        style="
                                            background: #366aff;
                                            border-radius: 0 4px 4px 0;
                                            color: white;
                                        "
                                        type="primary"
                                        ><span v-if="verifyIng"
                                            >（{{
                                                verifyTime
                                            }}
                                            S）{{$t('index.resend')}}</span
                                        ><span v-if="!verifyIng">{{
                                            verifyName
                                        }}</span></el-button
                                    ></template
                                >
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="dialog-footer" class="service-dialog-footer">
                <span style="" class="cancel-bind" @click="cancelBind"
                    >{{$t('index.unbind')}}</span
                >
                <el-button type="primary" @click="bindPhone"
                    >{{$t('index.bindNow')}}</el-button
                >
            </div>
        </popup>
        <popup
            v-if="certificationDialog"
            @closeEvent="certificationDialog = false"
        >
            <div slot="popup-name" class="popup-name">{{$t('index.certificationTitle')}}</div>
            <div slot="popup-tip">{{$t('index.certificationTip')}}</div>
            <div slot="popup-con">
                <div class="certification-content">
                    <el-tabs tab-position="bottom" type="">
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label"
                                ><i class="iconfont guoran-qiyeyouxiang"></i>
                                <span class="tabs-name">{{$t('index.enterpriseMail')}}</span></span
                            >
                            <div class="certification-form-item">
                                <el-input
                                    disabled
                                    :placeholder="$t('index.companyNamePla')"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div
                                class="certification-form-item"
                                :class="
                                    isPersonalEmailDomain
                                        ? 'inputErrorBorder'
                                        : ''
                                "
                            >
                                <el-input
                                    :placeholder="$t('index.enterpriseMailPla')"
                                    @blur="blurCompanyEmailInput()"
                                    prefix-icon="iconfont guoran-qiyeyouxiang"
                                    v-model="certification.companyEmail"
                                >
                                </el-input>
                            </div>
                            <div
                                class="inputErrorText"
                                v-show="isPersonalEmailDomain"
                            >
                                {{$t('index.companyMailTitle')}}
                            </div>
                            <div class="certification-form-item spic-certification-form-item">
                                <el-input
                                    :placeholder="$t('index.mailCodePlaceholder')"
                                    prefix-icon="iconfont guoran-yanzhengma"
                                    v-model="mailCode"
                                >
                                </el-input>
                                <el-button
                                    v-if="!sendDisabled"
                                    type="primary"
                                    @click="sendMailCode()"
                                    >{{ buttonName }}</el-button
                                >
                                <el-button
                                    v-if="sendDisabled"
                                    type="info"
                                    :disabled="true"
                                    >{{ buttonName }}</el-button
                                >
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    :disabled="isPersonalEmailDomain"
                                    @click="saveCertificationInfo(0)"
                                    >{{$t('index.submitCertification')}}</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >{{$t('index.previousStep')}}</span
                                >
                                <span class="q-tip"
                                    >{{$t('index.phoneNumber')}}</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >{{$t('index.otherMethods')}}</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-yingyezhizhao"></i>
                                <span class="tabs-name">{{$t('index.businessLicense')}}</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    disabled
                                    :placeholder="$t('index.companyNamePla')"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleCertChange"
                                        :file-list="fileList.cert"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadCertSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleCertRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >{{$t('index.businessLicenseTip')}}</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >{{$t('index.businessLicenseTip1')}}</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="
                                            reviewExample('example_cert.png')
                                        "
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>{{$t('index.viewLegend')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(1)"
                                    >{{$t('index.submitCertification')}}</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >{{$t('index.previousStep')}}</span
                                >
                                <span class="q-tip"
                                    >{{$t('index.phoneNumber')}}</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >{{$t('index.otherMethods')}}</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-qiyeweixin1"></i>
                                <span class="tabs-name">{{$t('index.eWechat')}}</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    disabled
                                    :placeholder="$t('index.companyNamePla')"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleEwxChange"
                                        :file-list="fileList.ewx"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadEwxSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleEwxRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >{{$t('index.eWechatTip')}}</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >{{$t('index.businessLicenseTip1')}}</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="
                                            reviewExample('example_ewx.png')
                                        "
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>{{$t('index.viewLegend')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(2)"
                                    >{{$t('index.submitCertification')}}</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >{{$t('index.previousStep')}}</span
                                >
                                <span class="q-tip"
                                    >{{$t('index.phoneNumber')}}</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >{{$t('index.otherMethods')}}</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-dingding1"></i>
                                <span class="tabs-name">{{$t('index.dingDing')}}</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    disabled
                                    :placeholder="$t('index.companyNamePla')"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleDdChange"
                                        :file-list="fileList.dd"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadDdSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleDdRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >{{$t('index.dingDingTip')}}</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >{{$t('index.businessLicenseTip1')}}</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="reviewExample('example_dd.png')"
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>{{$t('index.viewLegend')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(3)"
                                    >{{$t('index.submitCertification')}}</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >{{$t('index.previousStep')}}</span
                                >
                                <span class="q-tip"
                                    >{{$t('index.phoneNumber')}}</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >{{$t('index.otherMethods')}}</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-gongpaimingpian"></i>
                                <span class="tabs-name">{{$t('index.businessCard')}}</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    disabled
                                    :placeholder="$t('index.companyNamePla')"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleCardChange"
                                        :file-list="fileList.card"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadCardSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleCardRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >{{$t('index.businessCardTip')}}</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >{{$t('index.businessLicenseTip1')}}</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="
                                            reviewExample('example_card.png')
                                        "
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>{{$t('index.viewLegend')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(4)"
                                    >{{$t('index.submitCertification')}}</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >{{$t('index.previousStep')}}</span
                                >
                                <span class="q-tip"
                                    >{{$t('index.phoneNumber')}}</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >{{$t('index.otherMethods')}}</el-divider
                            >
                        </el-tab-pane>
                        <el-tab-pane>
                            <span class="certification-type-icon" slot="label">
                                <i class="iconfont guoran-qita"></i>
                                <span class="tabs-name">{{$t('index.qita')}}</span>
                            </span>
                            <div class="certification-form-item">
                                <el-input
                                    disabled
                                    :placeholder="$t('index.companyNamePla')"
                                    prefix-icon="iconfont guoran-a-14-03"
                                    v-model="certification.companyName"
                                >
                                </el-input>
                            </div>
                            <div class="upload">
                                <div class="img">
                                    <el-upload
                                        :action="this.requestUrl.file.upload"
                                        :on-change="handleOtherChange"
                                        :file-list="fileList.other"
                                        list-type="picture-card"
                                        :multiple="false"
                                        :on-success="onUploadOtherSuccess"
                                    >
                                        <i
                                            slot="default"
                                            class="el-icon-plus"
                                        ></i>
                                        <div slot="file" slot-scope="{ file }">
                                            <img
                                                class="
                                                    el-upload-list__item-thumbnail
                                                "
                                                :src="file.url"
                                                alt=""
                                            />
                                            <span
                                                class="
                                                    el-upload-list__item-actions
                                                "
                                            >
                                                <span
                                                    class="
                                                        el-upload-list__item-preview
                                                    "
                                                    @click="
                                                        handlePictureCardPreview(
                                                            file
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-zoom-in"
                                                    ></i>
                                                </span>
                                                <span
                                                    v-if="!disabled"
                                                    class="
                                                        el-upload-list__item-delete
                                                    "
                                                    @click="
                                                        handleOtherRemove(file)
                                                    "
                                                >
                                                    <i
                                                        class="el-icon-delete"
                                                    ></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="desc">
                                    <div class="des-item">
                                        <span class="number">1</span>
                                        <span
                                            >{{$t('index.qitaTip')}}</span
                                        >
                                    </div>
                                    <div class="des-item">
                                        <span class="number">2</span>
                                        <span
                                            >{{$t('index.businessLicenseTip1')}}</span
                                        >
                                    </div>
                                    <div
                                        class="link-span"
                                        @click="
                                            reviewExample('example_other.png')
                                        "
                                    >
                                        <i
                                            class="
                                                iconfont
                                                guoran-dianjichakantuli
                                            "
                                        ></i>
                                        <span>{{$t('index.viewLegend')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certification-post-button">
                                <el-button
                                    type="primary"
                                    @click="saveCertificationInfo(5)"
                                    >{{$t('index.submitCertification')}}</el-button
                                >
                            </div>
                            <div class="prev-step">
                                <span class="prev-text" @click="prevStepClick"
                                    >{{$t('index.previousStep')}}</span
                                >
                                <span class="q-tip"
                                    >{{$t('index.phoneNumber')}}</span
                                >
                            </div>
                            <el-divider content-position="center"
                                >{{$t('index.otherMethods')}}</el-divider
                            >
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </popup>
        <systemUpdateNotice></systemUpdateNotice>
    </div>
</template>

<script>
import SideNav from "./../menu/SideNav.vue";
import IndexNotice from "./components/IndexNotice.vue";
import TopHeader from "./../../components/top-header.vue";
import CompanyInfo from "./components/CompanyInfo.vue";
import CompanyInfobase from "./components/CompanyInfobase.vue";
import Predict from "./components/Predict.vue";
import ActiveService from "./components/ActiveService.vue";
import { redirectUri } from "../../const/redirectUri";
import Popup from "@/components/popup";
import agentConfig from "@/common/wechat/agentConfig";
import systemUpdateNotice from "../../components/systemUpdateNotice.vue";
export default {
    name: "Home",
    components: {
        Popup,
        SideNav,
        TopHeader,
        IndexNotice,
        CompanyInfo,
        Predict,
        ActiveService,
        CompanyInfobase,
        systemUpdateNotice
    },
    data() {
        return {
            activeServiceObj: {
                OPEN: -1,
                IM: -1,
                WORKORDER: -1,
                STATUS: -1,
                KNOWLEDGE:-1,
                ASKAI:-1,
                QUESTION:-1
            },
            botExpiresIn: "",
            activeVersionObj: {
                OPEN: {
                    config: { moduleVersion: { name: "" }, expiresIn: 999 },
                    expiresIn: 999,
                },
                IM: {
                    config: { moduleVersion: { name: "" }, expiresIn: 999 },
                    expiresIn: 999,
                },
                WORKORDER: {
                    config: { moduleVersion: { name: "" }, expiresIn: 999 },
                    expiresIn: 999,
                },
                KNOWLEDGE: {
                    config: { moduleVersion: { name: "" }, expiresIn: 999 },
                    expiresIn: 999,
                },
                ASKAI:{
                    config: { moduleVersion: { name: "" }, expiresIn: 999 },
                    expiresIn: 999,
                },
                QUESTION:{
                    config: { moduleVersion: { name: "" }, expiresIn: 999 },
                    expiresIn: 999,
                }
            },
            percentage: 10,
            colors: [
                { color: "#f56c6c", percentage: 20 },
                { color: "#e6a23c", percentage: 40 },
                { color: "#5cb87a", percentage: 60 },
                { color: "#1989fa", percentage: 80 },
                { color: "#6f7ad3", percentage: 100 },
            ],
            basePhoneInfoForm: {
                phone: "",
                checkCode: "",
            },
            phoneDialogFormVisible: false,
            verifyIng: false,
            verifyTime: 60,
            verifyName: "获取验证码",
            isShowTel: false,
            hasCompanyName: true,
            hasSkill: false,
            skillAndBaseinfoTitle: "",
            selectStep: 2,
            certificationVisible: true,
            currentIp: "",
            tips: {
                phoneNull: this.$t('index.phoneNull'),
                checkCodeNull: this.$t('index.checkCodeNull'),
                realNameNull: this.$t('index.realNameNull'),
                mailNull: this.$t('index.mailNull'),
                emailNull: this.$t('index.emailNull'),
                emailCodeNull: this.$t('index.emailCodeNull'),
                emailCodeError: this.$t('index.emailCodeError'),
                codeSendSuccess: this.$t('index.codeSendSuccess'),
                companyNameNull: this.$t('index.companyNameNull'),
                certUrlNull: this.$t('index.certUrlNull'),
                ewxUrlNull: this.$t('index.ewxUrlNull'),
                ddUrlNull: this.$t('index.ddUrlNull'),
                cardUrlNull: this.$t('index.cardUrlNull'),
                otherUrlNull: this.$t('index.otherUrlNull'),
                saveCertError: this.$t('index.saveCertError'),
                temCodeNull: this.$t('index.temCodeNull'),
            },
            dialogVisible: false,
            updateItem: [
                {
                    title: "工单系统Beta版上线",
                    type: "产品更新",
                    status: "0",
                    content:
                        "用户您好，工单系统已经开放测试，可在AskBot管理中心进入工单系统进行体验测试，如有问题请联系客服进行处理！",
                    time: "2020-10-20",
                },
                {
                    title: "WebHook功能上线",
                    type: "产品更新",
                    status: "1",
                    content:
                        "用户您好，WebHook是AskBot对话机器人非常核心的功能，经过一段时间技术攻关，目前已经上线，从此AskBot机器人不仅可以回答问题，同时可以与各类系统进行对接，让机器人具备一句话办理业务的能力，具体应用场景我们会陆续更新模板，同时也欢迎和我们联系做演示讲解。",
                    time: "2020-11-11",
                },
            ],
            uploadExampleVisible: false,
            uploadExampleUrl: "",
            dialogFormVisible: false,
            certificationDialog: false,
            baseInfoForm: {
                email: "",
                companyName: "",
                realName: "",
                phone: "",
                checkCode: "",
            },
            formLabelWidth: "120px",
            dialogImageUrl: "",
            uploadDialogVisible: false,
            disabled: false,
            isCertified: true,
            username: "",
            realname: "",
            systemModelList: [],
            companyId: "",
            certification: {
                companyName: "",
                companyEmail: "",
                type: 0,
                certUrl: "",
                ewxUrl: "",
                ddUrl: "",
                cardUrl: "",
                otherUrl: "",
            },
            mailCode: "",
            userCompany: {
                status: 0,
                companyName: "",
                canUseWorkOrderUserNumLimit: null,
                canUseCustomerServiceNum:0,
                canUseCustomerNumLimit:null,
                questionnaireNum:0,
                questionnaireNumLimit:1
            },
            entranceStatus: {
                OPEN: false,
                IM: false,
                WORKORDER: false,
            },
            moduleShow: {
                OPEN: false,
                IM: false,
                WORKORDER: false,
            },
            fileList: {
                cert: [],
                ewx: [],
                dd: [],
                card: [],
                other: [],
            },
            intervalTime: 60,
            buttonName: "获取验证码",
            sendDisabled: false,
            serviceExpired: false,
            currentUpdateContent: "",
            currentUpdateTitle: "",
            currentUpdateTime: "",
            articleList: [],
            isPersonalEmailDomain: false,
            rolePrivilegesList: [],
            serviceModeList: [],
            checkTemplateCode: "",
            economizeOnManpower: 100,
            userInfo:{},
            uid:"",
            knowdgeData:{},
            setIsShowTelObj:{},
            modulesList:[],
            botLimitData: {
              totalPeopleNum:0
            },
            openBeOverdueTime:0,
            workerBeOverdueTime:0,
            statusText: ["未认证", "审核中", "已认证", "未通过"],
        };
    },
    computed: {
        reverseUpdateItem() {
            return this.updateItem.reverse();
        },
        systemModelFilterList() {
            let arr = [];
            this.systemModelList.forEach((item) => {
                if (item.code !== "IM") {
                    arr.push(item);
                }
            });
            return arr;
        },
    },
    directives: {
        numberOnly: {
            bind(el) {
                el.handler = function () {
                    el.value = el.value.replace(/\D+/, "");
                    if (el.value.length > 11) {
                        el.value = el.value.substring(0, 11);
                    }
                };
                el.addEventListener("input", el.handler);
            },
            unbind(el) {
                el.removeEventListener("input", el.handler);
            },
        },
    },
    methods: {
        // 获取账号信息
        getUserInfo(data){
            this.userInfo = data;
        },
        moreNotice() {
            this.$router.push({ name: "notice" });
        },
        closeBaseInfoDia() {
            this.dialogFormVisible = false;
            localStorage.setItem("cancel_bind_phone", 1);
        },

        changeEconomizeOnManpower(val) {
            this.economizeOnManpower = Number(val);
        },
        cancelBind() {
            localStorage.setItem("cancel_bind_phone", 1);
            this.phoneDialogFormVisible = false;
        },
        onlyPhoneGetCheckcode() {
            if (this.basePhoneInfoForm.phone.trim() === "") {
                this.$message.error(this.tips.phoneNull);
                return false;
            } else {
                let tel_reg =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                if (!tel_reg.test(this.basePhoneInfoForm.phone.trim())) {
                    this.$message.error(this.$t('index.isPhoneCorrect'));
                    return false;
                }
            }
            this.$http_ignore_auth
                .get(
                    "/api/register/check?phone=" +
                        this.basePhoneInfoForm.phone.trim()
                )
                .then((res) => {
                    console.log(res);
                    if (res.data.code === "0") {
                        if (res.data != null && res.data.data) {
                            this.$message.error(this.$t('index.alreadyExists'));
                            return false;
                        } else {
                            this.$http_ignore_auth
                                .post(
                                    "/api/code/sms/send?phone=" +
                                        this.basePhoneInfoForm.phone.trim()
                                )
                                .then((res) => {
                                    if (res.data.code != 0) {
                                        this.$message.error(res.data.message);
                                    } else {
                                        this.verifyIng = true;
                                        this.verifyTime = 60;
                                        let timer = setInterval(() => {
                                            this.verifyTime =
                                                this.verifyTime - 1;
                                            if (this.verifyTime == 0) {
                                                this.verifyName = this.$t('index.retrieveAgain');
                                                this.verifyIng = false;
                                                clearInterval(timer);
                                            }
                                        }, 1000);
                                    }
                                });
                        }
                    }
                });
        },
        bindPhone() {
            if (this.isBlank(this.basePhoneInfoForm.phone)) {
                this.$message.error(this.tips.phoneNull);
                return;
            }
            let phonReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!phonReg.test(this.basePhoneInfoForm.phone)) {
                this.$message.error(this.$t('index.checkPhone'));
                return;
            }
            if (this.isBlank(this.basePhoneInfoForm.checkCode)) {
                this.$message.error(this.tips.checkCodeNull);
                return;
            }
            this.$http
                .put(
                    this.requestUrl.companyManage.onlyBindPhone +
                        "?phone=" +
                        this.basePhoneInfoForm.phone +
                        "&checkCode=" +
                        this.basePhoneInfoForm.checkCode
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        this.phoneDialogFormVisible = false;
                        localStorage.setItem("cancel_bind_phone", 1);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
        phoneChange(e) {
            this.baseInfoForm.phone = e.target.value;
        },
        setIsShowTel(obj) {
            this.setIsShowTelObj = obj;
            if (obj.tel != null && obj.tel != "null") {
                this.isShowTel = false;
            } else {
                this.isShowTel = true;
                let isCancel = localStorage.getItem("cancel_bind_phone");
                console.log(isCancel,'isCancel');
                if (
                    obj.oldUser &&
                    (obj.source == 2 || obj.source == 4) &&
                    isCancel != 1
                ) {
                    this.phoneDialogFormVisible = true;
                }
                if (
                    isCancel != 1 &&
                    !(obj.oldUser && (obj.source == 2 || obj.source == 4))
                ) {
                    console.log('认证');
                    // this.dialogFormVisible = true;
                }

                this.certificationDialog = false;
            }
        },

        setHasCompanyName(companyName) {
            if (
                localStorage.getItem("has_companyName") == null ||
                localStorage.getItem("has_companyName") == ""
            ) {
                if (companyName != null && companyName != "null") {
                    this.hasCompanyName = true;
                    localStorage.setItem("has_companyName", 1);
                } else {
                    this.hasCompanyName = false;
                    localStorage.setItem("has_companyName", 0);
                }
            } else {
                this.hasCompanyName = Boolean(
                    Number(localStorage.getItem("has_companyName"))
                );
            }
        },

        getCheckcode() {
            if (this.baseInfoForm.phone.trim() === "") {
                this.$message.error(this.tips.phoneNull);
                return false;
            } else {
                let tel_reg =
                    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

                if (!tel_reg.test(this.baseInfoForm.phone.trim())) {
                    this.$message.error(this.$t('index.isPhoneCorrect'));
                    return false;
                }
            }
            this.$http_ignore_auth
                .get(
                    "/api/register/check?phone=" +
                        this.baseInfoForm.phone.trim()
                )
                .then((res) => {
                    console.log(res);
                    if (res.data.code === "0") {
                        if (res.data != null && res.data.data) {
                            this.$message.error(this.$t('index.alreadyExists'));
                            return false;
                        } else {
                            this.$http_ignore_auth
                                .post(
                                    "/api/code/sms/send?phone=" +
                                        this.baseInfoForm.phone.trim()
                                )
                                .then((res) => {
                                    if (res.data.code != 0) {
                                        this.$message.error(res.data.message);
                                    } else {
                                        this.verifyIng = true;
                                        this.verifyTime = 60;
                                        let timer = setInterval(() => {
                                            this.verifyTime =
                                                this.verifyTime - 1;
                                            if (this.verifyTime == 0) {
                                                this.verifyName = this.$t('index.retrieveAgain');
                                                this.verifyIng = false;
                                                clearInterval(timer);
                                            }
                                        }, 1000);
                                    }
                                });
                        }
                    }
                });
        },
        //选择服务模块
        checkTemplate(item) {
            this.checkTemplateCode = item.versionCode;
        },
        retryAuth() {
            console.log('retryAuth');
            if (this.userCompany.status == 0) {
                this.dialogFormVisible = true;
                this.certificationDialog = false;
                this.baseInfoForm.email = "";
                this.baseInfoForm.companyName = "";
                this.baseInfoForm.realName = "";
                this.baseInfoForm.phone = "";
                this.baseInfoForm.checkCode = "";
                this.checkTemplateCode = "";
            } else {
                this.certificationDialog = true;
                this.dialogFormVisible = false;
            }
            this.certification.certUrl = "";
            this.certification.ewxUrl = "";
            this.certification.ddUrl = "";
            this.certification.cardUrl = "";
            this.certification.otherUrl = "";
        },
        onClickService(item) {
            this.$set(item, "checked", !item.checked);
            this.systemModelList = [...this.systemModelList];
        },
        prevStepClick() {
            this.dialogFormVisible = true;
            this.certificationDialog = false;
        },
        reviewExample(imgUrl) {
            this.uploadExampleUrl =
                "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/" +
                imgUrl;
            console.log(this.uploadExampleUrl);
            this.uploadExampleVisible = true;
        },
        addCompanyInfo() {
            if (this.selectStep == 1) {
                if (!this.hasSkill && this.isBlank(this.checkTemplateCode)) {
                    this.$message.error(this.tips.temCodeNull);
                    return;
                }
                let checkModules = [];
                this.systemModelList.forEach((m, i) => {
                    if (m.checked) {
                        checkModules.push(m);
                    }
                });
                this.$http
                    .post(
                        this.requestUrl.companyManage.newFirstBind +
                            "?version=" +
                            this.checkTemplateCode,
                        checkModules
                    )
                    .then((res) => {
                        console.log("bind module result:", res.data);
                        if (res.data && res.data.code === "0") {
                            this.companyId = res.data.data;
                            this.hasSkill = true;
                            this.selectStep = 2;
                            this.skillAndBaseinfoTitle = this.$t('index.infoBase');
                        }
                    })
                    .catch((res) => {
                        // location.hash = "/auth";
                    });
            }
            if (this.selectStep == 2) {
                if (this.isBlank(this.baseInfoForm.realName)) {
                    this.$message.error(this.tips.realNameNull);
                    return;
                }

                if (this.isShowTel) {
                    if (this.isBlank(this.baseInfoForm.phone)) {
                        this.$message.error(this.tips.phoneNull);
                        return;
                    }
                    let phonReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                    if (!phonReg.test(this.baseInfoForm.phone)) {
                        this.$message.error(this.$t('index.checkPhone'));
                        return;
                    }
                    if (this.isBlank(this.baseInfoForm.checkCode)) {
                        this.$message.error(this.tips.checkCodeNull);
                        return;
                    }
                }
                let emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                // 邮箱合法校验
                if (
                    this.baseInfoForm.email.trim() !== "" &&
                    !emailReg.test(this.baseInfoForm.email)
                ) {
                    this.$message.error(this.$t('index.isMailCorrect'));
                    return;
                }
                if (
                    !this.hasCompanyName &&
                    this.isBlank(this.baseInfoForm.companyName)
                ) {
                    this.$message.error(this.tips.companyNameNull);
                    return;
                }
                let paramObj = {
                    companyId: this.companyId,
                    email: this.baseInfoForm.email,
                    companyName: this.baseInfoForm.companyName,
                    realName: this.baseInfoForm.realName,
                };

                let url =
                    this.requestUrl.companyManage.userSubmitBasicInfo +
                    "?realName=" +
                    this.baseInfoForm.realName +
                    "&companyName=" +
                    this.baseInfoForm.companyName +
                    "&companyId=" +
                    this.companyId +
                    "&email=" +
                    this.baseInfoForm.email +
                    "&status=" +
                    this.userCompany.status;

                if (this.isShowTel) {
                    paramObj.phone = this.baseInfoForm.phone;
                    paramObj.checkCode = this.baseInfoForm.checkCode;
                    url =
                        url +
                        "&phone=" +
                        this.baseInfoForm.phone +
                        "&checkCode=" +
                        this.baseInfoForm.checkCode;
                }

                this.$http
                    .get(url)
                    .then((res) => {
                        console.log("bind module result:", res.data);
                        if (res.data && res.data.code === "0") {
                            // this.companyId = res.data.data;
                            this.dialogFormVisible = false;
                            if (this.userCompany.status == 0) {
                                this.certificationDialog = true;
                            } else {
                                this.$message.success(this.$t('index.submitSuccess'));
                            }
                            if(paramObj.companyName !== ''){
                                this.certification.companyName = paramObj.companyName;
                            }
                        } else {
                            this.$message.error(this.$t('index.verificationCodeError'));
                        }
                    })
                    .catch((res) => {
                        // location.hash = "/auth";
                    });
            }
        },
        openUpdateContent(index) {
            this.dialogVisible = true;
            this.currentUpdateTitle = this.articleList[index].title;
            this.currentUpdateContent = this.articleList[index].content;
            this.currentUpdateTime = this.articleList[index].created;
        },
        handleCertRemove(file) {
            this.fileList.cert = [];
            this.certification.certUrl = "";
        },
        handleEwxRemove(file) {
            this.fileList.ewx = [];
            this.certification.ewxUrl = "";
        },
        handleDdRemove(file) {
            this.fileList.dd = [];
            this.certification.ddUrl = "";
        },
        handleCardRemove(file) {
            this.fileList.card = [];
            this.certification.cardUrl = "";
        },
        handleOtherRemove(file) {
            this.fileList.other = [];
            this.certification.otherUrl = "";
        },
        handleCertChange(file, fileList) {
            this.fileList.cert = [file];
        },
        handleEwxChange(file, fileList) {
            this.fileList.ewx = [file];
        },
        handleDdChange(file, fileList) {
            this.fileList.dd = [file];
        },
        handleCardChange(file, fileList) {
            this.fileList.card = [file];
        },
        handleOtherChange(file, fileList) {
            this.fileList.other = [file];
        },
        onUploadCertSuccess(response, file, fileList) {
            this.certification.certUrl = response.data;
            console.log(fileList);
        },
        onUploadEwxSuccess(response, file, fileList) {
            this.certification.ewxUrl = response.data;
            console.log(fileList);
        },
        onUploadDdSuccess(response, file, fileList) {
            this.certification.ddUrl = response.data;
            console.log(fileList);
        },
        onUploadCardSuccess(response, file, fileList) {
            this.certification.cardUrl = response.data;
            console.log(fileList);
        },
        onUploadOtherSuccess(response, file, fileList) {
            this.certification.otherUrl = response.data;
            console.log(fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.uploadDialogVisible = true;
        },
        saveMoreInfo() {
            this.dialogFormVisible = false;
            let that = this;
            setTimeout(function () {
                that.certificationDialog = true;
            }, 1000);
        },
        sendMailCode() {
            let email = this.certification.companyEmail;
            if (email != null && email.length > 0) {
                this.$http
                    .post(this.requestUrl.code.mailSend, null, {
                        params: { email: email },
                    })
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log("send mailCode result:", res.data.data);
                            this.$message.success(this.tips.codeSendSuccess);
                            let that = this;
                            that.sendDisabled = true;
                            let interval = window.setInterval(function () {
                                that.buttonName =
                                    "（" + that.intervalTime + this.$t('index.retrieveAgain1');
                                that.intervalTime = that.intervalTime - 1;
                                console.log(
                                    "current time:",
                                    that.intervalTime,
                                    that.sendDisabled
                                );
                                if (that.intervalTime < 0) {
                                    that.buttonName = this.$t('index.getVerificationCode');
                                    that.intervalTime = 60;
                                    that.sendDisabled = false;
                                    window.clearInterval(interval);
                                }
                            }, 1000);
                        }
                    })
                    .catch((res) => {
                        // location.hash = "/auth";
                    });
            } else {
                this.$message.error(this.tips.emailNull);
            }
        },
        saveCertificationInfo(type) {
            this.certification.type = type;
            console.log("certification:", this.certification);
            if (this.isBlank(this.certification.companyName)) {
                this.$message.error(this.tips.companyNameNull);
                return;
            }
            if (type == 0) {
                let email = this.certification.companyEmail;
                if (this.isBlank(email)) {
                    this.$message.error(this.tips.emailNull);
                    return;
                }
                if (this.isBlank(this.mailCode)) {
                    this.$message.error(this.tips.emailCodeNull);
                    return;
                }
                this.$http
                    .post(this.requestUrl.code.mailCheck, null, {
                        params: { email: email, code: this.mailCode },
                    })
                    .then((res) => {
                        if (res.data && res.data.code === "0") {
                            console.log(
                                "check mailCode result:",
                                res.data.data
                            );
                            if (res.data.data != null && res.data.data) {
                                this.submitCertificationInfo();
                            } else {
                                this.$message.error(this.tips.emailCodeError);
                            }
                        }
                    })
                    .catch((res) => {
                        // location.hash = "/auth";
                    });
                return;
            } else if (type == 1) {
                if (this.isBlank(this.certification.certUrl)) {
                    this.$message.error(this.tips.certUrlNull);
                    return;
                }
            } else if (type == 2) {
                if (this.isBlank(this.certification.ewxUrl)) {
                    this.$message.error(this.tips.ewxUrlNull);
                    return;
                }
            } else if (type == 3) {
                if (this.isBlank(this.certification.ddUrl)) {
                    this.$message.error(this.tips.ddUrlNull);
                    return;
                }
            } else if (type == 4) {
                if (this.isBlank(this.certification.cardUrl)) {
                    this.$message.error(this.tips.cardUrlNull);
                    return;
                }
            } else {
                if (this.isBlank(this.certification.otherUrl)) {
                    this.$message.error(this.tips.otherUrlNull);
                    return;
                }
            }
            this.submitCertificationInfo();
        },
        submitCertificationInfo() {
            this.$http
                .post(
                    this.requestUrl.companyManage.postCompanyAuth +
                        "/" +
                        this.companyId +
                        "?ip=" +
                        this.currentIp,
                    this.certification
                )
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (res.data.data) {
                            this.certificationDialog = false;
                            this.getUserCompany();
                            this.$refs.leftMenuObj.getAuditStatus();
                            this.$alert(
                                this.$t('index.certificationReasonTip'),
                                this.$t('index.submitCertificationSuccess'),
                                {
                                    confirmButtonText: this.$t('index.confirmButtonText'),
                                }
                            );
                        } else {
                            this.$message.error(this.tips.saveCertError);
                        }
                    }
                })
                .catch((res) => {});
        },
        isBlank(value) {
            return value == null || value.length == 0;
        },
        getSystemModelList() {
            this.$http
                .get(this.requestUrl.systemModel.getSystemModel)
                .then((res) => {
                    console.log("modules:", res);
                    if (res.data && res.data.code === "0") {
                        this.systemModelList = res.data.data;
                        this.systemModelList.forEach(item => {
                            if (item.code !== "IM") {
                                item.checked = true;
                            }
                        })
                      sessionStorage.setItem('systemModelList',JSON.stringify(this.systemModelList))
                    }
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
        getServiceList() {
            let url = "/api-open/bind/common/skill-versions";
            this.$http.get(url).then((res) => {
                if (res.data.code === "0") {
                    this.serviceModeList = res.data.data;
                }
            });
        },
        enterModule(redirectUrl) {
            let tempwindow = window.open("_blank");
            tempwindow.location = redirectUrl;
        },
        getUserCompany() {
            this.$http
                .get(this.requestUrl.companyManage.getUserCompany)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (res.data.data != null) {
                            this.userCompany = res.data.data;
                            if(this.userCompany.promoteSurplusDay == null){
                                this.userCompany.promoteSurplusDay = -1;
                            }
                            sessionStorage.setItem('promoteSurplusDay',this.userCompany.promoteSurplusDay);
                            this.userCompany.canUseWorkOrderUserNumLimit =
                                this.userCompany.companyConfig.canUseWorkOrderUserNumLimit;
                            this.userCompany.canUseCustomerNumLimit =  this.userCompany.companyConfig.canUseCustomerNumLimit;
                            this.companyId = this.userCompany.id;
                            this.hasSkill =
                                this.userCompany.skillVersion == null ||
                                this.userCompany.skillVersion == ""
                                    ? false
                                    : true;
                            console.log(this.hasSkill);
                            if (this.hasSkill) {
                                this.selectStep = 2;
                                this.skillAndBaseinfoTitle = this.$t('index.infoBase');
                                console.log(1805);
                            } else {
                                this.selectStep = 1;
                                this.skillAndBaseinfoTitle =
                                    this.$t('index.checkService');
                                console.log(1810);
                            }
                            if (this.userCompany.certification != null) {
                                this.certification =
                                    this.userCompany.certification;
                               
                            }
                            console.log(this.userCompany,'userCompany');
                            let modules = this.userCompany.modules;
                             console.log(modules,'modules');
                            // this.activeServiceObj.STATUS =
                            //     this.userCompany.status;
                               
                            if (modules != null && modules.length > 0) {
                                modules.forEach((m) => {
                                    this.moduleShow[m.moduleCode] = true;
                                    if (m.expiresIn == 0) {
                                        this.serviceExpired = true;
                                    }
                                    m.checked = false;
                                    if (
                                        m.sysModule.name === "AskBot对话机器人" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_OPEN"
                                        ) !== -1
                                    ) {
                                        // this.activeServiceObj.OPEN = m.sysModule.portalUrl;
                                        this.botExpiresIn = m.expiresIn;
                                        this.activeVersionObj.OPEN = m;
                                        m.checked = true;
                                    } else if (
                                        m.sysModule.name === "AskChat即时通讯IM" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_IM"
                                        ) !== -1
                                    ) {
                                        m.checked = true;
                                        // this.activeServiceObj.IM = m.sysModule.portalUrl;
                                        this.activeVersionObj.IM = m;
                                    } else if (
                                        m.sysModule.name === "AskService工单系统" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_WORKORDER"
                                        ) !== -1
                                    ) {
                                        // this.activeServiceObj.WORKORDER = m.sysModule.portalUrl;
                                        this.activeVersionObj.WORKORDER = m;
                                        m.checked = true;
                                    } else if (
                                        m.sysModule.name === "AskBot知识管理" && 
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_KNOWLEDGE"
                                        ) !== -1
                                    ) {
                                        // this.activeServiceObj.KNOWLEDGE = m.sysModule.portalUrl;
                                        this.activeVersionObj.KNOWLEDGE = m;
                                        m.checked = true;
                                    } else if (m.sysModule.name === "AskAI"){
                                        this.activeVersionObj.ASKAI = m;
                                        m.checked = true;
                                    } else if(m.sysModule.name === "Ask问卷系统") {
                                        this.activeVersionObj.QUESTION = m
                                        m.checked = true;
                                    } 
                                });
                            } else if (this.userCompany.creator == -1) {
                                this.moduleShow.OPEN = true;
                                this.moduleShow.IM = true;
                                this.moduleShow.WORKORDER = true;
                            }
                        }
                        if (this.userCompany.status == 0) {
                            if(this.userCompany.userBuild){
                                this.dialogFormVisible = true;
                                this.certificationDialog = false;
                                this.certificationVisible = true;
                            } else {
                                if (
                                    this.userCompany.name == "" ||
                                    this.userCompany.name == null
                                ) {
                                    this.dialogFormVisible = true;
                                    console.log(22222);
                                    this.certificationDialog = false;
                                    this.certificationVisible = true;
                                } else {
                                    this.dialogFormVisible = false;
                                    this.certificationDialog = true;
                                    this.certificationVisible = true;
                                }
                            }
                        }
                        if (
                            this.userCompany.status == 2 ||
                            this.userCompany.status == 1
                        ) {
                            this.certificationVisible = false;
                            this.entranceStatus.OPEN = true;
                            this.entranceStatus.IM = true;
                            this.entranceStatus.WORKORDER = true;
                        }
                        this.getSelectCompanyPay()
                    }
                    console.log(this.activeServiceObj,'activeServiceObj');
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
        openGetMoreEdition() {
            this.$alert(this.$t('index.getMoreEditionTip'), this.$t('index.getMoreEditionTitle'), {
                confirmButtonText: this.$t('index.getMoreConfirmText'),
                callback: (action) => {},
            });
        },
        getCurrentIp() {
            let ipCheckUrl = "https://www.askbot.cn/ext-api/ip";
            this.$http.get(ipCheckUrl).then((res) => {
                console.log("============current ip:", res);
                if (res != null && res.data != null) {
                    this.currentIp = res.data.ip;
                }
            });
        },
        getArticleList() {
            // this.$http
            //     .get("/api-blog/article/page?page=1&size=3&categoryId=14")
            //     .then((res) => {
            //         this.articleList = res.data.list;
            //         console.log("res:" + res.data.list);
            //     });
        },
        blurCompanyEmailInput() {
            this.isPersonalEmailDomain = false;
            let that = this;
            let personalEmailDomail = [
                "163.com",
                "126.com",
                "qq.com",
                "foxmail.com",
                "aliyun.com",
                "139.com",
                "189.com",
                "sina.com",
                "tom.com",
                "88.com",
                "yeah.com",
                "outlook.com",
                "gmail.com",
            ];
            for (let index = 0; index < personalEmailDomail.length; index++) {
                if (
                    that.certification.companyEmail.indexOf(
                        personalEmailDomail[index]
                    ) != -1
                ) {
                    that.isPersonalEmailDomain = true;
                }
            }
        },
      getWechatCorp(){
        let mainId = localStorage.getItem('_mainId');
        let uid = localStorage.getItem('_uid');
        let url = this.requestUrl.agentConfig.getWechat+'?mainId='+mainId+'&uid='+uid
        this.$http.get(url).then(res=>{
          console.debug('res',res)
          /*if (res.data&&res.data.weChatKfPermanentCodeResponse!=null && res.data.weChatKfPermanentCodeResponse.auth_corp_info){
            let corpId = res.data.weChatKfPermanentCodeResponse.auth_corp_info.corpid;
            this.initWxAgentConfig(corpId);
          }*/
          if (res.data.code==='0'){
            if (res.data.data.length !==0){
              this.initWxAgentConfig(res.data.data[0].corpId)
            }
          }
        })
      },
      initWxAgentConfig(corpId){
        //agentConfig
        let currentUrl = encodeURIComponent(window.location.origin + window.location.pathname);
        let url =  this.requestUrl.agentConfig.agentConfigNew + "?corpId="+corpId+ "&url="+currentUrl;
        this.$http.get(url).then(res=>{
          console.info('load init wx agent config ',corpId,res);
          if (res.data&&res.data.data){
            agentConfig(corpId,res.data.data.data,(res)=>{
              console.info('agent auth config success',res);
              WWOpenData.bind(this.$el);
            });
          }
        })
      },
      getSelectCompanyPay() {
            this.$http.get(this.requestUrl.knowledge.selectCompanyPay+"?mainId=" +  localStorage.getItem('_mainId')).then((res) => {
                console.log(res,'resres');
                    if (res.data && res.data.code === "0") {
                        if (res.data.data != null) {
                            res.data.data.totalCapacityCopy = (res.data.data.totalCapacity/1024/1024/1024).toFixed(1) + 'GB';
                            // res.data.data.usedCapacityCopy = (res.data.data.usedCapacity/1024/1024/1024).toFixed(1) + 'GB';
                            let usedCapacity = (res.data.data.usedCapacity / 1024 / 1024 / 1024).toFixed(1);
                            if (usedCapacity >= 1) {
                                res.data.data.usedCapacityCopy = (res.data.data.usedCapacity / 1024 / 1024 / 1024).toFixed(1) + 'GB';
                            } else { 
                                res.data.data.usedCapacityCopy = (res.data.data.usedCapacity / 1024 / 1024).toFixed(1) + 'MB';
                            }
                            this.knowdgeData = res.data.data;
                        }
                    }
                })
                .catch(() => {});
        },
        // 获取用户的系统入口
        // api/company接口速度稍微慢些,导致页面体验不是很好,所以使用此接口获取系统入口
        getUserCompanyModule(){
             this.$http.get(this.requestUrl.companyManage.getUserCompanyModule).then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (res.data.data != null) {
                            this.activeServiceObj.STATUS = res.data.data.status;

                            // res.data.data.modules.forEach(item => {
                            // })

                            for (let index = 0; index < res.data.data.modules.length; index++) {
                                const item = res.data.data.modules[index];
                                if (item.sysModule.name === "AskBot对话机器人" && this.rolePrivilegesList.indexOf( "PORTAL_ENTRANCE_OPEN" ) !== -1) {
                                    this.activeServiceObj.OPEN = item.sysModule.portalUrl;
                                    this.modulesList.push(item.moduleCode);
                                } else if (item.sysModule.name === "AskChat即时通讯IM" && this.rolePrivilegesList.indexOf( "PORTAL_ENTRANCE_IM" ) !== -1) {
                                    this.activeServiceObj.IM = item.sysModule.portalUrl;
                                    this.modulesList.push(item.moduleCode);
                                } else if (item.sysModule.name === "AskService工单系统" && this.rolePrivilegesList.indexOf( "PORTAL_ENTRANCE_WORKORDER") !== -1) {
                                    this.activeServiceObj.WORKORDER = item.sysModule.portalUrl;
                                    this.modulesList.push(item.moduleCode);
                                } else if(item.sysModule.name === "管理系统") {
                                    // 不能去掉! T_T
                                }  else if (item.sysModule.name === "AskBot知识管理" && this.rolePrivilegesList.indexOf("PORTAL_ENTRANCE_KNOWLEDGE") !== -1) {
                                    this.activeServiceObj.KNOWLEDGE = item.sysModule.portalUrl;
                                    this.modulesList.push(item.moduleCode);
                                } else if (item.sysModule.name === "AskAI") {
                                    this.activeServiceObj.ASKAI = item.sysModule.portalUrl;
                                    this.modulesList.push(item.moduleCode);
                                } else if(item.sysModule.name === "Ask问卷系统") {
                                    this.activeServiceObj.QUESTION = item.sysModule.portalUrl;
                                    this.modulesList.push(item.moduleCode);
                                } 
                            console.log(this.modulesList, item.sysModule, 'this.modulesListthis.modulesList');

                            }
                    

                            localStorage.setItem("activeServiceObj", JSON.stringify(this.activeServiceObj))
                        }
                    }
                })
                .catch(() => {});
        },
      percentageFormat(use, all) {
        let allval = Number(all),
            useVal = Number(use);
        if ((allval === -1 || allval === "∞" || allval == 0 || allval == "null") && useVal != 0) {
          return 5;
        } else if (useVal == 0){
          return 0;
        } else {
          return (useVal / allval) * 100;
        }
      },
      nowRenew(){
          this.$refs.companyInfo.nowRenew();
      },
      isShowVersionDuration(){
        let bool = true;
        let arr = []
        if (this.userCompany.modules){
          for (let i=0; i<this.userCompany.modules.length;i++){
            let module = this.userCompany.modules[i]
            if (module.moduleCode !== 'MANAGEMENT' && arr.indexOf(module.expiresIn) === -1){
              arr.push(module.expiresIn)
            }
          }
        }
        console.log('arr',arr)
        if (arr.length > 1){
          bool = false;
        }
        return bool
      }
    },
    created(){
        this.getUserCompanyModule();
        this.getUserCompany();
    },
    mounted() {
        this.uid = localStorage.getItem('_uid')
        this.$nextTick(() => {
            this.username = localStorage.getItem("username");
            this.realname = localStorage.getItem("realname");
            this.getSystemModelList();
            this.getServiceList();
            console.log(this.$refs.grleftcontent.offsetHeight);
        });
        this.getCurrentIp();
        this.getArticleList();
        this.rolePrivilegesList = this.getRolePrivileges();
        this.getWechatCorp();
    },

    beforeRouteLeave(to,from,next){
        // 0  未认证   1 审核中    2 已认证   3 未通过
        // 未认证企业不允许跳转企业管理菜单
        if(to.path === '/_accountManage' || to.path === '/_personal'){
            if(this.userCompany.status == 0){
                this.dialogFormVisible = true;
                this.certificationDialog = false;
                this.certificationVisible = true;
            } else if (this.userCompany.status == 1) {
                this.$message.warning(this.$t('index.waitCertification'))
            } else if (this.userCompany.status == 3){
                this.$message.warning(this.$t('index.certificationFail'))
            } else {
                next()
            }
        } else {
            next()
        }
    },
    watch:{
      activeVersionObj:{
        handler(n){
          if(n.OPEN.expiresIn !== '无限制'){
            this.openBeOverdueTime = Math.abs(Number(n.OPEN.expiresIn));
          }
          if(n.WORKORDER.expiresIn !== '无限制'){
            this.workerBeOverdueTime = Math.abs(Number(n.WORKORDER.expiresIn));
          }
        },
        deep:true
      },
    }
};
</script>

<style lang="less">
@import "./../../assets/less/index/indexV2.less";

.spic-certification-form-item{
    .el-input__inner{
        border-radius: 6px 0 0 6px !important;
        border-right: none !important;
    }
    .el-button{
        border-radius: 0 6px 6px 0 !important;
    }
}

.cancel-bind {
    display: inline-block;
    border-radius: 16px;
    line-height: 32px;
    height: 32px;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    padding: 0 30px;
    margin-right: 30px;
}
.cancel-bind:hover {
    background-color: #ecf5ff;
    color: #366aff;
    border: 1px solid #366aff;
}
.dialogImg {
    img {
        width: 450px;
        object-fit: contain;
    }
}
#popup-assembly {
    width: 600px;
    .popup-tip {
        /* padding-top: 0!important;*/
    }
    .popup-header {
        padding-bottom: 24px !important;
    }
    .popup-name {
        /*font-size: 18px!important;*/
        color: #000000;
    }
    .system-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 40px;
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
        .dialog-left-header {
            padding: 20px;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            span {
                font-size: 13px;
                color: #dddddd;
            }
        }
        .service-item {
            border: 1px solid #dae1f6;
            width: 100px;
            height: 100px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            i {
                font-size: 16px;
            }
            span {
                margin-top: 10px;
            }
            .check-icon {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-top: 15px solid transparent;
                border-bottom: 15px solid #366aff;
                border-right: 15px solid #366aff;
                border-bottom-right-radius: 3px;
                i {
                    color: white;
                    position: absolute;
                    top: -2px;
                    font-size: 14px;
                }
            }
        }
        .click {
            border: #366aff 1px solid;
            color: #366aff;
            /* background: url('../../assets/images/u407.png') no-repeat bottom right;*/
            background-size: 30px 30px;
        }
    }
    .service-list {
        .header-name {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            padding-bottom: 20px;
        }
        .service-mode-list {
            padding-bottom: 40px;
            .service-item {
                padding: 16px 20px;
                border: 2px solid #dae1f6;
                border-radius: 5px;
                display: flex;
                align-items: center;
                margin-bottom: 38px;
                position: relative;
                .item-left {
                    margin-right: 18px;
                    /deep/img {
                        width: 85px;
                        height: 85px;
                        object-fit: contain;
                    }
                }
                .item-right {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    .version-name {
                        display: flex;
                        font-size: 18px;
                        font-weight: 600;
                    }
                    .version-detail {
                        display: flex;
                        font-size: 12px;
                        color: #a0a7b5;
                        margin-top: 14px;
                    }
                }
                .check-icon {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid #366aff;
                    border-right: 15px solid #366aff;
                    border-bottom-right-radius: 3px;
                    i {
                        color: white;
                        position: absolute;
                        top: -2px;
                        font-size: 14px;
                    }
                }
            }
            .service-item:last-child {
                margin-bottom: 0 !important;
            }
            .check-template {
                border: 2px solid #3676ff !important;
            }
        }
    }
    .certification-content {
        margin-top: 20px;
        .el-input__inner {
            border: 1px solid #e0e6f7;
        }
        .el-tabs__item {
            height: 90px;
            padding: 0px !important;
            .certification-type-icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                color: #a9b3c6;
                i {
                    font-size: 32px;
                    border: 1px solid #dae1f6;
                    width: 55px;
                    height: 55px;
                    line-height: 55px;
                    border-radius: 50%;
                    text-align: center;
                    color: #a1b9ff;
                }
                .tabs-name {
                    margin-right: 10px;
                }
            }
        }
        .is-active {
            i {
                border: 1px solid #366aff !important;
                color: #366aff !important;
            }
            .tabs-name {
                color: #366aff !important;
            }
        }
        .certification-form-item {
            width: 100%;
            padding-bottom: 20px;
            display: flex;
            flex-direction: row;
            .el-button {
                background: #366aff;
                border-radius: 5px;
            }
            .el-input__icon {
                font-size: 16px !important;
            }
        }
        .certification-post-button {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 25px;
            display: flex;
            flex-direction: row;
            .el-button {
                width: 100%;
                background: #366aff;
                border-radius: 19px;
            }
        }
        .upload {
            width: 100%;
            text-align: left;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            .img {
                flex: none;
                width: 100px;
                display: flex;
                flex-direction: row;
                margin-right: 15px;
                .el-upload--picture-card {
                    width: 95px;
                    height: 95px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #fafbff;
                    i {
                        color: #87a9ff;
                        font-size: 22px;
                    }
                }
                .el-upload-list__item {
                    width: 70px;
                    height: 70px;
                }
            }
            .desc {
                display: flex;
                flex-direction: column;
                .des-item {
                    display: flex;
                    /* align-items: center;*/
                    color: #a9b3c6;
                    padding: 6px 0;
                }
                .link-span {
                    line-height: 30px;
                    cursor: pointer;
                    color: #366aff;
                    padding-top: 10px;
                    display: flex;
                    align-items: center;
                    i {
                        margin-right: 7px;
                    }
                }
                .number {
                    flex: none;
                    width: 20px;
                    height: 20px;
                    background: #a1b9ff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    margin-right: 4px;
                }
            }
        }
    }
    .service-dialog-footer {
        .el-button {
            background: #366aff;
            border-radius: 19px;
        }
    }
}
.el-form-item__content {
    text-align: left;
}
.el-input__icon {
    font-size: 20px;
}
.el-tabs__nav-wrap::after {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-tabs__active-bar {
    height: 0px;
    background-color: transparent !important;
}
.el-tabs__nav {
    float: none !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dialog-update-content {
    padding: 20px;
    height: 300px;
    line-height: 35px;
    div {
        text-align: left;
        color: #4a4a4a;
        text-indent: 2em;
    }
    .team {
        position: absolute;
        bottom: 80px;
        right: 40px;
        text-align: right;
    }
    .update-time {
        position: absolute;
        bottom: 40px;
        right: 40px;
        text-align: right;
    }
}
.el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    text-align: center;
    .certification-container {
        padding: 20px;
        .certification-type {
            font-size: 16px;
            font-weight: 600;
        }
        .certification-content {
            margin-top: 20px;
            .el-tabs__item {
                height: 90px;
                padding: 0px !important;
                .certification-type-icon {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    color: #dddddd;
                    i {
                        font-size: 36px;
                        border: 1px solid #dddddd;
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        border-radius: 50%;
                        text-align: center;
                    }
                    .tabs-name {
                        margin-right: 10px;
                    }
                }
            }
            .is-active {
                i {
                    border: 1px solid #366AFF !important;
                    color: #366AFF !important;
                }
                .tabs-name {
                    color: #366AFF !important;
                }
            }
            .certification-form-item {
                width: 100%;
                margin: 15px 0;
                display: flex;
                flex-direction: row;
            }
            .certification-post-button {
                width: 100%;
                margin: 15px 0;
                display: flex;
                flex-direction: row;
                .el-button {
                    width: 100%;
                }
            }
            .upload {
                width: 100%;
                text-align: left;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .img {
                    width: 35%;
                    display: flex;
                    flex-direction: row;
                    .el-upload--picture-card {
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                    }
                    .el-upload-list__item {
                        width: 70px;
                        height: 70px;
                    }
                }
                .desc {
                    display: flex;
                    flex-direction: column;
                    span {
                        line-height: 35px;
                    }
                    .link-span {
                        line-height: 35px;
                        cursor: pointer;
                        color: #366AFF;
                    }
                }
            }
        }
    }
}
.dialog-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .dialog-left {
        width: 50%;
        text-align: left;
        display: flex;
        flex-direction: row;
        //background-color: #f1f6f7;
        border-right: solid 5px #dddddd;
        align-items: center;
        justify-content: center;
        .dialog-left-header {
            padding: 20px;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            span {
                font-size: 13px;
                color: #dddddd;
            }
        }
        .service-item {
            border: #dddddd 1px solid;
            width: 100px;
            height: 100px;
            text-align: center;
            padding: 10px;
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            border-radius: 5px;
            cursor: pointer;
            i {
                font-size: 42px;
            }
            span {
                margin-top: 10px;
            }
        }
        .click {
            border: #366AFF 1px solid;
            color: #366AFF;
            background: url("../../assets/images/u407.png") no-repeat bottom
                right;
            background-size: 30px 30px;
        }
    }
    .dialog-right {
        width: 50%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .el-form {
            width: 90% !important;
            text-align: center;
            margin-left: 20px;
            .el-input {
                margin-bottom: 20px !important;
            }
        }
    }
}

.prev-step {
    display: flex;
    align-items: center;
    /* span {
        display: inline-block;
        text-align: center;
    };*/
    .prev-text {
        flex: none;
        color: #366aff;
        cursor: pointer;
        border: 1px solid #b7caff;
        border-radius: 14px;
        height: 26px;
        width: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .q-tip {
        color: #000000;
        flex: none;
        width: 260px;
    }
}
.prev-step:after {
    content: "";
    display: inline-block;
    overflow: hidden;
    width: 100%;
}
.inputErrorText {
    margin-top: -24px;
    color: red;
    text-align: left;
    font-size: 12px;
    padding-bottom: 30px;
}
.inputErrorBorder {
    border: 1px solid red;
    border-radius: 5px;
    width: 99% !important;
    padding-bottom: 36px !important;
}
</style>
