<template>
    <div id="intellect-guide">
        <div class="ig-typesetting">
            <el-radio v-model="unit.content.typesetting" label="vertical"
                >{{$t('flowComponent.actionIntellectGuide.vertical')}}</el-radio
            >
            <el-radio v-model="unit.content.typesetting" label="horizontal"
                >{{$t('flowComponent.actionIntellectGuide.horizontal')}}</el-radio
            >
        </div>
        <div class="ig-edit-ig">
            <el-button
                @click="editIntellectGuide"
                class="gr-el-button-icon-plain-round"
                icon="guoran-tongyichicun-16-15-lianjibianji iconfont"
                size="mini"
                plain
                round
                >{{$t('flowComponent.actionIntellectGuide.edit')}}</el-button
            >
        </div>
        <div v-if="!showExmaple" class="ig-view">
            <!-- 描述 -->
            <div
                v-show="
                    unit.content.descriptionVisible &&
                    unit.content.description != ''
                "
                v-html="unit.content.description"
                class="ig-types-des"
            >
            </div>
            <!-- 一级分类 -->
            <div v-show="unit.content.typesVisible" class="ig-types-f">
                <span
                    v-for="(fType, fTypeIndex) in unit.content.options"
                    :key="`f_${fTypeIndex}`"
                    @click="changeFirstType(fTypeIndex)"
                    :class="[
                        'ig-types-f-cell',
                        activeFirstTypeIndex === fTypeIndex
                            ? 'ig-types-f-cell-active'
                            : '',
                    ]"
                    >{{ fType.name }}</span
                >
            </div>
            <!-- 二级分类 -->
            <div v-show="unit.content.typesVisible" class="ig-types-s">
                <span
                    v-for="(sType, sTypeIndex) in unit.content.options[
                        activeFirstTypeIndex
                    ].types"
                    :key="`s_${sTypeIndex}`"
                    @click="changeLastType(sTypeIndex)"
                    :class="[
                        'ig-types-s-cell',
                        activeSecondTypeIndex === sTypeIndex
                            ? 'ig-types-s-cell-active'
                            : '',
                    ]"
                    >{{ sType.name }}</span
                >
            </div>
            <!-- 横向排版 -->
            <div
                v-show="
                    activeOtherObj.recommendType == 0 &&
                    unit.content.typesetting === 'horizontal'
                "
                class="ig-types-tags"
            >
                <span
                    v-for="(
                        intentCell, intentCellIndex
                    ) in recommendIntentPageList(activeOtherObj)"
                    :key="`i_${intentCellIndex}`"
                    class="ig-types-tags-cell"
                    >{{ intentCell.questionName }}</span
                >
            </div>
            <!-- 竖向排版 -->
            <div
                v-show="
                    activeOtherObj.recommendType == 0 &&
                    unit.content.typesetting === 'vertical'
                "
                class="ig-types-list"
            >
                <span
                    v-for="(
                        intentCell, intentCellIndex
                    ) in recommendIntentPageList(activeOtherObj)"
                    :key="`i_${intentCellIndex}`"
                    class="ig-types-list-cell"
                >
                    <span class="ig-types-list-cell-name">{{
                        intentCell.questionName
                    }}</span>
                    <span><i class="el-icon-arrow-right"></i></span
                ></span>
            </div>
            <div
                v-show="activeOtherObj.recommendType == 1"
                class="ig-high-frequency-empty"
            >
              {{$t('flowComponent.actionIntellectGuide.tip1')}}
            </div>
            <div
                v-show="activeOtherObj.recommendType == 2"
                class="ig-high-frequency-empty"
            >
              {{$t('flowComponent.actionIntellectGuide.tip2')}}
            </div>
            <div
                v-if="unit.content.groupVisible && totalPage > 1"
                class="ig-change-list"
            >
                <el-button
                    @click="changeIntellectGuide"
                    class="gr-el-button-icon-plain-round"
                    icon="el-icon-refresh"
                    size="mini"
                    plain
                    round
                    >{{$t('common.changeBatch')}}</el-button
                >
            </div>
        </div>
        <div v-if="showExmaple" class="ig-view">
            <div class="ig-view-example">
                <img
                    src="./../../../../assets/images/example_pre_1.png"
                    alt=""
                    srcset=""
                />
            </div>
            <!-- 描述 -->
            <div
                v-show="exmapleObj.content.descriptionVisible"
                class="ig-types-des"
            >
                {{ exmapleObj.content.description }}
            </div>
            <!-- 一级分类 -->
            <div v-show="exmapleObj.content.typesVisible" class="ig-types-f">
                <span
                    v-for="(fType, fTypeIndex) in exmapleObj.content.options"
                    :key="`f_${fTypeIndex}`"
                    :class="[
                        'ig-types-f-cell',
                        activeFirstTypeIndex === fTypeIndex
                            ? 'ig-types-f-cell-active'
                            : '',
                    ]"
                    >{{ fType.name }}</span
                >
            </div>
            <!-- 二级分类 -->
            <div v-show="exmapleObj.content.typesVisible" class="ig-types-s">
                <span
                    v-for="(sType, sTypeIndex) in exmapleObj.content.options[
                        activeFirstTypeIndex
                    ].types"
                    :key="`s_${sTypeIndex}`"
                    :class="[
                        'ig-types-s-cell',
                        activeSecondTypeIndex === sTypeIndex
                            ? 'ig-types-s-cell-active'
                            : '',
                    ]"
                    >{{ sType.name }}</span
                >
            </div>
            <!-- 横向排版 -->
            <div
                v-show="
                    exmapleObjActiveOtherObj.recommendType == 0 &&
                    unit.content.typesetting === 'horizontal'
                "
                class="ig-types-tags"
            >
                <span
                    v-for="(
                        intentCell, intentCellIndex
                    ) in exmapleObjActiveOtherObj.recommendIntentList"
                    :key="`i_${intentCellIndex}`"
                    class="ig-types-tags-cell"
                    >{{ intentCell.questionName }}</span
                >
            </div>
            <!-- 竖向排版 -->
            <div
                v-show="
                    exmapleObjActiveOtherObj.recommendType == 0 &&
                    unit.content.typesetting === 'vertical'
                "
                class="ig-types-list"
            >
                <span
                    v-for="(
                        intentCell, intentCellIndex
                    ) in exmapleObjActiveOtherObj.recommendIntentList"
                    :key="`i_${intentCellIndex}`"
                    class="ig-types-list-cell"
                >
                    <span class="ig-types-list-cell-name">{{
                        intentCell.questionName
                    }}</span>
                    <span><i class="el-icon-arrow-right"></i></span
                ></span>
            </div>
            <div
                v-show="exmapleObjActiveOtherObj.recommendType == 1"
                class="ig-high-frequency-empty"
            >
              {{$t('flowComponent.actionIntellectGuide.tip1')}}
            </div>
            <div
                v-show="exmapleObjActiveOtherObj.recommendType == 2"
                class="ig-high-frequency-empty"
            >
              {{$t('flowComponent.actionIntellectGuide.tip2')}}
            </div>
            <div v-if="exmapleObj.content.groupVisible" class="ig-change-list">
                <el-button
                    @click="changeIntellectGuide"
                    class="gr-el-button-icon-plain-round"
                    icon="el-icon-refresh"
                    size="mini"
                    plain
                    round
                    >{{$t('common.changeBatch')}}</el-button
                >
            </div>
        </div>
        <popup v-if="editGiDialog" @closeEvent="editGiDialog = false">
            <div slot="popup-name" class="popup-name">{{$t('flowComponent.actionIntellectGuide.edit')}}</div>
            <div slot="popup-tip">{{$t('flowComponent.actionIntellectGuide.editTip')}}</div>
            <div slot="popup-con">
                <editig
                    ref="editig"
                    :intentTree4Radio="intentTree4Radio"
                    :compIndex="compIndex"
                    :unitObj="unit"
                    :isBotLink="isBotLink"
                    @templateConfigVisibleHandle="templateConfigVisibleHandle"
                ></editig>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="editGiDialog = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button type="primary" size="small" @click="saveActiveEditIg"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </popup>
    </div>
</template>
<script>
import Popup from "./../../../../components/popup";
import Editig from "./../other/EditIntellectGuide.vue";
import { answerInit } from "./../../utils/answerInit";
export default {
    name: "intellectGuide",
    props: [
        "unit",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "isStartOrEnd",
        "compIndex",
        "isBotLink",
        "unitIndex"
    ],
    components: { Popup, Editig },
    data() {
        return {
            totalPage: 0,
            activePage: 1,
            activeFirstTypeIndex: 0,
            activeSecondTypeIndex: 0,
            activeOtherObj: {},
            editGiDialog: false,
            showExmaple: true,
            exmapleObj: {
                // 智能引导
                type: "answer_intellect_guide",
                conditionList: [],
                content: {
                    typesetting: "vertical", // 排版方式 vertical 竖向 horizontal 横向
                    description: "", // 描述
                    descriptionVisible: true, // 是否展示描述
                    typesVisible: true, // 是否展示分类信息
                    groupVisible: true, // 是否分组展示
                    groupNumber: 3, // 每组展示数量
                    options: [
                        {
                            name: "常见问题",
                            recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                            recommendIntentList: [
                                { questionName: "", intentId: "" },
                            ], // recommendType = 0
                            highFrequency: {
                                // recommendType = 1
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            intelligenceRecommend: {
                                // recommendType = 2
                                recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            types: [],
                        },
                        {
                            name: "业务问题",
                            recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                            recommendIntentList: [
                                { questionName: "", intentId: ""},
                            ], // recommendType = 0
                            highFrequency: {
                                // recommendType = 1
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            intelligenceRecommend: {
                                // recommendType = 2
                                recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            types: [],
                        },
                        {
                            name: "硬件问题",
                            recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                            recommendIntentList: [
                                { questionName: "", intentId: "" },
                            ], // recommendType = 0
                            highFrequency: {
                                // recommendType = 1
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            intelligenceRecommend: {
                                // recommendType = 2
                                recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            types: [],
                        },
                        {
                            name: "软件问题",
                            recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                            recommendIntentList: [
                                { questionName: "", intentId: "" },
                            ], // recommendType = 0
                            highFrequency: {
                                // recommendType = 1
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            intelligenceRecommend: {
                                // recommendType = 2
                                recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            types: [],
                        },
                        {
                            name: "特殊规则",
                            recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                            recommendIntentList: [
                                { questionName: "", intentId: "" },
                            ], // recommendType = 0
                            highFrequency: {
                                // recommendType = 1
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            intelligenceRecommend: {
                                // recommendType = 2
                                recommendStrategy: [0, 1], // 0 根据与当前用户咨询过相同意图的用户所问的意图进行推荐 1 根据当前用户部门的其他用户咨询过的意图进行推荐
                                rang: [], // 推荐范围
                                number: 20, // 最多推荐问题数
                            },
                            types: [],
                        },
                    ], // 分类和意图数组 二维数组
                },
            },
            exmapleObjActiveOtherObj: {
                name: "打印机问题",
                recommendType: 0, // 0 关联意图 1 高频推荐意图 2 智能推荐
                recommendIntentList: [
                    {
                        questionName: "不能开机",
                        intentId: "",
                    },
                    {
                        questionName: "卡纸怎么办",
                        intentId: "",
                    },
                    {
                        questionName: "打印不清楚",
                        intentId: "",
                    },
                    {
                        questionName: "重复打印",
                        intentId: "",
                    },
                    {
                        questionName: "有报警声",
                        intentId: "",
                    },
                ], // recommendType = 0
            },
        };
    },
    methods: {
        templateConfigVisibleHandle(param){
            this.$emit("templateConfigVisibleHandle", param);
        },
        // 根据当前分组数页数返回展示列表
        recommendIntentPageList(obj) {
            let list = obj.recommendIntentList;
            let arr = [];
            let len = 0;
            if (list) {
                len = list.length;

                // 每组展示数
                let groupNumber = this.unit.content.groupNumber;

                this.totalPage = Math.ceil(len / groupNumber);
                if (this.unit.content.groupVisible) {
                    arr = list.slice(
                        (this.activePage - 1) * groupNumber,
                        this.activePage * groupNumber
                    );
                } else {
                    arr = list;
                }
                
            }
            return arr;
        },
        changeFirstType(fTypeIndex) {
            this.activeFirstTypeIndex = fTypeIndex;
            if (this.unit.content.options[fTypeIndex].types.length === 0) {
                this.activeOtherObj = this.unit.content.options[fTypeIndex];
            } else {
                this.activeSecondTypeIndex = 0;
                this.activeOtherObj =
                    this.unit.content.options[fTypeIndex].types[0];
            }
            this.activePage = 1;
        },
        changeLastType(sTypeIndex) {
            this.activeSecondTypeIndex = sTypeIndex;
            this.activeOtherObj =
                this.unit.content.options[this.activeFirstTypeIndex].types[
                    sTypeIndex
                ];
            this.activePage = 1;
        },
        // 编辑回显智能引导
        editIntellectGuide() {
            this.editGiDialog = true;
        },
        // 换一批引导
        changeIntellectGuide() {
            if (this.activePage === this.totalPage) {
                this.activePage = 1;
            } else {
                this.activePage++;
            }
        },
        //
        saveActiveEditIg() {
            let unitStr = JSON.stringify(this.unit).replace(/ /g, "");
            let preStr = JSON.stringify(
                answerInit("answer_intellect_guide", true, [], "")
            ).replace(/ /g, "");
            if (unitStr.length !== preStr.length) {
                this.showExmaple = false;
            } else {
                this.showExmaple = true;
            }

            this.$refs.editig.printUnit(this.compIndex, (unit) => {
                this.unit = { ...unit };
                let fTypeIndex = this.activeFirstTypeIndex;
                if (this.unit.content.options[fTypeIndex].types.length === 0) {
                    this.activeOtherObj = this.unit.content.options[fTypeIndex];
                } else {
                    this.activeOtherObj =
                        this.unit.content.options[fTypeIndex].types[
                            this.activeSecondTypeIndex
                        ];
                }
                this.activePage = 1;
                this.$emit("saveAigConfig", this.unit, this.compIndex);
                this.editGiDialog = false;
            });
        },
        addConfirmDataEntity(item, confirmPlaceholderEditabel) {
            this.$refs.editig.addConfirmDataEntity(item, confirmPlaceholderEditabel)
        },
        addWebHookSinglePlace(item, activeTemplateInfoCardSelectWebhook){
            this.$refs.editig.addWebHookSinglePlace(item, activeTemplateInfoCardSelectWebhook);
        }
    },
    mounted() {
        let unitStr = JSON.stringify(this.unit).replace(/ /g, "");
        let preStr = JSON.stringify(
            answerInit("answer_intellect_guide", true, [], "",this.isBotLink)
        ).replace(/ /g, "");
        if (unitStr.length !== preStr.length) {
            this.showExmaple = false;
        } else {
            this.showExmaple = true;
        }
         
        if (this.unit.content.options[0].types.length === 0) {
            this.activeOtherObj = this.unit.content.options[0];
        } else {
            this.activeSecondTypeIndex = 0;
            this.activeOtherObj = this.unit.content.options[0].types[0];
        }
    },
};
</script>
<style lang="less" scoped>
@import "./../../../../assets/less/channel/new_ui.less";
#intellect-guide {
    .ig-typesetting {
        height: 52px;
        margin-top: 16px;
        padding: 0 100px 0 25px;
        background: #fbfcfd;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        label {
            flex: 1;
            text-align: left;
        }
    }
    .ig-edit-ig {
        padding: 16px;
    }
    .ig-view {
        background: #fbfcfd;
        border-radius: 5px;
        position: relative;
        .ig-view-example {
            position: absolute;
            right: -5px;
            top: -5px;
            img {
                height: 50px;
            }
        }
        .ig-types-des {
            padding: 16px 16px 8px;
            text-align: left;
        }
        .ig-types-f {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 8px;
            .ig-types-f-cell {
                height: 30px;
                line-height: 30px;
                padding: 0 14px;
                margin-left: 8px;
                margin-top: 6px;
                background: #ffffff;
                border: 1px solid #e0e6f7;
                border-radius: 17px;
                color: #a3adc6;
                cursor: pointer;
                font-weight: 600;
                &:hover {
                    border: 1px solid #366aff;
                    color: #366aff;
                }
                &-active {
                    background-color: #366aff;
                    border: 1px solid #366aff;
                    color: white;
                }
            }
            .ig-types-f-cell-active {
                &:hover {
                    color: white;
                }
            }
        }
        .ig-types-s {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 8px 16px 0;
            .ig-types-s-cell {
                height: 34px;
                line-height: 34px;
                margin-right: 18px;
                border: 2px solid rgba(0, 0, 0, 0);
                color: #a3adc6;
                cursor: pointer;
                font-weight: 600;
                margin-bottom: -2px;
                &:hover {
                    color: #366aff;
                }
                &-active {
                    border-bottom: 2px solid #366aff;
                    color: #366aff;
                }
            }
        }
        .ig-types-tags {
            border-top: solid 2px #e0e6f7;
            border-bottom: dashed 1px #e0e6f7;
            margin: 0 16px;
            padding: 20px 0;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .ig-types-tags-cell {
                height: 28px;
                line-height: 28px;
                padding: 0 8px;
                margin-right: 6px;
                margin-bottom: 6px;
                color: #366aff;
                font-weight: 500;
                background: #ffffff;
                border: 1px solid #a1b9ff;
                border-radius: 5px;
                cursor: pointer;
                max-width: 550px;
                // flex: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .ig-high-frequency-empty {
            margin: 6px 15px;
            height: 40px;
            line-height: 40px;
            background: #fff8f4;
            border-radius: 5px;
            font-size: 12px;
            color: #ff9555;
            padding: 0 12px;
            text-align: left;
        }
        .ig-types-list {
            border-top: solid 2px #e0e6f7;
            margin: 0 16px;
            padding: 0 0 10px 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .ig-types-list-cell {
                border-bottom: dashed 1px #e0e6f7;
                height: 40px;
                line-height: 40px;
                display: flex;
                justify-content: space-between;
                color: #366aff;
                cursor: pointer;
                width: 100%;
                .ig-types-list-cell-name {
                    width: 550px;
                    // flex: auto;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        .ig-change-list {
            padding: 16px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
</style>