<template>
    <div id="ChatFlow" v-loading="loading">

      <!-- 组件选列表 -->
      <div class="grid-content components">
        <ComponentsList
            :nodeId="nodeId"
            :botVersion="botVersion"
            ref="componentsListRfe"
            @addComponents="addComponents"
        ></ComponentsList>
      </div>
      <!-- 流程配置 -->
      <div class="grid-content interaction-config">
        <div class="interaction-config-header">
          <div v-if="actionList.length > 0" class="interaction-content" ref="grContent">
            <div
                class="response-text"
                v-for="(unit, index) in actionList"
                :key="'ask_' + index"
            >
              <ComponentsHeader
                  :isBotLink="isBotLink"
                  :ref="'con-header' + index"
                  :unit="unit"
                  :actionList="actionList"
                  :index="index"
                  :aiLearningIndex="aiLearningIndex"
                  :isStartOrEnd="isStartOrEnd"
                  @conditionEcho="conditionEcho"
                  @deleteCondition="deleteCondition"
                  @addEffectiveTime="addEffectiveTime"
                  @handleUnit="handleUnit"
                  @changeAiLearningIndex="changeAiLearningIndex"
              ></ComponentsHeader>
              <div class="com-content">
                <div
                    class="rich-text-content"
                    v-if="unit.type === 'answer_rich_text'"
                >
                  <UEditorRichText
                      :unit="unit"
                      v-if="unit.content.version != 1"
                  ></UEditorRichText>
                  <AnswerRichtext
                      v-if="unit.content.version == 1"
                      :ref="'aCkE_' + index"
                      :unit="unit"
                      :index="index"
                      :unitIndex="index"
                      @templateConfigVisibleHandle="templateConfigVisibleHandle"
                      :aiLearningIndex="aiLearningIndex"
                      :intentId="intentId"
                      @resetAiLearningIndex="resetAiLearningIndex"
                  ></AnswerRichtext>
                  <cRecommend
                      :isBotLink="isBotLink"
                      :unit="unit"
                      :index="index"
                      :intentSAkillTypeOptionsNoChildren="
                                                intentSAkillTypeOptionsNoChildren
                                            "
                  />
                </div>
                <!------webhook答案-------->
                <ActionWebhook
                    v-if="unit.type === 'action_webhook'"
                    :unit="unit"
                    :isBotLink="isBotLink"
                    :unitIndex="index"
                    :webhookApiList="webhookApiList"
                    :expBaseCascList="expBaseCascList"
                    :intentOptions="intentOptions"
                    :intentTree4Radio="intentTree4Radio"
                    :entityOptionsBot="entityOptionsBot"
                    :entityOptions="entityOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :actionList="actionList"
                    :intentId="intentId"
                    :nodeId="nodeId"
                    :setAddNodeId="index"
                    :com_index="index"
                    @getNodeOptions="getNodeOptions"
                ></ActionWebhook>

                <!------发送邮件-------->
                <aMail
                    :ref="`action_mail${index}`"
                    :unit="unit"
                    :unitIndex="index"
                    :intentOptions="intentOptions"
                    :intentTree4Radio="intentTree4Radio"
                    :entityOptionsBot="entityOptionsBot"
                    :entityOptions="entityOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentId="intentId"
                    :nodeId="nodeId"
                    :com_index="index"
                    @getNodeOptions="getNodeOptions"
                    v-if="unit.type === 'action_mail'"
                ></aMail>
                <!------发送邮件-------->
                <!------弹窗答案-------->
                <aAlertIframe
                    :ref="`action_alert_iframe${index}`"
                    :unit="unit"
                    :unitIndex="index"
                    :intentOptions="intentOptions"
                    :entityOptionsBot="entityOptionsBot"
                    :entityOptions="entityOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    @templateConfigVisibleHandle="
                                            templateConfigVisibleHandle
                                        "
                    v-if="
                                            unit.type === 'action_alert_iframe'
                                        "
                ></aAlertIframe>
                <!------弹窗答案-------->
                <!------确认值答案-------->
                <aCconfirmvalue
                    :ref="`action_confirm_value${index}`"
                    :unit="unit"
                    :unitIndex="index"
                    :nodeId="nodeId"
                    :intentId="intentId"
                    :intentOptions="intentOptions"
                    :entityOptionsBot="entityOptionsBot"
                    :entityOptions="entityOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentTree4Radio="intentTree4Radio"
                    @getNodeOptions="getNodeOptions"
                    @templateConfigVisibleHandle="
                                            templateConfigVisibleHandle
                                        "
                    v-if="
                                            unit.type === 'action_confirm_value'
                                        "
                ></aCconfirmvalue>
                <!------确认值答案-------->
                <!------提交表单答案-------->
                <aFormsubmit
                    :ref="`form_submit${index}`"
                    :unit="unit"
                    :activeBot="activeBot"
                    :unitIndex="index"
                    :botVersion="botVersion"
                    :nodeId="nodeId"
                    :intentId="intentId"
                    :intentSAkillTypeOptionsNoChildren="
                                            intentSAkillTypeOptionsNoChildren
                                        "
                    :entityOptionsBot="entityOptionsBot"
                    :entityOptions="entityOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentTree4Radio="intentTree4Radio"
                    @updateFormUnit="updateFormUnit"
                    @getNodeOptions="getNodeOptions"
                    v-if="unit.type === 'action_form'"
                ></aFormsubmit>
                <!------提交表单答案-------->
                <!------卡片模版答案-------->
                <aRichtextpro
                    :ref="`arichtextpro${index}`"
                    :rich_text_pro_info="unit"
                    :webhookMultivaluedCascList_="
                                            webhookMultivaluedCascList
                                        "
                    :activeARichtextproIndex="index"
                    @rtpAddBottondiaVisible="
                                            rtpAddBottondiaVisible
                                        "
                    @templateConfigVisibleHandle="
                                            templateConfigVisibleHandle
                                        "
                    v-if="
                                            unit.type === 'answer_card_template'
                                        "
                ></aRichtextpro>
                <!------卡片模版答案-------->
                <!------快捷选择-------->
                <aFastselect
                    :isBotLink="isBotLink"
                    :ref="`aFastSelect${index}`"
                    :unit="unit"
                    :intentId="intentId"
                    :nodeId="nodeId"
                    :unitIndex="index"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentTree4Radio="intentTree4Radio"
                    @getNodeOptions="getNodeOptions"
                    v-if="
                                            unit.type === 'action_fast_select'
                                        "
                ></aFastselect>
                <!------快捷选择-------->
                <!------智能引导-------->
                <aIntellectGuide
                    :isBotLink="isBotLink"
                    :ref="`aIntellectGuide${index}`"
                    :unit="unit"
                    :compIndex="index"
                    :unitIndex="index"
                    @saveAigConfig="saveAigConfig"
                    @templateConfigVisibleHandle="
                                            templateConfigVisibleHandle
                                        "
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentTree4Radio="intentTree4Radio"
                    v-if="
                                            unit.type ===
                                            'answer_intellect_guide'
                                        "
                ></aIntellectGuide>
                <!------智能引导-------->
                <!------答案反馈-------->
                <aFeedback
                    :ref="`aFeedback${index}`"
                    :unit="unit"
                    :intentId="intentId"
                    :nodeId="nodeId"
                    :unitIndex="index"
                    :intentOptions="intentOptions"
                    :entityOptionsBot="entityOptionsBot"
                    :entityOptions="entityOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentTree4Radio="intentTree4Radio"
                    @getNodeOptions="getNodeOptions"
                    v-if="unit.type === 'action_feedback'"
                ></aFeedback>
                <!------答案反馈-------->
                <!------转企微-------->
                <aTransQw
                    :ref="`aTransQw${index}`"
                    :unit="unit"
                    :intentOptions="intentOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentTree4Radio="intentTree4Radio"
                    v-if="
                                            unit.type ===
                                            'action_transfer_2_ewechat'
                                        "
                ></aTransQw>
                <!------转企微-------->
                <!------强制跳转/随机跳转-------->
                <aEnforce
                    :isBotLink="isBotLink"
                    :unit="unit"
                    :intentOptions="intentOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentTree4Radio="intentTree4Radio"
                    :type="unit.type"
                    :intentId="intentId"
                    :nodeId="nodeId"
                    :setAddNodeId="index"
                    @getNodeOptions="getNodeOptions"
                    v-if="
                                            unit.type === 'action_enforce' ||
                                            unit.type === 'action_random_jump'
                                        "
                ></aEnforce>
                <!------强制跳转/随机跳转-------->
                <!------文本答案-------->
                <div
                    v-if="unit.type === 'answer_text'"
                    class="respose-text-content"
                >
                  <div class="rhetorical-question-input">
                    <answer-text
                        :unit="unit"
                        :unitIndex="index"
                        @templateConfigVisibleHandle="
                                                    templateConfigVisibleHandle
                                                "
                        :ref="`answer_focus_text_${index}`"
                    ></answer-text>
                    <cRecommend
                        :isBotLink="isBotLink"
                        :unit="unit"
                        :index="index"
                        :intentSAkillTypeOptionsNoChildren="
                                                    intentSAkillTypeOptionsNoChildren
                                                "
                    />
                  </div>
                </div>

                <!------欢迎语答案-------->
                <div
                    class="respose-text-content"
                    v-if="
                                            unit.type === 'answer_welcometext'
                                        "
                >
                  <div class="rhetorical-question-input">
                    <answer-text
                        :unit="unit"
                        :unitIndex="index"
                        @templateConfigVisibleHandle="
                                                    templateConfigVisibleHandle
                                                "
                        :ref="`answer_focus_text_${index}`"
                    ></answer-text>
                  </div>
                </div>
                <!------设置实体-------->
                <div
                    class="respose-text-content"
                    v-if="unit.type === 'action_set_entity'"
                >
                  <action-setentity
                      :unit="unit"
                      :unitIndex="index"
                      :isStartOrEnd="isStartOrEnd"
                      :entityOptions="entityOptions"
                      :entityOptionsBot="entityOptionsBot"
                      :skillId="skillId"
                      @getAllEntityByBot="getAllEntityByBot"
                      @getAllEntityBySkill="getAllEntityBySkill"
                  >
                  </action-setentity>
                </div>
                <!------跳转答案-------->
                <div
                    class="respose-text-content"
                    v-if="unit.type === 'action_jump'"
                >
                  <action-jump
                      :isBotLink="isBotLink"
                      :unit="unit"
                      :intentOptions="intentOptions"
                      :isStartOrEnd="isStartOrEnd"
                      :nodeOptions="nodeOptions"
                      :intentTree4Radio="intentTree4Radio"
                      :type="unit.type"
                      :intentId="intentId"
                      :nodeId="nodeId"
                      :com_index="index"
                      @getNodeOptions="getNodeOptions"
                  ></action-jump>
                </div>
                <!------反问答案-------->
                <aQuestion
                    :ref="`aQuestion${index}`"
                    :index="index"
                    :unit="unit"
                    :unitIndex="index"
                    @templateConfigVisibleHandle="
                                            templateConfigVisibleHandle
                                        "
                    :intentOptions="intentOptions"
                    :entityOptionsBot="entityOptionsBot"
                    :entityOptions="entityOptions"
                    :isStartOrEnd="isStartOrEnd"
                    :nodeOptions="nodeOptions"
                    :intentTree4Radio="intentTree4Radio"
                    :noGetParamQNumber="noGetParamQNumber"
                    :intentId="intentId"
                    :nodeId="nodeId"
                    @getNodeOptions="getNodeOptions"
                    v-if="unit.type === 'action_question'"
                ></aQuestion>

                <!------满意度组件-------->
                <div
                    class="respose-text-content"
                    v-if="
                                            unit.type === 'action_satisfaction'
                                        "
                >
                  <action-satisfaction
                      :unitIndex="index"
                      :unit="unit"
                      :custonerOptions="custonerOptions"
                  ></action-satisfaction>
                </div>
                <!------关闭对话组件-------->
                <div
                    class="respose-text-content"
                    v-if="false"
                >
                  <div class="simple-cell">
                                            <span
                                                class="
                                                    arsenal_icon
                                                    arsenalsms-solid
                                                "
                                            ></span>
                    <div class="simple-cell-content">
                                                <span
                                                >机器人终止会话,30分钟内，同一个用户再次发起咨询，不处理也不进行转发操作</span
                                                >
                    </div>
                  </div>
                </div>
                <!------转人工组件-------->
                <div
                    class="respose-text-content"
                    v-if="unit.type === 'action_transfer'"
                >
                  <action-transfer
                      :isBotLink="isBotLink"
                      :unit="unit"
                      :intentOptions="intentOptions"
                      :isStartOrEnd="isStartOrEnd"
                      :nodeOptions="nodeOptions"
                      :intentTree4Radio="intentTree4Radio"
                      :type="unit.type"
                      :intentId="intentId"
                      :nodeId="nodeId"
                      :com_index="index"
                      :custonerOptions="custonerOptions"
                      @getNodeOptions="getNodeOptions"
                  ></action-transfer>
                </div>
                <!------图片答案-------->
                <div
                    class="respose-text-content"
                    v-if="unit.type === 'answer_image'"
                >
                  <answer-image
                      :ref="`aImage${index}`"
                      :index="index"
                      :unit="unit"
                  ></answer-image>
                  <cRecommend
                      :isBotLink="isBotLink"
                      :unit="unit"
                      :index="index"
                      :intentSAkillTypeOptionsNoChildren="
                                                intentSAkillTypeOptionsNoChildren
                                            "
                  />
                </div>
                <!------视频答案-------->
                <div
                    class="respose-text-content"
                    v-if="unit.type === 'answer_video'"
                >
                  <answer-video
                      :ref="`aVideo${index}`"
                      :index="index"
                      :unit="unit"
                  ></answer-video>
                  <cRecommend
                      :isBotLink="isBotLink"
                      :unit="unit"
                      :index="index"
                      :intentSAkillTypeOptionsNoChildren="
                                                intentSAkillTypeOptionsNoChildren
                                            "
                  />
                </div>
                <!------音频答案-------->
                <div
                    class="respose-text-content"
                    v-if="unit.type === 'answer_voice'"
                >
                  <answer-audio
                      :index="index"
                      :unit="unit"
                      @stopOtherAudio="stopOtherAudio"
                      ref="audioTagCell"
                  ></answer-audio>
                  <!-- <cRecommend
                      :unit="unit"
                      :index="index"
                      :intentSAkillTypeOptionsNoChildren="
                          intentSAkillTypeOptionsNoChildren
                      "
                  /> -->
                </div>
                <!------点选答案-------->
                <div
                    class="respose-text-content tal"
                    v-if="unit.type === 'answer_radio'"
                >
                  <span class="cell-title margin-b-6">{{$t('flowComponent.answerRadio')}}</span>
                  <div class="cell-content">
                    <el-input
                        type="textarea"
                        autosize
                        :placeholder="$t('flowComponent.answerRadioPla')"
                        v-model="
                                                    unit.content.description
                                                "
                    ></el-input>
                  </div>
                  <span class="cell-title">{{$t('flowComponent.answerRadioBtn')}}</span>
                  <div
                      class="
                                                cell-content
                                                answer-radio-box
                                            "
                  >
                    <div
                        :id="
                                                    getId(
                                                        'custom-column-field',
                                                        index
                                                    )
                                                "
                        class="btn-list-box"
                    >
                      <div
                          :key="radioTextIndex"
                          v-for="(
                                                        tag, radioTextIndex
                                                    ) in unit.content.options"
                          class="btn-list-cell"
                      >
                        <div
                            class="
                                                            btn-list-cell-text btn-list-cell-text-radio one-column-ellipsis btn-list-cell-text-rich
                                                        "
                            v-html="tag.name" ></div
                        >
                        <span
                            class="
                                                            sortIcon
                                                            my-handle-btn
                                                            iconfont
                                                            guoran-a-16-10
                                                        "
                            @mouseenter="
                                                            sortClickBtn(
                                                                $event,
                                                                tag,
                                                                index
                                                            )
                                                        "
                        >
                                                    </span>
                        <span
                            @click="
                                                            deleteAnswerRadio(
                                                                index,
                                                                radioTextIndex
                                                            )
                                                        "
                            class="
                                                            my-handle-close
                                                            iconfont
                                                            guoran-a-16-09
                                                        "
                        >
                                                    </span>
                      </div>
                      <span @click="editRadioBtnList(index, 0)" class="cell-title-setting-inner"><i class="el-icon-setting"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CopyComponents @addCopyitem="addCopyitem" @addRichText="addRichText"></CopyComponents>
          </div>
          <div v-else class="interaction-content">
            <div class="empty-flow-box">
              <img class="empty-flow-image" src="./../../assets/images/empty-flow.png" alt="" srcset="">
              <span class="empty-flow-text">{{$t('flowComponent.emptyFlowText')}}</span>
            </div>
            <CopyComponents @addCopyitem="addCopyitem" @addRichText="addRichText"></CopyComponents>
          </div>
        </div>
      </div>

        <!-- 条件配置 -->
        <popup
            v-if="conditionConfigVisible"
            :popWidth="700"
            @closeEvent="conditionConfigVisible = false"
        >
            <div slot="popup-name" class="popup-name">
                {{ conditionConfigTitle }}
            </div>
            <div slot="popup-tip">{{$t('flowComponent.conditionConfigPopup.title')}}</div>
            <div slot="popup-con">
                <ConditionConfig
                    ref="conditionconfig"
                    :isStartOrEnd="isStartOrEnd"
                    @confirmSaveCondiConfig="confirmSaveCondiConfig"
                    :expBaseCascList="expBaseCascList"
                    :skillId="isStartOrEnd ? activeBot : skillId.slice(1)"
                    :isBotLink="isBotLink"
                />
            </div>
            <div slot="dialog-footer">
                <el-button
                    size="small"
                    plain
                    @click="conditionConfigVisible = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="confirmSaveCondiConfig_Chi"
                    >{{$t('common.save')}}</el-button
                >
            </div>
        </popup>

        <popup
            v-show="answerRadioConfigVisible"
            :popWidth="$i18n.locale === 'en' ? '950' : '850'"
            @closeEvent="answerRadioConfigVisible = false"
        >
            <div slot="popup-name">{{$t('flowComponent.webhook.radioBtnTitle')}}</div>
            <div slot="popup-tip">{{$t('flowComponent.webhook.radioBtnTip')}}</div>
            <div slot="popup-con">
                <radio-config
                    ref="answerRadioBtnConfig0"
                    @answerRadioValueChange="answerRadioValueChange"
                    @templateConfigVisibleHandle="templateConfigVisibleHandle "
                    @getNodeListByIntent="getNodeListByIntent"
                    @confirmAddNodeNew="confirmAddNodeNew"
                    @getNodeOptions="getNodeOptions"
                    @confirmSaveAnswerRadioBtn="confirmSaveAnswerRadioBtn"
                    :activeRadioConfigIndex="activeRadioConfigIndex"
                    :nodeId="nodeId"
                    :intentId="intentId"
                    :intentTree4Radio="intentTree4Radio"
                    :nodeOptions="JSON.parse(JSON.stringify(nodeOptions))"
                    :isStartOrEnd="isStartOrEnd"
                    :activeRadionConfigObj="activeRadionConfigObj"
                    :activeRadioBtnList="activeRadioBtnList"
                    :isBotLink="isBotLink"
                ></radio-config>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="cancelSaveAnswerRadioConfig"
                    plain
                    size="small"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    @click="confirmSaveAnswerRadioConfig"
                    size="small"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>
        <popup
            v-if="addBottondiaVisible"
            @closeEvent="addBottondiaVisible = false"
        >
            <div slot="popup-name">{{$t('flowComponent.cardTemplateTitle')}}</div>
            <div slot="popup-tip">{{$t('flowComponent.cardTemplateTip')}}</div>
            <div slot="popup-con">
                <div class="answer-radio-cell">
                    <div
                        style="padding: 6px 0;"
                        class="select-cell"
                    >
                        <span class="select-cell-title">{{$t('flowComponent.webhook.templateButtonName')}}</span>
                        <el-input
                            size="small"
                            v-model="infoCardActiveConfigObj.name"
                            maxlength="20"
                            :placeholder="$t('flowComponent.actionFastSelect.namePla')"
                        ></el-input>
                    </div>
                    <div style="padding: 6px 0;" class="select-cell">
                        <span class="select-cell-title">{{$t('flowComponent.webhook.radioClick')}}</span>
                        <el-select
                            v-if="addBottondiaVisibleType == 'simple'"
                            v-model="infoCardActiveConfigObj.scope"
                            @change="infoCardActiveConfigObjScopeChange"
                            size="small"
                            :placeholder="$t('common.selectPlaceholder')"
                        >
                            <el-option
                                v-for="item in jumpTypeOptions"
                                :key="item.value"
                                :label="$t('common.' + item.value)"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                        <el-select
                            v-if="addBottondiaVisibleType != 'simple'"
                            v-model="infoCardActiveConfigObj.scope"
                            @change="infoCardActiveConfigObjScopeChange"
                            size="small"
                            :placeholder="$t('common.selectPlaceholder')"
                        >
                            <el-option
                                v-for="item in jumpTypeAddSendOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div style="padding: 6px 0;" class="select-cell">
                        <span
                            class="select-cell-title"
                            v-show="infoCardActiveConfigObj.scope === 'NODE'"
                            >{{$t('flowComponent.NODE')}}</span
                        >
                        <span
                            class="select-cell-title"
                            v-show="infoCardActiveConfigObj.scope === 'INTENT'"
                            >{{$t('flowComponent.INTENT')}}</span
                        >
                        <span
                            class="select-cell-title"
                            v-show="infoCardActiveConfigObj.scope === 'LINK'"
                            >{{$t('flowComponent.LINK')}}</span
                        >
                        <span
                            class="select-cell-title"
                            v-show="
                                infoCardActiveConfigObj.scope ===
                                    'SEND_MSG_TO_BOT' ||
                                infoCardActiveConfigObj.scope === 'SEND_MSG_TO_USER'
                            "
                            >{{$t('flowComponent.SEND_MSG_TO_USER')}}</span
                        >
                        <div
                            class="template-config-link-cell"
                            v-if="
                                infoCardActiveConfigObj.scope ===
                                    'SEND_MSG_TO_BOT' ||
                                infoCardActiveConfigObj.scope ===
                                    'SEND_MSG_TO_USER' ||
                                infoCardActiveConfigObj.scope === 'LINK'
                            "
                        >
                            <ckeditor
                                :editor="editorInline"
                                v-model="infoCardActiveConfigObj.value"
                                @focus="onEditorFocus"
                            ></ckeditor>
                            <span
                                @click="showSelectPlaceholder"
                                class="insert-placeholder"
                                >{{$t('flowComponent.insertVariable')}}</span
                            >
                        </div>

                        <!-- <el-select
                            v-if="infoCardActiveConfigObj.scope === 'NODE'"
                            v-model="infoCardActiveConfigObj.value"
                            :popper-append-to-body="false"
                            filterable
                            size="small"
                            placeholder="请输入选择名称"
                        >
                            <el-option
                                v-for="item in nodeOptions"
                                :key="item.id"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select> -->
                        <SelectAddnode
                            v-if="infoCardActiveConfigObj.scope === 'NODE'"
                            :nodeOptions="nodeOptions"
                            :nodeId="infoCardActiveConfigObj.value"
                            :defaultParentNodeId="nodeId"
                            :nodeSelectARef="`cardSelectAddnode_pp_OO`"
                            :intentId="intentId"
                            @getNodeOptions="getNodeOptionsInfoCard"
                            @setAddNodeId="setAddNodeIdInfoCard"
                            index="1"
                        ></SelectAddnode>
                        <el-cascader
                            filterable
                            v-show="infoCardActiveConfigObj.scope === 'INTENT'"
                            v-model="infoCardActiveConfigObj.value"
                            size="small"
                            :options="intentTree4Radio"
                            :props="{
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </div>
                    <div style="padding: 6px 0;" class="com-select">
                        <div class="com-select-title">
                            <span class="title_left">{{$t('flowComponent.backValue')}}</span>
                            <span class="title_right">{{$t('flowComponent.assignValueTo')}}</span>
                            <span class="title_empty"></span>
                        </div>
                        <div
                            v-for="(
                                toEntityCell, toEntityCellIndex
                            ) in infoCardActiveConfigObj.assignments"
                            :key="toEntityCellIndex"
                            class="com-select-cell"
                        >
                            <el-cascader
                                size="small"
                                :key="`lsc_${toEntityCellIndex}_btn`"
                                v-model="toEntityCell.webhookId_key"
                                :options="webhookBaseCascList"
                                :props="{ label: 'name' }"
                            ></el-cascader>

                            <el-cascader
                                size="small"
                                :key="`rsc_${toEntityCellIndex}_btn`"
                                v-model="toEntityCell._full_entity_entity"
                                :options="entityOptions"
                                :props="{}"
                            ></el-cascader>

                            <div class="d-a-handle-box">
                                <!-- <span
                                    v-if="toEntityCellIndex == 0"
                                    class="el-icon-circle-plus handle-icon"
                                    @click="addToEntityCell(toEntityCellIndex)"
                                ></span>
                                <span
                                    v-if="toEntityCellIndex != 0"
                                    class="
                                        el-icon-circle-close
                                        handle-icon handle-icon-delete
                                    "
                                    @click="deleteToEntityCell(toEntityCellIndex)"
                                ></span> -->

                                <span
                                    v-if="toEntityCellIndex == 0"
                                    @click="addToEntityCell(toEntityCellIndex)"
                                    class="answer-text-cell-handle-icon-add"
                                >
                                    <i class="el-icon-plus"></i>
                                </span>
                                <span
                                    v-else
                                    @click="deleteToEntityCell(toEntityCellIndex)"
                                    class="answer-text-cell-handle-icon-delete"
                                >
                                    <i class="el-icon-close"></i
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="cancelSaveAddBottonConfig"
                    plain
                    size="small"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    @click="confirmSaveAddBottonConfig"
                    size="small"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </popup>


        <el-dialog
            title="选择数据列表"
            :visible.sync="infoCardSelectDatadiaVisible"
            :close-on-click-modal="false"
            width="750px"
        >
            <!-----消息卡片选择数据列表配置弹框 start------->
            <div class="answer-radio-cell select-data-box">
                <div
                    class="select-data-left"
                    v-if="infoCardSelectDataType == 'PLACEHOLDER-entity'"
                >
                    <div class="select-data-left-webhook">
                        <span class="select-data-left-webhook-cell">分类</span>
                        <span
                            @click="selectInfoCardEntity(wCell)"
                            v-for="(wCell, wCellIndex) in webhookEntityOptions"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectEntity === wCell.id,
                                },
                            ]"
                            >{{ wCell.label }}</span
                        >
                    </div>
                    <div class="select-data-left-response">
                        <span class="select-data-left-webhook-cell"
                            >实体列表</span
                        >
                        <span
                            @click="selectInfoCardEntityCell(wCell, wCellIndex)"
                            v-for="(
                                wCell, wCellIndex
                            ) in activeInfoCardSelectEntityOptions"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectEntityCell ===
                                        wCell.id,
                                },
                            ]"
                            >{{ wCell.label }}</span
                        >
                    </div>
                </div>
                <div
                    v-if="
                        infoCardSelectDataType == 'LOOP-NOT-ROOT' ||
                        infoCardSelectDataType == 'LOOP-IS-ROOT' ||
                        infoCardSelectDataType == 'PLACEHOLDER-webhook'
                    "
                    class="select-data-left"
                >
                    <div
                        class="select-data-left-webhook"
                        v-if="infoCardSelectDataType == 'LOOP-IS-ROOT'"
                    >
                        <span class="select-data-left-webhook-cell"
                            >WebHook</span
                        >
                        <span
                            @click="selectInfoCardWebhook(wCell)"
                            v-for="(wCell, wCellIndex) in webhookApiList"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectWebhook ===
                                        wCell.id,
                                },
                            ]"
                            >{{ wCell.label }}</span
                        >
                    </div>
                    <div
                        class="select-data-left-webhook"
                        v-if="infoCardSelectDataType == 'PLACEHOLDER-webhook'"
                    >
                        <span class="select-data-left-webhook-cell"
                            >WebHook</span
                        >
                        <span
                            @click="selectInfoCardWebhookPlaceH(wCell)"
                            v-for="(wCell, wCellIndex) in webhookApiList"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectWebhook ===
                                        wCell.id,
                                },
                            ]"
                            >{{ wCell.label }}</span
                        >
                    </div>
                    <div
                        v-if="
                            infoCardSelectDataType == 'LOOP-NOT-ROOT' ||
                            infoCardSelectDataType == 'LOOP-IS-ROOT'
                        "
                        class="select-data-left-response"
                    >
                        <span class="select-data-left-webhook-cell"
                            >数据列表</span
                        >
                        <span
                            @click="selectInfoCardWebhookResp(wCell)"
                            v-for="(
                                wCell, wCellIndex
                            ) in activeInfoCardSelectWebhookRespListArr"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectWebhookResp ===
                                        wCell.value,
                                },
                            ]"
                            >{{ wCell.name }}</span
                        >
                    </div>
                    <div
                        v-if="infoCardSelectDataType == 'PLACEHOLDER-webhook'"
                        class="select-data-left-response"
                    >
                        <span class="select-data-left-webhook-cell"
                            >数据列表</span
                        >
                        <span
                            @click="selectInfoCardWebhookResp(wCell)"
                            v-for="(
                                wCell, wCellIndex
                            ) in activeInfoCardSelectWebhookRespListArr"
                            :key="wCellIndex"
                            :class="[
                                'select-data-left-webhook-cell',
                                {
                                    'select-data-left-webhook-cell-active':
                                        activeInfoCardSelectWebhookResp ===
                                        wCell.value,
                                },
                            ]"
                            >{{ wCell.name }}</span
                        >
                    </div>
                </div>
                <div
                    v-if="
                        infoCardSelectDataType == 'LOOP-NOT-ROOT' ||
                        infoCardSelectDataType == 'LOOP-IS-ROOT'
                    "
                    class="select-data-right"
                >
                    <span class="select-data-left-webhook-cell">数据预览</span>
                    <el-tree
                        :data="selectInfoCardTreData"
                        :props="defaultProps"
                        default-expand-all
                        @node-click="selectInfoCardHandleNodeClick"
                    ></el-tree>
                </div>
            </div>
            <div class="answer-radio-cell">
                <div class="bott-handle">
                    <el-button
                        size="small"
                        @click="infoCardSelectDatadiaVisible = false"
                        plain
                        >{{$t('common.cancel')}}</el-button
                    >
                    <el-button
                        size="small"
                        @click="confirmSaveAddinfoCardSelectData"
                        type="primary"
                        >{{$t('common.confirm')}}</el-button
                    >
                </div>
            </div>
            <!-----消息卡片选择数据列表配置弹框 end------->
        </el-dialog>
        <popup
          v-if="templateConfigPlaceholderVisible"
          @closeEvent="templateConfigPlaceholderVisible = false"
          :popWidth="700"
          :popZindex="2600"
      >
        <!-----模版配置 start------->
        <div slot="popup-name" class="popup-name">选择变量</div>
        <div slot="popup-tip">变量配置</div>
        <div slot="popup-con">
          <div style="height: 400px" class="template-config-cell">
            <div style="height: 360px" class="select-cell select-data-box">
              <div class="select-data-p">
                <div
                    v-if="
                                    templateConfigParams.type ==
                                    'confirm-placeholder'
                                "
                    class="select-data-left-webhook"
                >
                                <span class="select-data-left-webhook-cell"
                                >变量类型</span
                                >
                  <span
                      @click="
                                        selectTemplatePlaceholderType(wCell)
                                    "
                      v-for="(wCell, wCellIndex) in [
                                        { label: '实体', id: 'entity' },
                                    ]"
                      :key="wCellIndex"
                      :class="[
                                        'select-data-left-webhook-cell',
                                        {
                                            'select-data-left-webhook-cell-active':
                                                templateActivePlaceholderType ===
                                                wCell.id,
                                        },
                                    ]"
                  >{{ wCell.label }}</span
                  >
                </div>
                <div
                    v-if="
                                    templateConfigParams.type !=
                                        'confirm-placeholder' ||
                                    templateConfigParams.type ==
                                        'alert-placeholder'
                                "
                    class="select-data-left-webhook"
                >
                                <span class="select-data-left-webhook-cell"
                                >变量类型</span
                                >
                  <span
                      @click="
                                        selectTemplatePlaceholderType(wCell)
                                    "
                      v-for="(wCell, wCellIndex) in [
                                        { label: '实体', id: 'entity' },
                                        {
                                            label: 'webhook返回值',
                                            id: 'response',
                                        },
                                    ]"
                      :key="wCellIndex"
                      :class="[
                                        'select-data-left-webhook-cell',
                                        {
                                            'select-data-left-webhook-cell-active':
                                                templateActivePlaceholderType ===
                                                wCell.id,
                                        },
                                    ]"
                  >{{ wCell.label }}</span
                  >
                </div>
              </div>
              <div
                  class="select-data-left template-select-data-left"
                  v-if="templateActivePlaceholderType == 'entity'"
              >
                <div class="select-data-left-webhook">
                                <span class="select-data-left-webhook-cell"
                                >分类</span
                                >
                  <div class="select-data-left-response-box">
                                    <span
                                        @click="templateSelectInfoCardEntity(wCell)"
                                        v-for="(wCell, wCellIndex) in entityOptions"
                                        :key="wCellIndex"
                                        :class="[
                                            'select-data-left-webhook-cell',
                                            {
                                                'select-data-left-webhook-cell-active':
                                                    activeTemplateInfoCardSelectEntity ===
                                                    wCell.value,
                                            },
                                        ]"
                                    >{{ wCell.label }}</span
                                    >
                  </div>
                </div>
                <div class="select-data-left-response">
                                <span class="select-data-left-webhook-cell"
                                >实体列表</span
                                >
                  <div class="select-data-left-response-box">
                                    <span
                                        @click="
                                            selectTemplateInfoCardEntityCell(
                                                wCell,
                                                wCellIndex
                                            )
                                        "
                                        v-for="(
                                            wCell, wCellIndex
                                        ) in activeTemplateInfoCardSelectEntityOptions"
                                        :key="wCellIndex"
                                        :class="[
                                            'select-data-left-webhook-cell',
                                            {
                                                'select-data-left-webhook-cell-active':
                                                    wCell.isSelected == true,
                                            },
                                        ]"
                                    >{{ wCell.label }}</span
                                    >
                  </div>
                </div>
              </div>
              <div
                  class="select-data-left template-select-data-left"
                  v-if="templateActivePlaceholderType == 'response'"
              >
                <div class="select-data-left-webhook">
                                <span class="select-data-left-webhook-cell"
                                >WebHook</span
                                >
                  <div class="select-data-left-response-box">
                                    <span
                                        @click="
                                            selectTemplateInfoCardWebhook(
                                                wCell,
                                                'click'
                                            )
                                        "
                                        v-for="(
                                            wCell, wCellIndex
                                        ) in webhookApiList"
                                        :key="wCellIndex"
                                        :class="[
                                            'select-data-left-webhook-cell',
                                            {
                                                'select-data-left-webhook-cell-active':
                                                    activeTemplateInfoCardSelectWebhook ===
                                                    wCell.id,
                                            },
                                        ]"
                                    >{{ wCell.label }}</span
                                    >
                  </div>
                </div>
                <div class="select-data-left-response">
                                <span class="select-data-left-webhook-cell"
                                >数据列表</span
                                >
                  <div class="select-data-left-response-box">
                                    <span
                                        @click="
                                            selectTemplateInfoCardWebhookResp(
                                                wCell,
                                                wCellIndex
                                            )
                                        "
                                        v-for="(
                                            wCell, wCellIndex
                                        ) in activeInfoCardSelectWebhookRespListArr"
                                        :key="wCellIndex"
                                        :class="[
                                            'select-data-left-webhook-cell',
                                            {
                                                'select-data-left-webhook-cell-active':
                                                    wCell.isSelected == true,
                                            },
                                        ]"
                                    >{{ wCell.name }}</span
                                    >
                  </div>
                </div>
              </div>
            </div>
            <div
                v-if="
                            templateConfigParams.type == 'confirm-placeholder'
                        "
                class="confirmPlaceholderEditabel"
            >
              <el-checkbox v-model="confirmPlaceholderEditabel"
              >用户是否能编辑</el-checkbox
              >
            </div>
          </div>
        </div>
        <div slot="dialog-footer">
          <el-button size="small" plain @click="templateConfigPlaceholderVisible = false"
          >{{$t('common.cancel')}}</el-button
          >
          <el-button
              type="primary"
              v-if="
                            templateConfigParams.type ==
                                'placeholder' ||
                            templateConfigParams.type ==
                                'placeholder-pre'
                        "
              size="small"
              @click="confirmSaveTemplateConfig"
          >{{$t('common.confirm')}}</el-button
          >
          <el-button
              type="primary"
              v-if="
                            templateConfigParams.type ==
                                'confirm-placeholder' ||
                            templateConfigParams.type ==
                                'alert-placeholder' ||
                            templateConfigParams.type ==
                                'usual-text-placeholder' ||
                            templateConfigParams.type ==
                                'usual-richtext-placeholder' ||
                            templateConfigParams.type ==
                                'usual-aq-placeholder' ||
                            templateConfigParams.type ==
                                'usual-text-deep-placeholder' ||
                            templateConfigParams.type ==
                                'usual-text-deep-radio-placeholder'
                        "
              size="small"
              @click="
                            confirmValueSaveTemplateConfig(
                                templateConfigParams.type
                            )
                        "

          >{{$t('common.confirm')}}</el-button
          >
          <el-button
              type="primary"
              v-if="
                            templateConfigParams.type == 'link' ||
                            templateConfigParams.type == 'url'
                        "
              size="small"
              @click="confirmSaveLinkPlacConfig"

          >{{$t('common.confirm')}}</el-button
          >
        </div>
        <!-----模版配置 end------->
      </popup>

        <popup
            v-if="templateConfigLiIcVisible"
            @closeEvent="templateConfigLiIcVisible = false"
        >
            <!-----模版配置 start------->
            <div slot="popup-name" class="popup-name">{{templateConfigParams.type == 'link' ||
                templateConfigParams.type == 'url'
                    ? '插入链接'
                    : '插入图标'}}</div>
            <div slot="popup-tip">卡片模板配置</div>
            <div slot="popup-con">
                <div style="height:100%" class="template-config-cell">
                    <div
                        class="select-cell select-data-box"
                        v-if="templateConfigParams.type == 'link'"
                    >
                        <div class="template-config-link">
                            <div class="template-config-link-cell">
                                <span class="template-config-link-title"
                                    >链接文本:</span
                                >
                                <el-input
                                    size="mini"
                                    v-model="templateConfigLinkName"
                                ></el-input>
                            </div>
                            <div class="template-config-link-cell">
                                <span class="template-config-link-title"
                                    >链接:</span
                                >
                                <ckeditor
                                    :editor="editorInline"
                                    v-model="templateConfigLink"
                                    @focus="onEditorFocus"
                                ></ckeditor>
                                <span
                                    @click="showSelectPlaceholder"
                                    class="insert-placeholder"
                                    >插入变量</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="select-cell select-data-box"
                        v-if="templateConfigParams.type == 'url'"
                    >
                        <div class="template-config-link">
                            <div class="template-config-link-cell">
                                <span class="template-config-link-title"
                                    >链接:</span
                                >
                                <ckeditor
                                    :editor="editorInline"
                                    v-model="templateConfigLink"
                                    @focus="onEditorFocus"
                                ></ckeditor>
                                <span
                                    @click="showSelectPlaceholder"
                                    class="insert-placeholder"
                                    >插入变量</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="select-cell select-data-box"
                        v-if="templateConfigParams.type == 'icon'"
                    >
                        <div class="template-config-link">
                            <div class="upload-icon-box">
                                <div class="img-box">
                                    <img
                                        v-if="templateConfigIconUrl != ''"
                                        height="50px"
                                        :src="templateConfigIconUrl"
                                        alt=""
                                        srcset=""
                                    />
                                </div>
                                <el-upload
                                    class="rich-text-image-upload"
                                    :show-file-list="showFileList"
                                    :action="importFileUrl"
                                    :before-upload="beforeImageMUpload"
                                    :on-success="
                                        (response, file, fileList) =>
                                            handleIconUploadSuccess(
                                                response,
                                                file,
                                                fileList
                                            )
                                    "
                                >
                                    <i class="el-icon-upload"></i>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="templateConfigLiIcVisible = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    @click="confirmSaveTemplateConfig"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
            <!-----模版配置 end------->
        </popup>
        <effectiveTime
            ref="effectiveTime"
            @addEffectiveTimeVisibleHandle="addEffectiveTimeVisibleHandle"
            :addEffectiveTimeVisible="addEffectiveTimeVisible"
            :effectiveTimeObjP="effectiveTimeObjP"
            :timeTimeRange="timeTimeRange"
            :timeRepeat="timeRepeat"
        ></effectiveTime>

        <!-- 版本功能不可用提示 -->
        <popup v-if="versionTipDialog" @closeEvent="versionTipDialog = false">
            <div slot="popup-name" class="popup-name">无法访问</div>
            <div slot="popup-tip">您当前的版本无法使用该功能</div>
            <div slot="popup-con">
                <VersionTip :tipIndex="tipIndex" />
            </div>
            <div slot="dialog-footer">
                <el-button size="small" plain @click="versionTipDialog = false"
                    >我知道了</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    v-clipboard:copy="'4001-789-800'"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    >拨打 4001-789-800 升级</el-button
                >
            </div>
        </popup>

        <DeletenodeDia
            ref="deleteNodeDia"
            @closeIntentNodeList="closeIntentNodeList"
            :nodeTree="nodeTree"
            :deleteNodeName="deleteNodeName"
            :intentId="deleteNodeIntent"
            :nodeId="deleteNodeId"
        ></DeletenodeDia>
    </div>
</template>
<script>
function holder(data) {
    return "${" + data + "}";
}
import { lastEditor, currentEditor, changeEditor } from "./ChartFlowContext";
// import {lastEditor} from "./ChartFlowContext";

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import FlowHeader from "./component/cell/FlowHeader.vue";

import Popup from "@/components/popupNew";
import VersionTip from "./version/VersionTip";

import Sortable from "sortablejs";
import recursive from "./chatFlowNodeComponent";
import intentComponent from "./IntentComponent.vue";
import intentNodeComponent from "./firstNodeComponent.vue";
import CopyComponents from './CopyComponents'
import Bus from "./bus.js";
import { apiKeys } from "../../const/SpecialApiKey.js";
import Driver from "driver.js"; // import driver.js
import "driver.js/dist/driver.min.css"; // import driver.js css
import { skillItem, intentItem } from "./tampData";


// answerInit
import { answerInit } from "./utils/answerInit";
// 消息卡片V2
import aRichtextpro from "./component/answer/Rich_text_pro_v2";

// 智能引导
import aIntellectGuide from "./component/answer/Answer_intellect_guide.vue";
// 反问3.0
import aQuestion from "./component/action/Action_question";
// 强制执行
import aEnforce from "./component/action/Action_enforce";
// 转企业微信
import aTransQw from "./component/action/Action_trans_qywxx";

// 生效时间设置
import effectiveTime from "./condition/EffectiveTime";

// 关联推荐意图
import cRecommend from "./component/cell/Recommend";

import CKEDITOR from "ckeditor";
import { filterSingleWithScope } from "./filter/WebHookResponseFilter";

// 删除节点弹窗
import DeletenodeDia from "./component/cell/DeleteNode.vue";
// 选择组件列表
import ComponentsList from "./component/cell/ComponentsList.vue";
// 组件配置头部
import ConfigHeader from "./component/cell/ConfigHeader.vue";
// 组件单位头部
import ComponentsHeader from "./component/cell/ComponentsHeader.vue";
// 条件设置
import ConditionConfig from "./component/cell/ConditionConfig.vue";
// 编辑点选按钮
import RadioConfig from "./component/cell/RadioConfig.vue";
// 图片组件
import AnswerImage from "./component/answer/AnswerImage.vue";
// 视频组件
import AnswerVideo from "./component/answer/AnswerVideo.vue";
// 视频组件
import AnswerAudio from "./component/answer/AnswerAudio.vue";
// 文本组件
// import AnswerText from "./component/answer/AnswerStext.vue";
import AnswerText from "./component/answer/AnswerUpdatetext.vue";
// 跳转组件
import ActionJump from "./component/action/Action_Jump.vue";
// ckeditor 富文本组件
import AnswerRichtext from "./component/answer/AnswerCkeditor.vue";
// 百度富文本组件
import UEditorRichText from "./component/answer/UEditorRichText";
// 发送邮件
import aMail from "./component/action/Action_mail";
// 提交表单组件
import aFormsubmit from "./component/action/Form_submit";
// 确认值
import aCconfirmvalue from "./component/action/Action_confirm_value";
// 答案反馈
import aFeedback from "./component/action/Action_feedback";
// 快捷选择
import aFastselect from "./component/action/Action_fast_select.vue";
// 转人工组件
import ActionTransfer from "./component/action/Action_transfer.vue";
// 设置实体组件
import ActionSetentity from "./component/action/Action_set_entity.vue";
// 满意度组件
import ActionSatisfaction from "./component/action/Action_satisfaction.vue";
// 弹窗答案
import aAlertIframe from "./component/action/Action_alert_iframe";
// webhook
import ActionWebhook from "./component/action/ActionWebhook.vue";
// 选择或新增节点
import SelectAddnode from "./component/cell/SelectAddnode.vue";


export default {
    props: ["isPart", "drawerSize", "intentIds",'isBotLink'],
    watch: {

    },
    data() {
        return {
            // 节点添加成功
            addnodeSucTipFlag: true,
            // 删除节点信息
            deleteNodeName: "",
            deleteNodeIntent: "",
            deleteNodeId: "",
            deleteNodeDia: false,

            rollToIntentId: "",

            activePageInfo: {
                // 当前页跳转信息
                name: "返回全部机器人",
                gotoPageName: "botIndex",
                botId: 1,
                headerName: "营销线索机器人",
            },
            versionTipDialog: false,
            tipIndex: 2,
            botVersion: "",

            skillItemTamp: skillItem,
            intentItemTamp: intentItem,
            parentIntentItemTamp: intentItem,
            isOtherSkill: false,
            entityOptionsBot: [],
            confirmPlaceholderEditabel: true,
            // 卡片模版
            templateConfigPlaceholderVisible: false,
            templateConfigLiIcVisible: false,
            templateConfigParams: {},
            templateActivePlaceholderType: "entity",
            activeTemplateInfoCardSelectEntity: "",
            activeTemplateInfoCardSelectEntityOptions: [],
            tampActTemplateInfoCardSelectEntityOptions: [],
            activeTemplateInfoCardSelectEntityCell: "",
            activeTemplateInfoCardSelectEntityObj: {},
            activeTemplateInfoCardSelectWebhook: "",
            activeTemplateInfoCardSelectWebhookObj: {},
            activeTemplateInfoCardSelectWebhookResp: "",
            activeTemplateInfoCardSelectWebhookRespObj: {},
            templateConfigLinkName: "",
            templateConfigLink: "",
            templateConfigIconUrl: "",
            // ckeditor
            currentEditor: null,
            editorInline: CKEDITOR.InlineEditor,
            ckeditor: {
                currentCallBack: () => {}, // 当前回调
                // editor: ClassicEditor,
                editor: CKEDITOR.ClassicEditor,
                editorConfig: {
                    toolbar: [
                        "undo",
                        "redo",
                        "|",
                        "insertNotifyButton",
                        "insertNotifyList",
                        "insertNotifyPlaceholder4Entity",
                        "insertNotifyPlaceholder4Webhook",
                    ],
                    askPluginListener: [
                        {
                            event: "BUTTON",
                            process: this.beforeButtonInsert,
                        },
                        {
                            event: "PLACEHOLDER",
                            process: this.beforePlaceholderInsert,
                        },
                        {
                            event: "LOOP",
                            process: this.beforeLoopBlockInsert,
                        },
                    ], // 注册监听
                },
            },

            // 消息卡片
            addBottondiaVisible: false,
            addBottondiaVisibleType: "simple",
            infoCardActiveConfigObj: {
                name: "",
                scope: "NODE",
                value: "",
                assignments: [{ webhookId_key: "", _full_entity_entity: "" }],
                currentWebHookSCope: null,
            },
            infoCardActiveConfigObjIndex: "",
            infoCardActiveConfigObjUniIndex: "",
            infoCardSelectDatadiaVisible: false,
            activeInfoCardSelectWebhook: "",
            activeInfoCardSelectWebhookObj: {},
            activeInfoCardSelectWebhookRespList: [],
            activeInfoCardSelectWebhookRespListArr: [],
            tampActiveInfoCardSelectWebhookRespListArr: [],
            activeInfoCardSelectWebhookResp: "",
            activeInfoCardSelectWebhookRespObj: {},
            selectInfoCardTreData: [],
            defaultProps: {
                children: "children",
                label: "label",
            },
            infoCardLoopIsRoot: true,
            infoCardSelectDataType: "BUTTON",
            activeInfoCardSelectEntity: "",
            activeInfoCardSelectEntityOptions: [],
            activeInfoCardSelectEntityCell: "",
            activeInfoCardSelectEntityObj: {},
            placeHolderActiveEvent: {},
            //
            loading: false,
            botList: [],
            activeBot: "",
            nodeNameValue: "",
            activeNodeName: "",
            loginSuccessTimes: "",
            guidTaskId: null,
            nodeTree: [],
            skillId: "",
            intentId: "",
            bid: "",
            id: "",
            name: "",
            bName: "",
            rollToId: "",
            nodeId: "start",
            activeIndexArr: [],
            showNodeName: "",
            //节点名称验证规则对象
            nodeName_rules: {
                //验证节点名称是否合法
                name: [
                    {
                        min: 5,
                        max: 20,
                        message: "节点民初长度在 3 到 20 个字符",
                        trigger: "blur",
                    },
                ],
            },
            // 取消保存确认
            cancelSaveVisible: false,
            cancelMoveNodeSaveVisible: false,

            // 意图情况
            intentStatus: [
                { name: "当前意图", id: "CURRENT" },
                { name: "上一个意图", id: "LAST" },
                { name: "是否识别过意图", id: "RECOGNITION" },
                { name: "意图是否已经结束", id: "CLOSED" },
            ],
            activeIntentStatus: "当前意图",
            intentValue: [],
            // 实体
            allEntityValue: [],
            activeEntityValue: "",
            // 初始化组件条件添加
            conditionConfigTitle: "组件操作",

            // 流程json
            actionList: [],
            // 条件配置

            conditionConfigVisible: false,

            entityValue: [],
            entityOptions: [],

            // 答案抽屉
            drawer: false,
            direction: "rtl",
            showTip: false,
            activeIndex: "1",
            activeIndex2: "1",
            options: [],
            textarea1: "",
            // 反问实体
            value: "",
            input: "",
            allEntitys: [],
            state1: "",
            state2: "",
            entityHandleOptions: [
                {
                    value: 0,
                    label: "不再反问",
                },
                {
                    value: 1,
                    label: "继续反问",
                },
            ],
            entityHandleValue: "QUIT_Q",
            // 跳转
            jumpTypeValue: "NODE",
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],

            jumpTypeAddSendOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "SEND_MSG_TO_BOT",
                    label: "向机器人发送内容",
                },
                {
                    value: "SEND_MSG_TO_USER",
                    label: "向用户发送内容",
                },
                {
                    value: "LINK",
                    label: "跳转链接",
                },
            ],
            // activeJumpValue: "",
            nodeOptions: [],

            intentOptions: [],
            intentSAkillTypeOptions: [],
            intentSAkillTypeOptionsNoChildren: [],
            // 点选配置
            answerRadioConfigVisible: false,
            activeRadioBtnList:[],
            activeRadionConfigObj: {
                name: "",
                scope: "NODE",
                value: "",
            },
            activeRadioConfigIndex: [],
            // 图片
            importFileUrl: location.origin + "/api/oss",
            dialogImageVisible: false,
            // 转人工
            custonerOptions: [],

            actionListCopy: [],
            isStartOrEnd: false, // 当前操作是否为开始或者结束节点
            isUnrecognized: false,
            // 满意度组件操作
            satiAnswerRadioVisible: false,
            activeSatiAnswerRadioObj: {
                name: "",
                answer: "",
            },
            activeSaIndex: 0, // 当前编辑满意度组件index
            activeSaCellIndex: 0, // 点选按钮位置
            // 视频组件
            activeVideoIndex: 0, // 当前操作视频组件index
            videoFlag: false,
            // webhook
            webhookEntityOptions: [],
            cascaderProps: {
                value: "label",
            },

            webhookDirList: [],
            // webhook点选配置

            webhookActiveRadioConfigIndex: [],
            webhookSendToRobotContent: "",
            webhookbaseList: [],
            webhookBaseCascList: [],
            webhookMultivaluedCascList: [],
            expBaseCascList: [],
            //是否显示进度条
            videoUploadPercent: "",
            //进度条的进度，
            isShowUploadVideo: false,
            //显示上传按钮
            videoForm: {
                showVideoPath: "",
            },
            // 意图树
            intentTree4Radio: [],

            isInitNode: false,
            msg: "<h2>Vue + UEditor + v-model双向绑定</h2>",
            // webhook组件
            webhookOptions: [
                // {name: 'webhook1', id: 1},{name: 'webhook2', id: 2}
            ],
            webhookApiList: [],
            // 生效时间
            timeTimeRange: [new Date(), new Date()],
            addEffectiveTimeVisible: false,
            effectiveTimeObjP: {
                range: "PERMANENT", // PERMANENT/TIME/DATETIME/EFFECTIVE_TIME
                startTime: "",
                endTime: "",
                repeat: ["1", "2", "3", "4", "5", "6", "7"], // 1 2 3 4 5 6 7
                timeRange: [new Date(), new Date()],
                filterHolidays: true,
                balanceHolidays: true,
            }, // 当前编辑生效时间信息
            effectiveTimeIndex: 0, // 当前编辑生效时间index
            customerServiceOfflineIntentId: "", // 没有客服在线默认跳转到意图
            maxLengthLimit:40,

            aiLearningIndex: -1, // 当前正在ai生成的组件index

            selectPlaceholderIsLimit: false, // 是否一次只能选择一个变量
        };
    },
    components: {
        FlowHeader,
        UEditorRichText,
        aMail,
        recursive,
        aRichtextpro,
        aFormsubmit,
        aCconfirmvalue,
        aAlertIframe,
        aFeedback,
        effectiveTime,
        aEnforce,
        cRecommend,
        aFastselect,
        aIntellectGuide,
        aTransQw,
        aQuestion,
        intentComponent,
        intentNodeComponent,
        VersionTip,
        Popup,
        DeletenodeDia,
        ComponentsList,
        ConfigHeader,
        ComponentsHeader,
        ConditionConfig,
        RadioConfig,
        AnswerImage,
        AnswerText,
        AnswerVideo,
        AnswerAudio,
        ActionJump,
        ActionTransfer,
        ActionSetentity,
        ActionSatisfaction,
        ActionWebhook,
        SelectAddnode,
        AnswerRichtext,
        CopyComponents,
    },
    filters: {},
    methods: {
        // // 获取自定义标签数据
        // getTagsOptions(){
        //     this.FetchGet(this.requestUrl.bindV2.getCustomizeFields).then((res) => {
        //         if (res.code == 0) {
        //             console.log(res,'res');
        //             if(res.data && res.data.length > 0){
        //                 res.data.forEach(dataItem => {
        //                     // 滔搏-标签
        //                     if(dataItem.enName === 'label'){
        //                         this.$refs.conditionconfig.userAttributeOptions.forEach(item => {
        //                             if(item.value === 'staff'){
        //                                 item.children.push({
        //                                     value:"tags",
        //                                     label:dataItem.name
        //                                 })
        //                             }
        //                         })
        //                     }
        //                 })
        //                 this.$refs.conditionconfig.userAttributeTypeNameObj['tags'] = '标签';
        //                 this.FetchGet(this.requestUrl.bindV2.getTagsOptions).then((res) => {
        //                     if (res.code == 0) {
        //                         this.$refs.conditionconfig.tagsOptions = res.data;
        //                     }
        //                 })
        //             }
        //         }
        //     })
        // },
        changeAiLearningIndex(index){
            console.log(2163, index);
            this.aiLearningIndex = index;
            this.$refs['aCkE_' + index][0].startEvtsourceFn();
        },
        resetAiLearningIndex(){
            this.aiLearningIndex = -1;
        },
        // 暂停当前播放外的其他音频组件
        stopOtherAudio(refId){
            console.log(refId,this.$refs.audioTagCell);
            this.$refs.audioTagCell.forEach(item => {
                item.stopAudioPlay(refId);
            })
        },
        // Copy成功
        onCopy(e) {
            this.$message.success("内容已复制到剪切板！");
        },
        // Copy失败
        onError(e) {
            this.$message.error("抱歉，复制失败！");
        },
        getNodeOptionsInfoCard(){
            this.getNodeOptions(true);
        },
        setAddNodeIdInfoCard(nodeId, index){
            this.$set(this.infoCardActiveConfigObj, "value", nodeId);
        },
        cancelSaveAnswerRadioConfig() {
            this.answerRadioConfigVisible = false;
        },
        changeActiveNodeName(val) {
            this.activeNodeName = val;
        },
        changeCancelSaveVisible() {
            this.cancelSaveVisible = false;
        },
        setBotList(list) {
            this.botList = [...list];
        },
        scrollIntentIntoView(intentId) {
            var rollToId = "intentSpan" + intentId;
            this.rollToIntentId = intentId;
            this.$nextTick(() => {
                setTimeout(() => {
                    document.querySelector("#" + rollToId).scrollIntoView({
                        behavior: "smooth", // 平滑过渡
                        block: "center", // 上边框与视窗顶部平齐。默认值
                    });
                    this.nodeTree.forEach((skillItem, skillIndex) => {
                        skillItem.children.forEach((classItem, classIndex) => {
                            classItem.children.forEach(
                                (intentItem, intentIndex) => {
                                    if (
                                        intentItem.id == intentId
                                    ) {
                                        this.closeIntentNodeList(
                                            skillIndex,
                                            classIndex,
                                            intentIndex,
                                            true
                                        );
                                    }
                                }
                            );
                        });
                    });
                this.nodeTree = [...this.nodeTree];
                }, 100);
            });
        },
        // 全部展开/收起技能
        closeSkillAllNodeList(val) {
            this.nodeTree.forEach((item) => {
                item.show = val;
            });
            this.nodeTree = [...this.nodeTree];
        },

        saveAigConfig(value, index) {
            this.$set(this.actionList, index, value);
            let actionListCopy = JSON.parse(JSON.stringify(this.actionList));
            this.actionList = [];
            this.$nextTick(() => {
                this.actionList = [...actionListCopy];
            });
        },
        closePartDrewer() {
            this.$refs.componentsListRfe.activeTab = 0;
            this.drawer = false;
            this.$nextTick(() => {
                this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                    item.stopAudioPlay("");
                })
            })
            if (this.isPart == 1) {
                document.getElementsByClassName("v-modal")[0].style.height =
                    "100%";
            }
        },
        getOldAndNewIntentFlow() {
            this.$http.get(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                intentId: this.intentIds[0],
            })
                .then((res) => {
                    if (res.data.code === "0") {
                        res.data.data.initNode.children =
                            res.data.data.initNode.childrenNode == null
                                ? []
                                : res.data.data.initNode.childrenNode;
                        let setName = (firstNodeArr) => {
                            //遍历树  获取id数组
                            firstNodeArr.forEach((item) => {
                                if (item.childrenNode === null) {
                                    item.children = [];
                                } else {
                                    item.children = item.childrenNode;
                                    if (item.children.length > 0) {
                                        setName(item.children);
                                    }
                                }
                            });
                        };
                        setName(res.data.data.initNode.children);
                        this.intentItemTamp = {
                            id: this.intentIds[0],
                            name: "",
                            isLeafNode: true,
                            hasProcess: true,
                            children: [res.data.data.initNode],
                            show: true,
                        };
                        this.intentItemTamp.children = [
                            ...this.intentItemTamp.children,
                        ];
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
            this.$http.get(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                intentId: this.intentIds[1],
            })
                .then((res) => {
                    if (res.data.code === "0") {
                        res.data.data.initNode.children =
                            res.data.data.initNode.childrenNode == null
                                ? []
                                : res.data.data.initNode.childrenNode;
                        let setName = (firstNodeArr) => {
                            //遍历树  获取id数组
                            firstNodeArr.forEach((item) => {
                                if (item.childrenNode === null) {
                                    item.children = [];
                                } else {
                                    item.children = item.childrenNode;
                                    if (item.children.length > 0) {
                                        setName(item.children);
                                    }
                                }
                            });
                        };
                        setName(res.data.data.initNode.children);
                        this.parentIntentItemTamp = {
                            id: this.intentIds[1],
                            name: "",
                            isLeafNode: true,
                            hasProcess: true,
                            children: [res.data.data.initNode],
                            show: true,
                        };
                        this.parentIntentItemTamp.children = [
                            ...this.parentIntentItemTamp.children,
                        ];
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },

        addEffectiveTimeVisibleHandle(data) {
            this.addEffectiveTimeVisible = false;
            if (data.type == "save") {
                this.actionList[this.effectiveTimeIndex].effectiveTime =
                    data.data;
            }
        },
        /**
         * 添加生效时间
         * @method addEffectiveTime
         * @param {Number} 组件index
         * @param {unit} 组件信息
         * @return {}
         */
        addEffectiveTime(index, unit) {
            this.effectiveTimeIndex = index;
            if ("effectiveTime" in unit) {
                this.effectiveTimeObjP = JSON.parse(
                    JSON.stringify(unit.effectiveTime)
                );
            } else {
                this.effectiveTimeObjP = {
                    range: "PERMANENT", // PERMANENT/TIME/DATETIME/EFFECTIVE_TIME
                    startTime: "",
                    endTime: "",
                    repeat: ["1", "2", "3", "4", "5", "6", "7"], // 1 2 3 4 5 6 7
                    timeRange: [
                        new Date(2016, 9, 10, 8, 40, 0),
                        new Date(2016, 9, 10, 8, 40, 59),
                    ],
                    filterHolidays: true,
                    balanceHolidays: true,
                };
            }
            this.addEffectiveTimeVisible = true;
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */

        rowDrop() {
            const box = document.querySelector(".interaction-content");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.actionList.splice(oldIndex, 1);
                    _this.actionList.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.actionList = _this.actionList;
        },

        getId(name, id) {
            return name + id;
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */

        sortClickBtn(e, items, index) {
            e.stopPropagation();
            let _this = this;
            let idname = "custom-column-field" + index;
            let box = document.getElementById(idname);
            var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".my-handle-btn",
                group: "shared",
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.actionList[index].content.options.splice(
                        oldIndex,
                        1
                    );
                    _this.actionList[index].content.options.splice(
                        newIndex,
                        0,
                        item[0]
                    );
                },
            });
            this.actionList = _this.actionList;
        },
        updateFormUnit(data) {
            this.actionList[data.unitIndex] = data.unit;
            this.actionList = [...this.actionList];
        },
        async getAllEntityByBot() {
            let response = {}
            await this.$http.get(this.requestUrl.entity.getAllEntityBySkill + '?startNode=true').then((res) => {response = res})
            console.log(response,'response');
            if (response.data.code === "0") {
                let tampArr = [];
                for (let key in response.data.data) {
                    tampArr.push({
                        label: key,
                        value: key,
                        children: response.data.data[key],
                    });
                }
                tampArr.forEach((item) => {
                    item.children.forEach((cell) => {
                        cell.label = cell.name;
                        cell.value = cell.name;
                    });
                });
                this.entityOptionsBot = [...tampArr];
                this.entityOptions = [...tampArr];
                console.log(this.entityOptionsBot,'实体');
            } else {
                this.$message.error(response.data.message);
            }

        },
        // 消息卡片V2
        onEditorFocus(zh, editor) {
            changeEditor(editor);
            // this.currentEditor = editor;
        },
        handleIconUploadSuccess(response, file, fileList) {
            if (response.code == 0) {
                this.templateConfigIconUrl = response.data;
            }
        },
        rtpAddBottondiaVisible(content) {
            this.addBottondiaVisible = true;
            this.addBottondiaVisibleType = "rtpBtnEdit";
            this.infoCardActiveConfigObj = content.info;
            this.infoCardActiveConfigObjUniIndex = content.uniIndex;
            this.infoCardActiveConfigObjIndex = content.index;
            this.infoCardActiveConfigObj.currentWebHookSCope =
                content.webHookSCope;
            this.webhookBaseCascList = filterSingleWithScope(
                this.webhookBaseCascList,
                content.webHookSCope
            );
        },
        showSelectPlaceholder() {
            this.templateConfigPlaceholderVisible = true;
        },
        templateConfigVisibleHandle(content) {
            this.selectPlaceholderIsLimit = content.isLimit;
            // 初始化
            this.templateActivePlaceholderType = "entity";
            this.activeTemplateInfoCardSelectEntity = "";
            this.activeTemplateInfoCardSelectEntityCell = "";
            this.activeTemplateInfoCardSelectWebhook = "";
            this.activeTemplateInfoCardSelectWebhookResp = "";
            this.activeInfoCardSelectWebhookRespListArr = [];
            this.tampActiveInfoCardSelectWebhookRespListArr = [];

            this.templateConfigLinkName = "";
            this.templateConfigLink = "";

            this.templateConfigIconUrl = "";

            this.templateConfigParams = content;
            if (
                this.templateConfigParams.type == "placeholder" &&
                this.templateConfigParams.multiple
            ) {
                this.selectTemplateInfoCardWebhook(
                    {
                        id: this.templateConfigParams.webhookId_key[0],
                        value: this.templateConfigParams.webhookId_key[1],
                    },
                    "scope"
                );
            }
            if (
                this.templateConfigParams.type == "placeholder" ||
                this.templateConfigParams.type == "confirm-placeholder" ||
                this.templateConfigParams.type == "alert-placeholder" ||
                this.templateConfigParams.type == "usual-text-placeholder" ||
                this.templateConfigParams.type == "usual-richtext-placeholder" ||
                this.templateConfigParams.type == "usual-aq-placeholder" ||
                this.templateConfigParams.type == "usual-text-deep-placeholder" ||
                this.templateConfigParams.type == "usual-text-deep-radio-placeholder"
            ) {
                this.templateConfigPlaceholderVisible = true;
            }
            if (
                this.templateConfigParams.type == "link" ||
                this.templateConfigParams.type == "icon" ||
                this.templateConfigParams.type == "url"
            ) {
                this.templateConfigLiIcVisible = true;
            }
        },
        confirmSaveLinkPlacConfig() {
            if (this.templateActivePlaceholderType == "entity") {
                this.tampActTemplateInfoCardSelectEntityOptions.forEach(
                    (item) => {
                        if (item.isSelected) {
                            this.addEntityPlace(item);
                        }
                    }
                );
            } else {
                this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                    (item) => {
                        this.addWebHookSinglePlace(
                            item,
                            this.activeTemplateInfoCardSelectWebhook
                        );
                    }
                );
            }
            this.activeTemplateInfoCardSelectEntityOptions.forEach((item) => {
                item.isSelected = false;
            });
            this.activeInfoCardSelectWebhookRespListArr.forEach((item) => {
                item.isSelected = false;
            });
            this.templateConfigPlaceholderVisible = false;
        },
        addEntityPlace(data) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.label,
                    data: entityDataHolder(data.value),
                },
            });
        },
        addWebHookSinglePlace(data, webhookId) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolder(webhookId, data.value),
                },
            });
        },
        addWebHookPlaceWithScope(data) {
            let command = currentEditor.commands.get("insertAskComponent");
            command.execute({
                tag: "span",
                options: {
                    name: data.name,
                    data: webHookDataHolderWithScope(data.value),
                },
            });
        },
        trLink(value) {
            let link = value;
            let htmlDivElement = document.createElement("div");
            htmlDivElement.innerHTML = link;
            let placeholders = htmlDivElement.getElementsByClassName(
                "ask-component-placeholder"
            );
            if (placeholders != null && placeholders.length > 0) {
                for (let i = 0, len = placeholders.length; i < len; i++) {
                    let attribute =
                        "'+" + placeholders[i].getAttribute("th:text") + "+'";
                    placeholders[i].innerText = attribute;
                }
            }
            link = "'" + htmlDivElement.innerText + "'";
            return link;
        },
        confirmValueSaveTemplateConfig(type) {
            let arichtextproName = "";
            if (type == "confirm-placeholder") {
                arichtextproName =
                    "action_confirm_value" +
                    this.templateConfigParams.unitIndex;
            }
            if (type == "alert-placeholder") {
                arichtextproName =
                    "action_alert_iframe" + this.templateConfigParams.unitIndex;
            }

            if (type == "usual-text-placeholder") {
                arichtextproName =
                    "answer_focus_text_" + this.templateConfigParams.unitIndex;
            }

            if (type == "usual-aq-placeholder") {
                arichtextproName =
                    "aQuestion" + this.templateConfigParams.unitIndex;
            }

            if (type == "usual-text-deep-placeholder") {
                arichtextproName =
                    "aIntellectGuide" + this.templateConfigParams.unitIndex;
            }

            if (type == "usual-text-deep-radio-placeholder") {
                arichtextproName =
                    "answerRadioBtnConfig" + this.templateConfigParams.unitIndex;
            }

            // answerRadioBtnConfig

            if (type == "usual-richtext-placeholder") {
                arichtextproName =
                    "aCkE_" + this.templateConfigParams.unitIndex;
            }

            if (this.templateActivePlaceholderType == "response") {
                if (type == "alert-placeholder" || type == "usual-text-placeholder" ||  type == "usual-richtext-placeholder" || type == "usual-aq-placeholder"  || type == "usual-text-deep-placeholder"  || type == "usual-text-deep-radio-placeholder" ) {
                    this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                        (item) => {
                            if (arichtextproName == 'answerRadioBtnConfig0'){
                                this.$refs[
                                    arichtextproName
                                ].addWebHookSinglePlace(
                                    item,
                                    this.activeTemplateInfoCardSelectWebhook,
                                    this.templateConfigParams.cellIndex
                                );
                            } else {
                                this.$refs[
                                    arichtextproName
                                ][0].addWebHookSinglePlace(
                                    item,
                                    this.activeTemplateInfoCardSelectWebhook,
                                    this.templateConfigParams.cellIndex
                                );
                            }
                        }
                    );
                } else {
                    this.$refs[arichtextproName][0].addConfirmDataResponse();
                }
            }
            console.log(2740, this.tampActTemplateInfoCardSelectEntityOptions);
            if (this.templateActivePlaceholderType == "entity") {
                this.tampActTemplateInfoCardSelectEntityOptions.forEach(
                    (item) => {
                        console.log(item.label)
                        if (item.isSelected) {
                            console.log(2806, this.$refs[
                                    arichtextproName
                                ])
                            if (arichtextproName == 'answerRadioBtnConfig0') {
                                this.$refs[
                                    arichtextproName
                                ].addConfirmDataEntity(
                                    item,
                                    this.confirmPlaceholderEditabel,
                                    this.templateConfigParams.cellIndex
                                );
                            } else {
                                this.$refs[
                                    arichtextproName
                                ][0].addConfirmDataEntity(
                                    item,
                                    this.confirmPlaceholderEditabel,
                                    this.templateConfigParams.cellIndex
                                );
                            }

                        }
                    }
                );
            }
            this.activeTemplateInfoCardSelectEntityOptions.forEach((item) => {
                item.isSelected = false;
            });
            this.tampActTemplateInfoCardSelectEntityOptions = [];
            this.templateConfigPlaceholderVisible = false;
        },
        confirmSaveTemplateConfig() {
            let arichtextproName =
                "arichtextpro" +
                this.templateConfigParams.activeARichtextproIndex;
            switch (this.templateConfigParams.type) {
                case "placeholder-pre":
                case "placeholder":
                    if (this.templateActivePlaceholderType == "response") {
                        if (
                            this.templateConfigParams.multiple &&
                            this.templateConfigParams.webhookId_key[1] ==
                                this.activeTemplateInfoCardSelectWebhookRespObj
                                    .parentValue
                        ) {
                            this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                                (item) => {
                                    this.$refs[
                                        arichtextproName
                                    ][0].addWebHookPlaceWithScope(item);
                                }
                            );
                        } else {
                            this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                                (item) => {
                                    this.$refs[
                                        arichtextproName
                                    ][0].addWebHookSinglePlace(
                                        item,
                                        this.activeTemplateInfoCardSelectWebhook
                                    );
                                }
                            );
                        }
                    }
                    if (this.templateActivePlaceholderType == "entity") {
                        this.tampActTemplateInfoCardSelectEntityOptions.forEach(
                            (item) => {
                                if (item.isSelected) {
                                    this.$refs[
                                        arichtextproName
                                    ][0].addEntityPlace(item);
                                }
                            }
                        );
                    }
                    this.activeTemplateInfoCardSelectEntityOptions.forEach(
                        (item) => {
                            item.isSelected = false;
                        }
                    );
                    this.activeInfoCardSelectWebhookRespListArr.forEach(
                        (item) => {
                            item.isSelected = false;
                        }
                    );
                    this.tampActTemplateInfoCardSelectEntityOptions = [];
                    this.tampActiveInfoCardSelectWebhookRespListArr = [];
                    this.templateConfigPlaceholderVisible = false;
                    break;
                case "url":
                    this.actionList[
                        this.templateConfigParams.activeARichtextproIndex
                    ].content.content.htmlList[
                        this.templateConfigParams.cellIndex
                    ].src = this.trLink(this.templateConfigLink);
                    this.actionList[
                        this.templateConfigParams.activeARichtextproIndex
                    ].content.content.htmlList[
                        this.templateConfigParams.cellIndex
                    ].dynamic = "true";
                    let actionListStr = JSON.stringify(this.actionList);
                    this.actionList = [...JSON.parse(actionListStr)];
                    this.templateConfigLiIcVisible = false;
                    break;
                case "link":
                    let command = lastEditor.commands.get("insertAskComponent");
                    command.execute({
                        tag: "a",
                        options: {
                            href: this.trLink(this.templateConfigLink),
                            name: this.templateConfigLinkName,
                        },
                    });
                    this.templateConfigLiIcVisible = false;
                    break;
                case "icon":
                    if (this.templateActivePlaceholderType == "entity") {
                        this.$refs[arichtextproName][0].addIcon(
                            this.templateConfigIconUrl
                        );
                    }
                    this.templateConfigLiIcVisible = false;
                    break;
                default:
                    break;
            }
        },
        selectTemplatePlaceholderType(info) {
            this.templateActivePlaceholderType = info.id;
        },
        templateSelectInfoCardEntity(item) {
            this.activeTemplateInfoCardSelectEntity = item.value;
            this.activeTemplateInfoCardSelectEntityOptions = item.children;
            this.activeTemplateInfoCardSelectEntityOptions.forEach((item) => {
                item.isSelected = false;
            });
        },
        selectTemplateInfoCardEntityCell(data, index) {
            this.activeTemplateInfoCardSelectEntityCell = data.id;
            this.activeTemplateInfoCardSelectEntityObj = data;
            if (this.selectPlaceholderIsLimit) {
                this.activeTemplateInfoCardSelectEntityOptions.forEach((v, index) => {
                    v.isSelected = false;
                })
                this.activeTemplateInfoCardSelectEntityOptions[index].isSelected = true;
            } else {
                this.activeTemplateInfoCardSelectEntityOptions[index].isSelected =
                !this.activeTemplateInfoCardSelectEntityOptions[index]
                    .isSelected;

            }
            this.activeTemplateInfoCardSelectEntityOptions = [
                ...this.activeTemplateInfoCardSelectEntityOptions,
            ];

            let isExit = false,
                exitIndex = -1;
            this.tampActTemplateInfoCardSelectEntityOptions.forEach(
                (item, itemIndex) => {
                    if (data.id == item.id) {
                        isExit = true;
                        exitIndex = itemIndex;
                    }
                }
            );
            if (isExit) {
                this.tampActTemplateInfoCardSelectEntityOptions.splice(
                    exitIndex,
                    1
                );
            } else {
                this.tampActTemplateInfoCardSelectEntityOptions.push(data);
            }
            if (this.selectPlaceholderIsLimit) {
                this.tampActTemplateInfoCardSelectEntityOptions = [data];
            } else {
                this.tampActTemplateInfoCardSelectEntityOptions = [
                    ...this.tampActTemplateInfoCardSelectEntityOptions,
                ];
            }

        },
        formateActiveInfoCardSelectWebhookRespListArr(list){
            let tampList = JSON.parse(JSON.stringify(list))
            let arr = [];
            tampList.forEach(item => {
                if (item.multivalued) {
                     tampList.forEach(cell => {
                        if (cell.value.split("[*].")[0] === item.value && !cell.multivalued) {
                            cell.name = item.name + " - " + cell.name;
                            cell.label = item.label + " - " + cell.label;
                            arr.push(cell);
                        }
                     })
                } else {
                    arr.push(item);
                }
            })
            return arr;
        },
        selectTemplateInfoCardWebhook(cell1, type) {
            let cell = {};
            if (type == "click") {
                cell = {
                    id: cell1.id,
                    value: this.templateConfigParams.webhookId_key[1],
                };
            } else {
                cell = {
                    id: this.templateConfigParams.webhookId_key[0],
                    value: this.templateConfigParams.webhookId_key[1],
                };
            }

            this.activeTemplateInfoCardSelectWebhook = cell.id;
            this.activeTemplateInfoCardSelectWebhookObj = cell;

            this.$http.get(this.requestUrl.webhook.webhookDetail + cell.id).then(
                (res) => {
                    if (res.data.code === "0") {
                      console.log('3038',res)
                        this.activeTemplateInfoCardSelectWebhookResp = "";
                        if (
                            res.data.setting &&
                            res.data.setting.response &&
                            res.data.setting.response.config
                        ) {
                            this.activeInfoCardSelectWebhookRespList =
                                res.data.setting.response.config;
                            let activeInfoCardSelectWebhookRespListArr = [];
                            if (type !== "scope" && type !== "click") {
                                this.activeInfoCardSelectWebhookRespList.forEach(
                                    (item, index) => {
                                        if (!item.multivalued) {
                                            if (
                                                item.value.indexOf("[*]") == -1
                                            ) {
                                                activeInfoCardSelectWebhookRespListArr.push(
                                                    item
                                                );
                                            }
                                        }
                                    }
                                );
                            } else {
                                if (this.templateConfigParams.type == 'usual-text-deep-radio-placeholder') {
                                    // 点选配置变量特殊处理 放开多选字段
                                    activeInfoCardSelectWebhookRespListArr = this.formateActiveInfoCardSelectWebhookRespListArr(this.activeInfoCardSelectWebhookRespList);
                                } else {
                                    this.activeInfoCardSelectWebhookRespList.forEach(
                                        (item, index) => {
                                            if (!item.multivalued) {
                                                if (
                                                    item.value.indexOf("[*]") == -1
                                                ) {
                                                    activeInfoCardSelectWebhookRespListArr.push(
                                                        item
                                                    );
                                                }
                                            }
                                            if (
                                                item.value.split("[*].")[0] ==
                                                    cell.value &&
                                                item.value.split("[*].").length == 2
                                            ) {
                                                activeInfoCardSelectWebhookRespListArr.push(
                                                    item
                                                );
                                            }
                                        }
                                    );
                                }

                            }
                            activeInfoCardSelectWebhookRespListArr.forEach(
                                (item) => (item.isSelected = false)
                            );
                            this.activeInfoCardSelectWebhookRespListArr =
                                activeInfoCardSelectWebhookRespListArr;
                            this.selectInfoCardTreData = [
                                ...this.transfResponseConfig(
                                    res.data.setting.response.config
                                ),
                            ];
                        } else {
                            this.selectInfoCardTreData = [...[]];
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        selectTemplateInfoCardWebhookResp(data, index) {
            this.activeTemplateInfoCardSelectWebhookResp = data.value;
            this.activeTemplateInfoCardSelectWebhookRespObj = data;
            if (this.selectPlaceholderIsLimit) {
                this.activeInfoCardSelectWebhookRespListArr.forEach((v, index) => {
                    v.isSelected = false;
                })
                this.activeInfoCardSelectWebhookRespListArr[index].isSelected = true;
            } else {
                this.activeInfoCardSelectWebhookRespListArr[index].isSelected =
                !this.activeInfoCardSelectWebhookRespListArr[index].isSelected;
            }

            this.activeInfoCardSelectWebhookRespListArr = [
                ...this.activeInfoCardSelectWebhookRespListArr,
            ];

            let isExit = false,
                exitIndex = -1;
            this.tampActiveInfoCardSelectWebhookRespListArr.forEach(
                (item, itemIndex) => {
                    if (data.value == item.value) {
                        isExit = true;
                        exitIndex = itemIndex;
                    }
                }
            );
            if (isExit) {
                this.tampActiveInfoCardSelectWebhookRespListArr.splice(
                    exitIndex,
                    1
                );
            } else {
                this.tampActiveInfoCardSelectWebhookRespListArr.push(data);
            }
            if (this.selectPlaceholderIsLimit){
                this.tampActiveInfoCardSelectWebhookRespListArr = [data];
            }
            this.tampActiveInfoCardSelectWebhookRespListArr = [
                ...this.tampActiveInfoCardSelectWebhookRespListArr,
            ];
        },
        // webhook 展示数据展开和收起
        showFailedActionCollapse(index) {
            this.actionList[index].content.failedActionCollapse =
                !this.actionList[index].content.failedActionCollapse;
            this.actionList = [...this.actionList];
        },
        showresponseCollapse(index) {
            this.actionList[index].content.responseCollapse =
                !this.actionList[index].content.responseCollapse;
            this.actionList = [...this.actionList];
        },
        addToEntityCell(index) {
            this.infoCardActiveConfigObj.assignments.push({
                webhookId_key: "",
                _full_entity_entity: "",
            });
        },
        deleteToEntityCell(index) {
            this.infoCardActiveConfigObj.assignments.splice(index, 1);
        },
        webhookApiListHandleChange(e) {
            console.log(e);
        },
        beforeButtonInsert(event, callback) {
            // let type = event.type; // 事件类型
            // let param = event.param; // 各个事件param类型有所不同,参考下方实现

            // 弹窗
            this.showAddBottondia(callback);
        },
        beforePlaceholderInsert(event, callback) {
            this.placeHolderActiveEvent = event;
            this.selectInfoCardData(event, callback);
        },

        beforeLoopBlockInsert(event, callback) {
            this.selectInfoCardData(event, callback);
        },

        // 消息卡片
        showAddBottondia(callback) {
            this.addBottondiaVisible = true;
            this.addBottondiaVisibleType = "simple";
            // 重置当前回调
            this.ckeditor.currentCallBack = callback;
        },
        // 取消保存添加按钮
        cancelSaveAddBottonConfig() {
            this.addBottondiaVisible = false;
            this.infoCardActiveConfigObj = {
                name: "点击编辑点选",
                scope: "NODE",
                value: "",
                assignments: [{ webhookId_key: "", _full_entity_entity: "" }],
            };
        },
        // 保存编辑添加按钮
        confirmSaveAddBottonConfig() {
            if (this.infoCardActiveConfigObj.name === "") {
                this.$message.error("按钮名称不能为空！");
            } else {
                if (this.infoCardActiveConfigObj.value === "") {
                    this.$message.error("请选择跳转到的意图或节点！");
                } else {
                    this.addBottondiaVisible = false;
                    let assignments = [];
                    this.infoCardActiveConfigObj.assignments.forEach((item) => {
                        assignments.push({
                            webhookId: item.webhookId_key[0],
                            key: item.webhookId_key[1],
                            entity: item._full_entity_entity[1],
                            _full_entity: item._full_entity_entity[0],
                        });
                    });
                    let sendToRT = {
                        name: this.infoCardActiveConfigObj.name,
                        event:
                            this.infoCardActiveConfigObj.scope == "INTENT"
                                ? "TO_INTENT"
                                : "TO_NODE",
                        value: this.infoCardActiveConfigObj.value,
                        assignments: assignments,
                    };

                    this.actionList[this.infoCardActiveConfigObjUniIndex].content.content.buttonList[this.infoCardActiveConfigObjIndex] = this.infoCardActiveConfigObj;
                    this.actionList[this.infoCardActiveConfigObjUniIndex].content.content.buttonList = [...this.actionList[this.infoCardActiveConfigObjUniIndex].content.content.buttonList];

                    this.infoCardActiveConfigObj = {
                        name: "",
                        scope: "NODE",
                        value: "",
                        assignments: [
                            { webhookId_key: "", _full_entity_entity: "" },
                        ],
                    };
                    if (this.addBottondiaVisibleType == "simple") {
                        this.ckeditor.currentCallBack(sendToRT); // 回调用户输入
                    } else {
                        // console.log(1000000);
                    }

                }
            }
        },
        // 消息卡片添加按钮切换点击跳转类型
        infoCardActiveConfigObjScopeChange(e) {
            this.infoCardActiveConfigObj.value = "";
        },
        selectInfoCardData(data, callback) {
            switch (data) {
                case "BUTTON":
                    data = {
                        type: "BUTTON",
                    };
                    break;
                case "PLACEHOLDER-entity":
                    data = {
                        type: "PLACEHOLDER",
                        param: {
                            source: "entity",
                            model: {
                                webhook_id: "1",
                                list_key: "location",
                            },
                        },
                    };
                    break;
                case "PLACEHOLDER-webhook":
                    data = {
                        type: "PLACEHOLDER",
                        param: {
                            source: "webhook",
                            model: {
                                webhook_id: "38",
                                list_key: "location",
                            },
                        },
                    };
                    break;
                case "LOOP-NOT-ROOT":
                    data = {
                        type: "LOOP",
                        param: {
                            model: {
                                webhook_id: 38,
                                list_key: "location",
                            },
                        },
                    };

                    break;
                case "LOOP-IS-ROOT":
                    data = {
                        type: "LOOP",
                        param: {
                            model: null,
                        },
                    };
                    break;

                default:
                    break;
            }

            if (data.type == "BUTTON") {
                this.infoCardSelectDataType = data.type;
                this.showAddBottondia();
            } else {
                if (
                    data.type == "PLACEHOLDER" &&
                    data.param.source == "entity"
                ) {
                    this.infoCardSelectDataType = "PLACEHOLDER-entity";
                }
                if (
                    data.type == "PLACEHOLDER" &&
                    data.param.source == "webhook"
                ) {
                    this.infoCardSelectDataType = "PLACEHOLDER-webhook";
                }
                if (data.type == "LOOP" && !JSON.stringify(data.param.model)) {
                    this.infoCardSelectDataType = "LOOP-NOT-ROOT";
                }
                if (
                    data.type == "LOOP" &&
                    JSON.stringify(data.param.model) == "{}"
                ) {
                    this.infoCardSelectDataType = "LOOP-IS-ROOT";
                }
                this.infoCardSelectDatadiaVisible = true;
                this.ckeditor.currentCallBack = callback;

                this.activeInfoCardSelectEntity = "";
                this.activeInfoCardSelectEntityCell = "";
                this.activeInfoCardSelectWebhook = "";
                this.activeInfoCardSelectWebhookResp = "";
                this.activeInfoCardSelectWebhookRespListArr = [];
                this.tampActiveInfoCardSelectWebhookRespListArr = [];
            }
        },
        selectInfoCardEntity(item) {
            this.activeInfoCardSelectEntity = item.id;
            this.activeInfoCardSelectEntityOptions = item.children;
            this.activeInfoCardSelectEntityOptions.forEach((item) => {
                item.isSelected = false;
            });
        },
        selectInfoCardEntityCell(data) {
            this.activeInfoCardSelectEntityCell = data.id;
            this.activeInfoCardSelectEntityObj = data;
        },
        resultsResponse(arr) {
            let tampArr = [];
            JSON.parse(arr).forEach((item) => {
                if (
                    !item.multivalued &&
                    item.value.indexOf(
                        this.placeHolderActiveEvent.param.model.list_key + "[*]"
                    ) != -1
                ) {
                    tampArr.push(item);
                }
            });
            return tampArr;
        },
        selectInfoCardWebhookPlaceH(cell) {
            this.activeInfoCardSelectWebhook = cell.id;
            this.activeInfoCardSelectWebhookObj = cell;
            this.$http.get(this.requestUrl.webhook.webhookDetail + cell.id).then(
                (res) => {
                    if (res.code === "0") {
                        if (
                            res.data.setting &&
                            res.data.setting.response &&
                            res.data.setting.response.config
                        ) {
                            this.activeInfoCardSelectWebhookRespList =
                                res.data.setting.response.config;

                            let activeInfoCardSelectWebhookRespListArr = [];
                            if (
                                JSON.stringify(
                                    this.placeHolderActiveEvent.param.model
                                ) == "{}"
                            ) {
                                this.activeInfoCardSelectWebhookRespList.forEach(
                                    (item, index) => {
                                        if (!item.multivalued) {
                                            if (
                                                item.value.indexOf("[*]") == -1
                                            ) {
                                                activeInfoCardSelectWebhookRespListArr.push(
                                                    item
                                                );
                                            }
                                        }
                                    }
                                );
                            } else {
                                activeInfoCardSelectWebhookRespListArr =
                                    this.resultsResponse(
                                        JSON.stringify(
                                            this
                                                .activeInfoCardSelectWebhookRespList
                                        )
                                    );
                            }
                            activeInfoCardSelectWebhookRespListArr.forEach(
                                (item) => {
                                    item.isSelected = false;
                                }
                            );
                            this.activeInfoCardSelectWebhookRespListArr =
                                activeInfoCardSelectWebhookRespListArr;
                            this.selectInfoCardTreData = [
                                ...this.transfResponseConfig(
                                    res.data.setting.response.config
                                ),
                            ];
                        } else {
                            this.selectInfoCardTreData = [...[]];
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        selectInfoCardWebhook(item) {
            this.activeInfoCardSelectWebhook = item.id;
            this.activeInfoCardSelectWebhookObj = item;
            this.$http.get(this.requestUrl.webhook.webhookDetail + item.id).then(
                (res) => {
                    if (res.code === "0") {
                        if (
                            res.data.setting &&
                            res.data.setting.response &&
                            res.data.setting.response.config
                        ) {
                            this.activeInfoCardSelectWebhookRespList =
                                res.data.setting.response.config;
                            let activeInfoCardSelectWebhookRespListArr = [];
                            this.activeInfoCardSelectWebhookRespList.forEach(
                                (item) => {
                                    if (item.multivalued) {
                                        activeInfoCardSelectWebhookRespListArr.push(
                                            item
                                        );
                                    }
                                }
                            );
                            activeInfoCardSelectWebhookRespListArr.forEach(
                                (item) => {
                                    item.isSelected = false;
                                }
                            );
                            this.activeInfoCardSelectWebhookRespListArr =
                                activeInfoCardSelectWebhookRespListArr;
                            this.selectInfoCardTreData = [
                                ...this.transfResponseConfig(
                                    res.data.setting.response.config
                                ),
                            ];
                        } else {
                            this.selectInfoCardTreData = [...[]];
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        selectInfoCardWebhookResp(item) {
            this.activeInfoCardSelectWebhookResp = item.value;
            this.activeInfoCardSelectWebhookRespObj = item;
            let sendToRT = {
                type: "LOOP",
                param: {
                    model: {
                        webhook_id: this.activeInfoCardSelectWebhook,
                        list_key: item.value,
                    },
                },
            };
        },
        selectInfoCardHandleNodeClick(data) {
            console.log(data);
        },
        confirmSaveAddinfoCardSelectData() {
            this.infoCardSelectDatadiaVisible = false;
            let sendToRT = {};

            if (this.infoCardSelectDataType == "PLACEHOLDER-entity") {
                sendToRT = {
                    name: this.activeInfoCardSelectEntityObj.label,
                    key: this.activeInfoCardSelectEntityObj.value,
                };
            }
            if (this.infoCardSelectDataType == "PLACEHOLDER-webhook") {
                sendToRT = {
                    name: this.activeInfoCardSelectWebhookRespObj.name,
                    key:
                        this.activeInfoCardSelectWebhook +
                        "." +
                        this.activeInfoCardSelectWebhookRespObj.value,
                };
            }
            if (
                this.infoCardSelectDataType == "LOOP-NOT-ROOT" ||
                this.infoCardSelectDataType == "LOOP-IS-ROOT"
            ) {
                sendToRT = {
                    name: this.activeInfoCardSelectWebhookRespObj.name,
                    key:
                        this.activeInfoCardSelectWebhook +
                        "." +
                        this.activeInfoCardSelectWebhookRespObj.value,
                };
            }

            this.ckeditor.currentCallBack(sendToRT);
        },
        transfResponseConfig(list) {
            let tampArr = [],
                maxlength = 0;
            list.forEach((cell, index) => {
                cell.label = cell.name;
                if (cell.value != "") {
                    cell.children = [];
                    cell.valueToArr = cell.value.split("[*].");
                    if (cell.valueToArr.length == 2) {
                        cell.parentValue = cell.valueToArr[0];
                    } else if (cell.valueToArr.length == 1) {
                        cell.parentValue = 0;
                    } else if (cell.valueToArr.length > 2) {
                        let tampArr = cell.value.split("[*].");
                        tampArr.splice(tampArr.length - 1, 1);
                        cell.parentValue = tampArr.join("[*].");
                    }

                    if (cell.valueToArr.length > maxlength) {
                        maxlength = cell.valueToArr.length;
                    }
                }
            });

            for (let index = maxlength; index > 0; index--) {
                list.forEach((cell, cellIndex) => {
                    if (cell.valueToArr.length == index) {
                        list.forEach((innerCell) => {
                            if (innerCell.value == cell.parentValue) {
                                innerCell.children.push(cell);
                            }
                        });
                    }
                });
            }

            list.forEach((cell) => {
                if (cell.parentValue == 0) {
                    tampArr.push(cell);
                }
            });
            return tampArr;
        },
        getWebhookList() {
            this.$http.get(
                this.requestUrl.webhook.getWebhookDir,
                "keyword=''"
            ).then((res) => {
                if (res.data.code === "0") {
                    let tampArr = [];
                    this.webhookDirList = res.data.data;
                    this.loading = false;

                    let moToExtractNodeAsList = (target, item) => {
                        if (item.type === "api" && item.status != 0) {
                            tampArr.push(item);
                        } else {
                            item.children.forEach((cell) => {
                                moToExtractNodeAsList(tampArr, cell);
                            });
                        }
                    };

                    res.data.data.forEach((item) => {
                        moToExtractNodeAsList(tampArr, item);
                    });

                    this.webhookApiList = tampArr;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        getWebhookEntityTree() {
            this.$http.get(this.requestUrl.entity.getEntityTree).then((res) => {
                if (res.data.code === "0") {
                    this.webhookEntityOptions = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        // 获取webhookbaselist
        getWebhookBaseList() {
            this.$http.get(this.requestUrl.webhook.webhookbaseList, {
                status: 1,
            }).then((res) => {
                if (res.data.code === "0") {
                    this.webhookbaseList = JSON.parse(JSON.stringify(res.data.data));
                    let tampWebhookBaseCascList = JSON.parse(
                        JSON.stringify(res.data.data)
                    );
                    let tampExpBaseCascList = JSON.parse(
                        JSON.stringify(res.data.data)
                    );
                    let tampWebhookMultivaluedCascList = JSON.parse(
                        JSON.stringify(res.data.data)
                    );

                    tampWebhookBaseCascList.forEach((item) => {
                        item.children = [];
                        item.value = item.id;
                        item.configs.forEach((cell) => {
                            if (!cell.multivalued) {
                                item.children.push(cell);
                            }
                        });
                    });

                    tampExpBaseCascList.forEach((item) => {
                        item.children = [];
                        item.value = item.id;
                        item.configs.forEach((cell) => {
                            if (
                                cell.value.indexOf("*") == -1
                            ) {
                                item.children.push(cell);
                            }
                        });
                    });

                    tampWebhookMultivaluedCascList.forEach((item) => {
                        item.children = [];
                        item.value = item.id;
                        item.configs.forEach((cell) => {
                            if (cell.multivalued) {
                                item.children.push(cell);
                            }
                        });
                    });

                    this.webhookMultivaluedCascList = [
                        ...tampWebhookMultivaluedCascList,
                    ];
                    this.webhookBaseCascList = [...tampWebhookBaseCascList];
                    this.expBaseCascList = [...tampExpBaseCascList];
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },

        nodeSelectChange(e) {
            console.log(e);
        },


        // 取消保存
        cancelSaveConfirm() {
            this.$refs.componentsListRfe.activeTab = 0;
            this.cancelSaveVisible = false;
            this.drawer = false;
            this.$nextTick(() => {
                this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                    item.stopAudioPlay("");
                })
            })
        },

        checkFormatRich(text) {
            let flag = false;
            if (text.indexOf("onclick='previewImage(this)'") != -1) {
                flag = true;
            }
            if (
                text.indexOf(
                    '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_'
                ) != -1
            ) {
                flag = true;
            }
            if (text.indexOf("<li class='rich-text-li'") != -1) {
                flag = true;
            }
            // if (text.indexOf('<img width="100%"  src="https://static') != -1) {
            //     flag = true;
            // }
            if (
                text.indexOf(
                    "a style='color: -webkit-link;text-decoration: underline;' href="
                ) != -1
            ) {
                flag = true;
            }
            return flag;
        },
        formatRichTextNew(html) {
            let str = html;
            // let str = html.replace(
            //     /\<img src="https:\/\/static/g,
            //     '<img width="100%"  src="https://static'
            // );
            str = str.replace(
                /a href=/g,
                "a style='color: -webkit-link;text-decoration: underline;' href="
            );
            str = str.replace(/\<li/g, "<li class='rich-text-li'");
            str = str.replace(/\<img/g, "<img onclick='previewImage(this)' ");
            str = str.replace(/\<table/g, "<table class='rich-text-table' ");
            // 附件前标
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_txt.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_txt.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_jpg.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_jpg.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_rar.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_rar.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_zip.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_rar.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_pdf.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_pdf.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_doc.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_doc.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_ppt.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_ppt.gif'
            );
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_xls.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_xls.gif'
            );
            console.log("icon_exe.gif");
            str = str.replace(
                /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_exe.gif/g,
                '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_exe.gif'
            );
            return str;
        },
        checkActionListChanged() {
            let newVal = JSON.stringify(this.actionList);
            let oldVal = JSON.stringify(this.actionListCopy);
            newVal = this.checkFormatRich(newVal)
                ? newVal
                : this.formatRichTextNew(newVal);
            oldVal = this.checkFormatRich(oldVal)
                ? oldVal
                : this.formatRichTextNew(oldVal);
            if (
                newVal.split(" ").join("").length ==
                oldVal.split(" ").join("").length
            ) {
                this.cancelSaveConfirm();
            } else {
                if (
                    !(
                        (this.nodeId == "start" &&
                            !this.RP_Visible("OPEN_PROCESS_START")) ||
                        (this.nodeId == "end" &&
                            !this.RP_Visible("OPEN_PROCESS_END")) ||
                        (!this.isStartOrEnd &&
                            !this.isOtherSkill &&
                            !this.RP_Visible("OPEN_PROCESS_CUSTOMIZE")) ||
                        (this.isOtherSkill &&
                            !this.RP_Visible("OPEN_PROCESS_OTHER"))
                    )
                ) {
                    this.cancelMoveNodeSaveVisible = false;
                    this.cancelSaveVisible = true;
                    this.$refs.configheader.cancelSaveVisible = true;
                } else {
                    this.$refs.componentsListRfe.activeTab = 0;
                    this.drawer = false;
                    this.$nextTick(() => {
                        this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                            item.stopAudioPlay("");
                        })
                    })
                }
            }
        },
        // 点击节点配置流程
        configAction(intentId, nodeId, skillId, name, indexArr, isInitNode) {
            if (isInitNode === 0) {
                this.isInitNode = true;
            } else {
                this.isInitNode = false;
            }
            console.debug(intentId, nodeId, skillId, name, indexArr, isInitNode,'点击节点配置流程');
            // 判定当前是否为“其他”技能
            this.nodeTree.forEach((item) => {
                if (
                    item.id == skillId &&
                    item.name == "特殊规则" &&
                    !item.display
                ) {
                    this.isOtherSkill = true;
                } else {
                    this.isOtherSkill = false;
                }
            });
            this.nodeId = nodeId;
            this.intentId = intentId;
            this.activeNodeName = name;
            this.activeIndexArr = indexArr;
            this.skillId = skillId;
            this.drawer = true;
            this.$nextTick(() => {
                this.$refs.audioTagCell && this.$refs.audioTagCell.forEach(item => {
                    item.stopAudioPlay("");
                })
            })
            if (this.isPart == 1) {
                this.$nextTick(() => {
                    document.getElementsByClassName("v-modal")[0].style.height =
                        "0";
                });
            }

            if (nodeId !== "start" && nodeId !== "end") {
                this.isStartOrEnd = false;
                this.activeRadionConfigObj.scope = "INTENT";
                this.getIntentListBySkill();
                this.getAllEntityBySkill();
            } else {
                this.isStartOrEnd = true;
                this.getAllEntityByBot();
            }

            for (let i = 0, len = this.botList.length; i < len; i++) {
                let bot = this.botList[i];
                if (bot.id == this.activeBot) {
                    if (
                        bot.setting != null &&
                        bot.setting.unrecognizedIntentId != null &&
                        bot.setting.unrecognizedIntentId == intentId
                    ) {
                        this.isUnrecognized = true;
                        break;
                    }
                } else {
                    this.isUnrecognized = false;
                    continue;
                }
            }

            this.getNodeListByIntent(nodeId, false);

            this.$nextTick(() => {
                this.rowDrop();
            });
        },
        // 点击展示或者关闭技能节点
        closeSkillNodeList(skillIndex) {
            this.nodeTree[skillIndex].show = !this.nodeTree[skillIndex].show;
            this.nodeTree = [...this.nodeTree];
        },
        // 点击展示或者关闭分类节点展示
        closeClassNodeList(skillIndex, classIndex) {
            this.nodeTree[skillIndex].children[classIndex].show =
                !this.nodeTree[skillIndex].children[classIndex].show;
            this.nodeTree = [...this.nodeTree];
            this.getEleHeight();
        },
        // 点击展示或者关闭分类节点展示
        closeIntentNodeList(skillIndex, classIndex, intentIndex, value, type) {
            if (value) {
                this.nodeTree[skillIndex].children[classIndex].children[
                    intentIndex
                ].show = true;
            } else {
                this.nodeTree[skillIndex].children[classIndex].children[
                    intentIndex
                ].show =
                    !this.nodeTree[skillIndex].children[classIndex].children[
                        intentIndex
                    ].show;
            }

            let len =
                this.nodeTree[skillIndex].children[classIndex].children.length;
            if (len - 1 === intentIndex) {
                this.nodeTree[skillIndex].children[classIndex].isOpenLast =
                    this.nodeTree[skillIndex].children[classIndex].children[
                        intentIndex
                    ].show;
            }
            if (
                !this.nodeTree[skillIndex].children[classIndex].children[
                    len - 1
                ].show
            ) {
                this.nodeTree[skillIndex].children[
                    classIndex
                ].isOpenLast = false;
                this.nodeTree = [...this.nodeTree];
            } else {
                this.nodeTree = [...this.nodeTree];
                this.getEleHeight();
            }

            this.$http.get(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                intentId:
                    this.nodeTree[skillIndex].children[classIndex].children[
                        intentIndex
                    ].id,
            })
                .then((res) => {
                    if (res.code === "0") {
                        if (res.data.initNode == null && type === "delete") {
                            this.nodeTree[skillIndex].children[
                                classIndex
                            ].children[intentIndex].hasProcess = false;
                            this.nodeTree[skillIndex].children[
                                classIndex
                            ].children[intentIndex].children = [];
                        } else {
                            res.data.initNode.children =
                                res.data.initNode.childrenNode == null
                                    ? []
                                    : res.data.initNode.childrenNode;
                            let setName = (firstNodeArr) => {
                                //遍历树  获取id数组
                                firstNodeArr.forEach((item, index) => {
                                    // isLastAndHasChi 有兄弟节点且为兄弟节点中最后一个
                                    if (item.childrenNode === null) {
                                        item.children = [];
                                        item.isLastAndHasChi = false;
                                        this.nodeTree = [...this.nodeTree];
                                    } else {
                                        item.children = item.childrenNode;
                                        item.isLastAndHasChi =
                                            index === firstNodeArr.length - 1 &&
                                            firstNodeArr.length > 1;
                                        this.nodeTree = [...this.nodeTree];
                                        if (item.children.length > 0) {
                                            setName(item.children);
                                        }
                                    }
                                });
                            };
                            setName(res.data.initNode.children);

                            this.nodeTree[skillIndex].children[
                                classIndex
                            ].children[intentIndex].children[0] =
                                res.data.initNode;
                            this.nodeTree = [...this.nodeTree];
                        }

                        if (
                            this.nodeTree[skillIndex].children[classIndex]
                                .children.length -
                                1 ===
                            intentIndex
                        ) {
                            this.nodeTree[skillIndex].children[
                                classIndex
                            ].isOpenLast =
                                this.nodeTree[skillIndex].children[
                                    classIndex
                                ].children[intentIndex].show;
                        }

                        this.nodeTree = [...this.nodeTree];
                        this.getEleHeight();
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 删除条件
        deleteCondition(index, ind, miniuIndex) {
            this.actionList[index].conditionList[ind].splice(miniuIndex, 1);
            if (this.actionList[index].conditionList[ind].length === 0) {
                this.actionList[index].conditionList.splice(ind, 1);
            }
        },
        // 条件配置 - 取消条件设置
        cancelSaveCondiConfig(item) {
            this.conditionConfigVisible = false;
        },
        // 根据id返回意图分类名称
        getIntentNameById(idArr) {
            console.log(3832);
            let tamparr = [];
            this.intentSAkillTypeOptionsNoChildren.forEach((item) => {
                if (item.id === idArr[0]) {
                    item.children.forEach((cell) => {
                        if (cell.id === idArr[1]) {
                            tamparr.push(item.name);
                            tamparr.push(cell.name);
                        }
                    });
                }
            });
            return tamparr;
        },

        confirmSaveCondiConfig_Chi() {
            this.$refs.conditionconfig.confirmSaveCondiConfig_Chi();
        },

        // 条件配置 - 保存条件设置
        confirmSaveCondiConfig(param) {
            let { paramObj, conditionEditing } = param;
            console.log(paramObj, conditionEditing,'paramObj, conditionEditing');
            this.conditionConfigVisible = false;
            let indexArr = conditionEditing.index;
            switch (conditionEditing.type) {
                case "edit":
                    this.actionList[indexArr[0]].conditionList[indexArr[1]][
                        indexArr[2]
                    ] = paramObj;
                    break;
                case "addOr":
                    this.actionList[indexArr[0]].conditionList.push([paramObj]);
                    break;
                case "addAnd":
                    if (indexArr[1] === 0 && indexArr[2] === 0) {
                        this.actionList[indexArr[0]].conditionList[
                            indexArr[1]
                        ] = [];
                    }
                    this.actionList[indexArr[0]].conditionList[
                        indexArr[1]
                    ].push(paramObj);
                    break;
            }
            this.actionList = JSON.parse(JSON.stringify(this.actionList));
        },
        // 条件配置 - 回显和初始化
        conditionEcho(type, data) {
            this.conditionConfigVisible = true;
            // this.getTagsOptions();
            let tampObj = null;
            switch (type) {
                case "edit":
                    this.conditionConfigTitle =  this.$t('flowComponent.editCondition');
                    tampObj =
                        this.actionList[data[0]].conditionList[data[1]][
                            data[2]
                        ];

                    break;
                case "addOr":
                    this.conditionConfigTitle = this.$t('flowComponent.addOrCondition');

                    break;
                case "addAnd":
                    this.conditionConfigTitle = this.$t('flowComponent.addAndCondition');

                    break;
            }
            this.$nextTick(() => {
                this.$refs.conditionconfig.conditionEcho_chi(
                    type,
                    data,
                    tampObj
                );
            });
        },
        // 实体值选择
        querySearchEntityValue(queryString, cb) {
            if (this.allEntityValue.length == 0) {
                let tampArr = [],
                    e = this.entityValue;
                this.entityOptions.forEach((item) => {
                    if (item.value === e[0]) {
                        item.children.forEach((cell) => {
                            if (cell.value === e[1]) {
                                tampArr = cell.content;
                            }
                        });
                    }
                });
                tampArr.forEach((item) => {
                    item.value = item.standardWord;
                    item.label = item.standardWord;
                });
                this.allEntityValue = [...tampArr];
            }
            var allEntityValue = Array.from(this.allEntityValue);
            allEntityValue.unshift({ label: "空", value: "空" });
            var results = queryString
                ? allEntityValue.filter(
                      this.createFilterEntityValue(queryString)
                  )
                : allEntityValue;
            cb(results);
        },
        // 实体值筛选
        createFilterEntityValue(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        // 实体筛选
        querySearch(queryString, cb) {
            var allEntitys = this.allEntitys;
            var results = queryString
                ? allEntitys.filter(this.createFilter(queryString))
                : allEntitys;
            cb(results);
        },
        // 实体筛选
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        loadAllEntity() {
            return [];
        },
        handleSelectEntity(item) {
            console.log(item);
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {});
        },
        // 点击组件添加到右侧
        addComponents(type, callback) {
            console.log(type, this.isStartOrEnd,3981);
            if (
                type == "action_webhook" &&
                localStorage.getItem("_bot_version") == 2
            ) {
                this.tipIndex = 2;
                this.versionTipDialog = true;
                return false;
            }
            if (type == "action_feedback") {
                this.$http.get(
                    this.requestUrl.chatFlowComponent.getLastFeedback,
                    { botId: this.activeBot }
                )
                    .then((res) => {
                        let obj = null;
                        if (res.code === "0") {
                            if (res.data == null) {
                                obj = {
                                    // 答案反馈
                                    conditionList: [],
                                    content: {
                                        positiveFeedback: {
                                            scope: "NODE", // INTENT NODE CONTENT NONE
                                            value: "",
                                            text: "有用",
                                        },
                                        negativeFeedback: {
                                            scope: "NODE", // INTENT NODE CONTENT NONE 😄
                                            value: "",
                                            text: "无用",
                                        },
                                    },
                                    effectiveTime: {
                                        range: "PERMANENT",
                                        startTime: "",
                                        endTime: "",
                                        repeat: [
                                            "1",
                                            "2",
                                            "3",
                                            "4",
                                            "5",
                                            "6",
                                            "7",
                                        ],
                                        filterHolidays: true,
                                        balanceHolidays: true,
                                        timeRange: [
                                            new Date(
                                                "Thu Dec 15 2019 00:00:00 GMT+0800 (中国标准时间)"
                                            ),
                                            new Date(
                                                "Thu Dec 15 2100 23:59:59 GMT+0800 (中国标准时间)"
                                            ),
                                        ],
                                    },
                                    type: "action_feedback",
                                };
                                if (
                                    this.actionList == null ||
                                    this.actionList == undefined
                                ) {
                                    this.actionList = [obj];
                                } else {
                                    this.actionList.push(obj);
                                }
                            } else {
                                obj = {
                                    // 答案反馈
                                    conditionList: [],
                                    content: {
                                        positiveFeedback:
                                            res.data.positiveFeedback,
                                        negativeFeedback:
                                            res.data.negativeFeedback,
                                    },
                                    effectiveTime: {
                                        range: "PERMANENT",
                                        startTime: "",
                                        endTime: "",
                                        repeat: [
                                            "1",
                                            "2",
                                            "3",
                                            "4",
                                            "5",
                                            "6",
                                            "7",
                                        ],
                                        filterHolidays: true,
                                        balanceHolidays: true,
                                        timeRange: [
                                            new Date(
                                                "Thu Dec 15 2019 00:00:00 GMT+0800 (中国标准时间)"
                                            ),
                                            new Date(
                                                "Thu Dec 15 2100 23:59:59 GMT+0800 (中国标准时间)"
                                            ),
                                        ],
                                    },
                                    type: "action_feedback",
                                };
                                if (res.data.positiveFeedback.scope == "NODE") {
                                    obj.content.positiveFeedback.value = "";
                                }
                                if (res.data.negativeFeedback.scope == "NODE") {
                                    obj.content.negativeFeedback.value = "";
                                }
                                if (
                                    this.actionList == null ||
                                    this.actionList == undefined
                                ) {
                                    this.actionList = [obj];
                                } else {
                                    this.actionList.push(obj);
                                }
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            } else {
                if (this.actionList == null || this.actionList == undefined) {
                    this.actionList = [
                        answerInit(
                            type,
                            this.isStartOrEnd,
                            this.custonerOptions,
                            this.customerServiceOfflineIntentId,
                            this.isBotLink
                        ),
                    ];
                } else {
                    this.actionList.push(
                        answerInit(
                            type,
                            this.isStartOrEnd,
                            this.custonerOptions,
                            this.customerServiceOfflineIntentId,
                            this.isBotLink
                        )
                    );
                }
            }
            console.log(this.actionList,'this.actionList');

            // 滚动到底部
            this.$nextTick(() => {
                if(callback) {
                    // 延迟处理防止 回调执行时节点还未生成
                    setTimeout(() => {
                        callback()
                    }, 200);
                }
                if(type == "answer_text"){
                    let name = `answer_focus_text_${this.actionList.length - 1}`;
                    this.$refs[name][0].focusFirst();
                }
                setTimeout(() => {
                    this.$refs.grContent.scrollTo(0, 100000000);
                    console.log(4035);
                }, 200)
            });
        },
        // 获取节点下拉选树
        // needGet 是否需要获取数据 data 传入使用数据
        getNodeOptions(needGet, data) {
            let setOption = (nodeData) => {
                // nodeData res.data.initNode;
                let extractNodeAsList = (target, node, parentNum) => {
                    if (
                        node.childrenNode !== null &&
                        node.childrenNode.length > 0
                    ) {
                        parentNum++;
                        node.childrenNode.forEach((item) => {
                            if (
                                item.childrenNode !== null &&
                                item.childrenNode.length > 0
                            ) {
                                target.push({
                                    name: item.name,
                                    id: item.id,
                                    parentNum: parentNum,
                                    classPop: "__",
                                });
                            } else {
                                target.push({
                                    name: item.name,
                                    id: item.id,
                                    parentNum: parentNum,
                                    classPop: "oo",
                                });
                            }
                            extractNodeAsList(target, item, parentNum);
                        });
                    } else {
                        node.hasLeaf = false;
                    }
                    return target;
                };
                this.nodeOptions = extractNodeAsList([], nodeData, 1);
                this.nodeOptions.unshift({
                    name: nodeData.name,
                    id: nodeData.id,
                    parentNum: 1,
                    classPop: "__",
                });
            };
            if (needGet) {
                this.$http.get(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                    intentId: this.intentId,
                }).then((res) => {
                    if (res.code === "0") {
                        setOption(res.data.initNode);
                        this.scrollIntentIntoView(this.intentId);
                    }
                });
            } else {
                setOption(data);
            }
        },

        // 获取意图节点 nodeId 操作节点id isCreateNode 创建节点调用
        getNodeListByIntent(nodeId, isCreateNode) {
            console.log("+++++++++++++");
            if (isCreateNode) {
                nodeId = this.nodeId;
            }
            if (nodeId === "start" || nodeId === "end") {
                let url =
                    nodeId === "start"
                        ? this.requestUrl.chatFlowNode.getStertChatFlow
                        : this.requestUrl.chatFlowNode.getEndChatFlow;
                this.$http.get(url, {
                    botId: this.activeBot,
                })
                    .then((res) => {
                        if (res.code === "0") {
                            let tampActions = res.data == null?[]:res.data;
                            tampActions &&
                                tampActions.forEach((action, index) => {
                                    if (
                                        !("effectiveTime" in action) ||
                                        action.effectiveTime == null
                                    ) {
                                        action.effectiveTime = {
                                            range: "PERMANENT",
                                            startTime: "",
                                            endTime: "",
                                            repeat: [
                                                "1",
                                                "2",
                                                "3",
                                                "4",
                                                "5",
                                                "6",
                                                "7",
                                            ],
                                            filterHolidays: true,
                                            balanceHolidays: true,
                                            timeRange: [
                                                new Date(),
                                                new Date(
                                                    "Thu Dec 31 2100 23:59:59 GMT+0800 (中国标准时间)"
                                                ),
                                            ],
                                        };
                                    }
                                });

                            this.actionList = tampActions;
                            this.actionListCopy = JSON.parse(
                                JSON.stringify(this.actionList)
                            );
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        console.debug(err);
                        this.$message.error("获取意图节点失败！");
                    });
            } else {
                this.$http.get(this.requestUrl.chatFlowNode.getIntentChatFlow, {
                    intentId: this.intentId,
                })
                    .then((res) => {
                        if (res.code === "0") {
                            if (!isCreateNode) {
                                // 创建节点时不用重新赋值actionList
                                if (res.data.initNode.id === nodeId) {
                                    this.actionList =
                                        res.data.initNode.actionList == null
                                            ? []
                                            : res.data.initNode.actionList;
                                } else {
                                    let setName = (firstNodeArr) => {
                                        //遍历树
                                        firstNodeArr &&
                                            firstNodeArr.forEach((item) => {
                                                if (item.id === nodeId) {
                                                    if (
                                                        item.actionList == null
                                                    ) {
                                                        this.actionList = [];
                                                    } else {
                                                        this.actionList =
                                                            item.actionList;
                                                    }
                                                } else {
                                                    setName(item.childrenNode);
                                                }
                                            });
                                    };
                                    setName(res.data.initNode.childrenNode);
                                }
                            }

                            this.getNodeOptions(false, res.data.initNode);

                            if (!isCreateNode) {
                                // 创建节点时不用重新赋值actionList
                                let list = this.actionList;
                                let dataFixed = false;
                                if (list && list.length != 0) {
                                    for (
                                        let i = 0, len = list.length;
                                        i < len;
                                        i++
                                    ) {
                                        let action = list[i];
                                        if (
                                            !("effectiveTime" in action) ||
                                            action.effectiveTime == null
                                        ) {
                                            action.effectiveTime = {
                                                range: "PERMANENT",
                                                startTime: "",
                                                endTime: "",
                                                repeat: [
                                                    "1",
                                                    "2",
                                                    "3",
                                                    "4",
                                                    "5",
                                                    "6",
                                                    "7",
                                                ],
                                                filterHolidays: true,
                                                balanceHolidays: true,
                                                timeRange: [
                                                    new Date(),
                                                    new Date(
                                                        "Thu Dec 31 2100 23:59:59 GMT+0800 (中国标准时间)"
                                                    ),
                                                ],
                                            };
                                        }
                                        if (
                                            !("recommend" in action.content) ||
                                            action.content.recommend == null
                                        ) {
                                            this.$set(
                                                action.content,
                                                "recommend",
                                                {
                                                    list: [
                                                        {
                                                            id: "",
                                                            type: 0,
                                                            text: "", // type 0 自定义 1 跟随意图
                                                            ext: {
                                                                intentValue: [], // 用于回显 对后端没用
                                                            },
                                                        },
                                                    ],
                                                    status: false, // 推荐开关
                                                    description: "", // 推荐描述
                                                }
                                            );
                                        }
                                        if (action.type == "answer_text") {
                                            let textList = action.content.list;
                                            let extInfos =
                                                action.content.extInfos;
                                            if (
                                                extInfos == null ||
                                                textList.length !=
                                                    extInfos.length
                                            ) {
                                                dataFixed = true;
                                                extInfos = new Array();
                                                for (
                                                    let j = 0,
                                                        len2 = textList.length;
                                                    j < len2;
                                                    j++
                                                ) {
                                                    extInfos[j] = {
                                                        expectedIntention: "",
                                                        notAsExpectedReply: "",
                                                    };
                                                }
                                                action.content.extInfos =
                                                    extInfos;
                                            }
                                        }
                                        if (action.type === "action_question") {
                                            if (
                                                action.content.goToAction ==
                                                null
                                            ) {
                                                this.$set(
                                                    action.content,
                                                    "goToAction",
                                                    { scope: "NODE", value: "" }
                                                );
                                            }
                                            if (
                                                action.content
                                                    .entityNotFountAction ==
                                                null
                                            ) {
                                                this.$set(
                                                    action.content,
                                                    "entityNotFountAction",
                                                    {
                                                        type: "RECOGNIZE_INTENT",
                                                        recognizeIntent: {
                                                            roundToContinue: 3,
                                                        },
                                                        gotoNode: {
                                                            id: "",
                                                        },
                                                        gotoIntent: {
                                                            id: "",
                                                        },
                                                        sendMsgToUser: {
                                                            content: "",
                                                        },
                                                    }
                                                );
                                            }
                                        }
                                        if (action.type === "action_transfer") {
                                            if (
                                                action.content.ifOffline == null
                                            ) {
                                                this.$set(
                                                    action.content,
                                                    "ifOffline",
                                                    { scope: "NODE", value: "" }
                                                );
                                            }
                                        }
                                        if (action.type == "action_webhook") {
                                            if (
                                                action.content.failedAction
                                                    .scope == "INTENT"
                                            ) {
                                                // if (action.content.failedAction.value != "") {
                                                //   action.content.failedAction.value = Number(
                                                //     action.content.failedAction.value
                                                //   );
                                                // }
                                            }
                                            action.content.params.forEach(
                                                (paramsItem) => {
                                                    if (
                                                        paramsItem.type ==
                                                        "WEB_HOOK_RESPONSE"
                                                    ) {
                                                        paramsItem.webHookShowInfo[0] =
                                                            Number(
                                                                paramsItem
                                                                    .webHookShowInfo[0]
                                                            );
                                                    }
                                                    if (
                                                        paramsItem.actionNoParam
                                                            .gotoContent.type ==
                                                        "INTENT"
                                                    ) {
                                                        // if (paramsItem.actionNoParam.gotoContent.value != "") {
                                                        //   paramsItem.actionNoParam.gotoContent.value = Number(
                                                        //     paramsItem.actionNoParam.gotoContent.value
                                                        //   );
                                                        // }
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                                for (
                                    let i = 0, len = list.length;
                                    i < len;
                                    i++
                                ) {
                                    // this.actionList[i] = list[i];
                                    this.$set(this.actionList, i, list[i]);
                                }
                                if (dataFixed) {
                                    this.saveChatFlow(true);
                                }
                                this.actionListCopy = JSON.parse(
                                    JSON.stringify(this.actionList)
                                );
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error("获取意图节点失败！");
                    });
            }
            this.$nextTick(() => {
                this.$refs.grContent.scrollTo(0, 0);
            })
        },
        // 获取当前知识库下意图列表
        getIntentListBySkill() {
            this.$http.get(this.requestUrl.trainList.skillIntent, {
                skillId: this.skillId.slice(1),
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.intentOptions = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取当前机器人下知识库意图列表
        getIntentListByBotId(bId, paramType,botIdList) {
             console.log(4530);
                // 获取多个机器人以及多个机器人下的知识库,分类以及意图
                this.$http.post(this.requestUrl.bindV2.getBotIntentTree,botIdList).then((res) => {
                    if (res.data.code === "0") {
                        res.data.data.forEach(botItem => {
                            botItem.children && botItem.children.forEach(skillItem => {
                                skillItem.children && skillItem.children.forEach(typeItem => {
                                    typeItem.children && typeItem.children.forEach(intentItem => {
                                        intentItem.apiKey = botItem.apiKey;
                                    })
                                })
                            })
                        })
                        this.intentSAkillTypeOptionsNoChildren = res.data.data;
                        this.intentTree4Radio = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });


        },
        // 获取指定知识库下所有实体
        getAllEntityBySkill() {
            this.$http.get(this.requestUrl.entity.getAllEntityBySkill, {
                skillId: this.skillId.slice(1),
            })
                .then((res) => {
                    if (res.code === "0") {
                        let tampArr = [];
                        for (let key in res.data) {
                            tampArr.push({
                                label: key,
                                value: key,
                                children: res.data[key],
                            });
                        }
                        tampArr.forEach((item) => {
                            item.children.forEach((cell) => {
                                cell.label = cell.name;
                                cell.value = cell.name;
                            });
                        });
                        this.entityOptions = [...tampArr];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 改版反问跳转类型切换
        questionJumpTypeValueChange(index, data) {
            this.actionList[index].content.goToAction.value = "";
        },
        // 跳转类型切换
        answerRadioValueChange() {
            this.activeRadionConfigObj.value = "";
        },
        // 添加反问类型答案cell
        addActionQuestionCell(index) {
            this.actionList[index].content.list.push({
                questions: [""],
                entity: [],
                askIfExist: 0,
            });
        },
        // 删除反问类型答案cell
        deleteActionQuestionCell(index) {
            this.actionList[index].content.list.splice(q_index, 1);
        },
        // 操作反问类型答案cell中单条文案
        handleActionQuestionCellTextItem(type, index, q_item_text_index) {
            if (type === "delete") {
                this.actionList[index].content.questions.splice(
                    q_item_text_index,
                    1
                );
            }
            if (type === "add") {
                this.actionList[index].content.questions.push("");
            }
        },
        // 操作答案组件顺序或者删除
        handleUnit(type, index) {
            let tampArr = this.actionList;
            let tampObj = tampArr[index];
            switch (type) {
                case "bottom":
                    tampArr.splice(index, 1);
                    tampArr.push(tampObj);
                    break;
                case "top":
                    tampArr.splice(index, 1);
                    tampArr.unshift(tampObj);
                    break;
                case "down":
                    tampArr[index] = tampArr[index + 1];
                    tampArr[index + 1] = tampObj;
                    break;
                case "up":
                    tampArr[index] = tampArr[index - 1];
                    tampArr[index - 1] = tampObj;
                    break;
                case "delete":
                    tampArr.splice(index, 1);
                    break;
            }
            this.actionList = [...tampArr];
        },

        // 点选类型配置
        editRadioBtnList(index, radioTextIndex){
            let tampArr = this.actionList;
            this.activeRadioConfigIndex = [index, radioTextIndex];
            let list = JSON.parse(JSON.stringify(tampArr[index].content.options));
            list.forEach( editRadioItem => {
                editRadioItem.valueText = ''
            })
            this.activeRadioBtnList = JSON.parse(
                JSON.stringify(tampArr[index].content.options)
            );
            if (this.activeRadioBtnList.length === 0) {
                let obj = {
                    name: "",
                    scope: this.isStartOrEnd ? "INTENT" : "NODE",
                    value: "",
                    type: "FIXED"
                }
                if(this.isBotLink){
                    obj.valueText = ''; // 应用统一配置欢迎语字段
                }
                this.activeRadioBtnList = [obj];

            }
            this.$refs.answerRadioBtnConfig0.groupVisible = tampArr[index].content.groupVisible;
            this.$refs.answerRadioBtnConfig0.groupNumber = tampArr[index].content.groupNumber;

            this.answerRadioConfigVisible = true;
        },
        // 删除点选
        deleteAnswerRadio(index, radioTextIndex) {
            let tampArr = this.actionList;
            tampArr[index].content.options.splice(radioTextIndex, 1);
            this.actionList = [...tampArr];
        },

        // 保存编辑点选
        confirmSaveAnswerRadioConfig() {
            this.$refs.answerRadioBtnConfig0.saveAnswerRadioBtnConfig();
        },
        // 确认保存点选
        confirmSaveAnswerRadioBtn(list, index){
            this.actionList[index].content.options = list;
            this.actionList[index].content.groupVisible = this.$refs.answerRadioBtnConfig0.groupVisible;
            this.actionList[index].content.groupNumber = this.$refs.answerRadioBtnConfig0.groupNumber;
            this.actionList = [...this.actionList];
            this.answerRadioConfigVisible = false;
        },

        // 图片答案类型
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        beforeImageMUpload(file) {
            const isJPG =
                file.type === "image/JPG" ||
                file.type === "image/JPEG" ||
                file.type === "image/PNG" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/GIF" ||
                file.type === "image/gif";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error(
                    "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                );
            }
            if (!isLt2M) {
                this.$message.error(
                    "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
                );
            }
            return isJPG && isLt2M;
        },

        // 保存流程设置
        saveChatFlow(hiddenMsg) {
            let actionListTamp = JSON.parse(JSON.stringify(this.actionList));
            actionListTamp.forEach((item) => {
                if (item.type == "answer_rich_text") {
                    let str = item.content.html;
                    // let str = item.content.html.replace(
                    //     /\<img src="https:\/\/static/g,
                    //     '<img width="100%"  src="https://static'
                    // );
                    str = str.replace(
                        /a href=/g,
                        "a style='color: -webkit-link;text-decoration: underline;' href="
                    );
                    str = str.replace(/\<li/g, "<li class='rich-text-li'");
                    str = str.replace(
                        /\<img/g,
                        "<img onclick='previewImage(this)' referrerpolicy='no-referrer' "
                    );
                    console.log(4788, str);
                    str = str.replace(
                        /\<table/g,
                        "<table class='rich-text-table' "
                    );
                    // 附件前标
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_txt.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_txt.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_jpg.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_jpg.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_rar.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_rar.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_zip.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_rar.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_pdf.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_pdf.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_doc.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_doc.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_ppt.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_ppt.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_xls.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_xls.gif'
                    );
                    str = str.replace(
                        /="\/UEditor\/dialogs\/attachment\/fileTypeImages\/icon_exe.gif/g,
                        '="https://test.open.guoranbot.com/UEditor/dialogs/attachment/fileTypeImages/icon_exe.gif'
                    );
                    item.content.html = str;
                }
                console.log(4832, item.content.html);
                if (item.type == "action_webhook") {
                    item.content.params.forEach((paramCell, paramCellIndex) => {
                        if (paramCell.type == "WEB_HOOK_RESPONSE") {
                            if (paramCell.webHookShowInfo == undefined) {
                                paramCell.webHookShowInfo = ["", ""];
                            }
                            paramCell.webhookId =
                                paramCell.webHookShowInfo[0] || "";
                            paramCell.webHookResponseName =
                                paramCell.webHookShowInfo[1] || "";
                        }
                    });
                }
                if (item.type == "action_form") {
                    let tampArrFormSort = [];
                    if(item.content.relation) {
                        item.content.relation.forEach(itemInner => {
                            item.content.originForm.formFieldRelation.forEach(cell => {
                                if (itemInner.fieldId == cell.fieldId) {
                                    tampArrFormSort.push(cell);
                                }
                            })
                        })
                    }
                    this.$set(item.content.originForm, 'formFieldRelation', tampArrFormSort);
                }
            });
            let url = "";
            console.log(this.actionList);
            if (this.nodeId == "start" || this.nodeId == "end") {
                if(this.isBotLink){
                    this.$emit("saveWelcomeMessage",this.actionList);
                } else {
                    url =
                        this.nodeId == "start"
                            ? this.requestUrl.chatFlowNode.ipdateStartNodeNew
                            : this.requestUrl.chatFlowNode.ipdateEndNodeNew;
                            console.log(this.activeBot, actionListTamp,11111);
                    this.$http.put(url + '/' + this.activeBot, actionListTamp)
                        .then((res) => {
                            if (res.code === "0") {
                                this.$message.success("保存成功");
                            } else {
                                this.$message.error(res.message);
                            }
                            this.actionListCopy = JSON.parse(
                                JSON.stringify(this.actionList)
                            );
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            } else {
                url = this.requestUrl.chatFlowNode.updateNode;
                this.$http.put(url + '/' + this.nodeId, {
                    actionList: actionListTamp,
                    intentId: this.intentId,
                })
                    .then((res) => {
                        if (res.code === "0") {
                            if (hiddenMsg == true) {
                            } else {
                                this.$message.success("保存成功");
                                let isShowHeathly = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
                                this.$eventBus.$emit("bot-heathly-visible", {
                                    flag:isShowHeathly,
                                    type:"updateIntentDetail",
                                    intentId:this.$route.query.intentId,
                                    botId:this.$route.query.bid
                                });
                                if (this.isPreMoveNode) {
                                    this.showChangeNoderelation();
                                }
                            }
                            // 编辑节点名称
                            if (this.activeNodeName.trim().length === 0) {
                                this.$message.error("节点名称不能为空！");
                            } else {
                                console.log(4789)
                                this.$http.put(
                                    this.requestUrl.chatFlowNode.updateNodeName + '/' + this.nodeId,
                                    {
                                        intentId: this.intentId,
                                        name: this.activeNodeName.trim(),
                                    }
                                ).then((res) => {
                                    if (res.code === "0") {
                                        this.nodeTree.forEach(
                                            (skillItem, skillIndex) => {
                                                skillItem.children.forEach(
                                                    (classItem, classIndex) => {
                                                        classItem.children.forEach(
                                                            (
                                                                intentItem,
                                                                intentIndex
                                                            ) => {
                                                                if (
                                                                    intentItem.id ===
                                                                    this
                                                                        .intentId
                                                                ) {
                                                                    this.closeIntentNodeList(
                                                                        skillIndex,
                                                                        classIndex,
                                                                        intentIndex,
                                                                        true
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }
                                                );
                                            }
                                        );
                                    } else {
                                        this.$message.error(res.message);
                                    }
                                });
                                // 更新缺流程意图数量
                                this.$refs.flowHeader.getEmptyFlowList();
                            }
                            // this.closeIntentNodeList(
                            //     skillIndex,
                            //     classIndex,
                            //     intentIndex,
                            //     true
                            // );
                        } else {
                            this.$message.error(res.message);
                        }
                        this.actionListCopy = JSON.parse(
                            JSON.stringify(this.actionList)
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        // 点击添加节点
        addNodeHandleFirst(intentId, parentId, indexArr, type) {
            if (type === "first") {
                this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                    indexArr[2]
                ].children[0].isAddEditing = true;
                this.nodeTree = [...this.nodeTree];
            } else if (type === "pre") {
                this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                    indexArr[2]
                ].isAddEditing = true;
                this.nodeTree = [...this.nodeTree];
            } else {
                let firstNodeArr =
                    this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                        indexArr[2]
                    ].children[0].children;
                let setName = (firstNodeArr) => {
                    //遍历树  获取id数组
                    firstNodeArr.forEach((item) => {
                        if (item.id === parentId) {
                            item.isAddEditing = true;
                            item.name = item.name.trim() + " ";
                            let tampNodeTree = this.nodeTree;
                            this.nodeTree = [];
                            this.nodeTree = tampNodeTree;
                        } else {
                            item.isAddEditing = false;
                            item.name = item.name.trim() + " ";
                            this.nodeTree = [...this.nodeTree];
                            if (item.children.length > 0) {
                                setName(item.children);
                            }
                        }
                    });
                };
                setName(firstNodeArr);
            }
            this.nodeNameValue = "";
        },
        // 确认移动节点
        confirmAddNodeNew(indexArr) {
            let arr = indexArr;
            this.closeIntentNodeList(arr[0], arr[1], arr[2], true);
        },

        // 确认移动节点
        confirmMoveNodeNew(indexArr) {
            let arr = JSON.parse(indexArr);
            this.closeIntentNodeList(arr[0], arr[1], arr[2], true);
        },

        // 确认添加节点
        confirmAddNodeHandleFirst(intentId, parentId, indexArr, value, type) {
            if (value.length === 0) {
                console.log("AddNode++++++")
                this.$message.error("请输入子节点名称！");
            } else {
                if (type === "first") {
                    this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                        indexArr[2]
                    ].children[0].isAddEditing = false;
                    this.nodeTree = [...this.nodeTree];
                } else if (type === "pre") {
                    this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                        indexArr[2]
                    ].isAddEditing = false;
                    this.nodeTree = [...this.nodeTree];
                } else {
                    let firstNodeArr =
                        this.nodeTree[indexArr[0]].children[indexArr[1]]
                            .children[indexArr[2]].children[0].children;
                    let setName = (firstNodeArr) => {
                        //遍历树  获取id数组
                        firstNodeArr.forEach((item) => {
                            if (item.id === parentId) {
                                item.isAddEditing = false;
                                item.name = item.name + " ";
                                this.nodeTree = [...this.nodeTree];
                            } else {
                                if (item.children.length > 0) {
                                    setName(item.children);
                                }
                            }
                        });
                    };
                    setName(firstNodeArr);
                }
                this.$http.post(
                    this.requestUrl.chatFlowNode.addNode,
                    {
                        intentId: intentId,
                        name: value,
                        parentId: parentId,
                    },
                    { emulateJSON: true }
                ).then((res) => {
                    if (res.code === "0" || res.code === "OPMANYREQ") {
                        if (!this.addnodeSucTipFlag) {
                            this.$message.success("节点添加成功！");
                            this.addnodeSucTipFlag = true;
                            setTimeout(() => {
                                this.addnodeSucTipFlag = false;
                            }, 3000)
                        }

                        this.nodeTree[indexArr[0]].children[
                            indexArr[1]
                        ].children[indexArr[2]].hasProcess = true;
                        this.closeIntentNodeList(
                            indexArr[0],
                            indexArr[1],
                            indexArr[2],
                            true
                        );
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },

        // 放弃添加节点
        concelAddNodeHandleFirst(intentId, parentId, indexArr, type) {
            if (type === "first") {
                this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                    indexArr[2]
                ].children[0].isAddEditing = false;
                this.nodeTree = [...this.nodeTree];
            } else if (type === "pre") {
                this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                    indexArr[2]
                ].isAddEditing = false;
                this.nodeTree = [...this.nodeTree];
            } else {
                let firstNodeArr =
                    this.nodeTree[indexArr[0]].children[indexArr[1]].children[
                        indexArr[2]
                    ].children[0].children;
                let setName = (firstNodeArr) => {
                    //遍历树  获取id数组
                    firstNodeArr.forEach((item) => {
                        if (item.id === parentId) {
                            item.isAddEditing = false;
                            item.name = item.name + " ";
                            this.nodeTree = [...this.nodeTree];
                        } else {
                            setName(item.children);
                        }
                    });
                };
                setName(firstNodeArr);
            }
        },

        // 点击删除节点
        deleteNodeHandleFirst(intentId, id, name) {
            this.deleteNodeName = name;
            this.deleteNodeIntent = intentId;
            this.deleteNodeId = id;
            this.$refs.deleteNodeDia.deleteNodeDia = true;
        },

        //根据Url跳转至意图列表页面
        tobotIntent(botId, botName, intentId, intentName) {
            var intentId = this.$route.query.intentId;
            if (this.$route.query.isOtherSkill) {
                this.$router.push({
                    name: "botIntent",
                    query: {
                        id: this.$route.query.id,
                        name: this.$route.query.bName,
                        refApiKey: this.$router.currentRoute.query.refApiKey,
                        tabIndex: "skill",
                        isDefault: this.$route.query.isDefault,
                    },
                });
            } else {
                if (!this.RP_Visible("OPEN_INTENT")) {
                    this.$router.push({
                        name: "botIntent",
                        query: {
                            id: this.$route.query.id,
                            name: this.$route.query.name,
                            refApiKey:
                                this.$router.currentRoute.query.refApiKey,
                            tabIndex: "skill",
                            refAttrType: this.$route.query.refAttrType,
                        },
                    });
                } else {
                    if (!intentId) {
                        this.$router.push({
                            name: "botIntent",
                            query: {
                                id: this.$route.query.id,
                                name: this.$route.query.name,
                                refAttrType: this.$route.query.refAttrType,
                            },
                        });
                    } else {
                        var bid = this.$route.query.bid;
                        var bName = this.$route.query.bName;
                        this.$router.push({
                            name: "intentQa",
                            query: {
                                bid: this.$route.query.bid,
                                bName: this.$route.query.bName,
                                intentId: this.$route.query.intentId,
                                intentName: this.$route.query.intentName,
                                refApiKey:
                                    this.$router.currentRoute.query.refApiKey,
                                refAttrType: this.$route.query.refAttrType,
                            },
                        });
                    }
                }
            }

        },
        //流程中点击意图名称跳转至意图页面
        tobotIntentQa(intentId, intentName) {
            var id = this.$route.query.id;
            var name = this.$route.query.name;
            var bName = this.$route.query.bName;
            var bid = this.$route.query.bid;
            let otherIntentNameList = ['人工客服不在线', '咨询者问题机器人不识别', '咨询者发送图片', '咨询者发送视频'];
            if (otherIntentNameList.indexOf(intentName) != -1) {
                console.log(5084, intentName);
                let tree =  this.nodeTree;
                sessionStorage.setItem('IntentIsRet',1);
                // this.nodeTree.forEach((skillItem, skillIndex) => {
                //     skillItem.children.forEach((classItem, classIndex) => {
                //         classItem.children.forEach(
                //             (intentItem, intentIndex) => {
                //                 if (
                //                     intentItem.id == intentId
                //                 ) {
                //                     console.log(5094, skillItem, classItem, skillItem.id, classItem.id);
                //                     sessionStorage.setItem('_activeSkill', skillItem.id);
                //                     sessionStorage.setItem('_activeSkillList', JSON.stringify(tree));
                //                     sessionStorage.setItem('_activeClass', classItem.id);
                //                     sessionStorage.setItem('_activeClassList', JSON.stringify(skillItem.children));
                //                 }
                //             }
                //         );
                //     });
                // });
                console.log(5104, !!this.$route.query.bName);
                this.$router.push({
                    name: "botIntent",
                    query: {
                        id: this.$route.query.id,
                        name: !!this.$route.query.bName? this.$route.query.bName:this.$route.query.name,
                        fromIntentID: intentId,
                    },
                });
            } else {
                if (bid && intentName != "咨询者问题机器人不识别") {
                    this.$router.push({
                        name: "intentQa",
                        query: {
                            bid: this.$route.query.bid,
                            bName: this.$route.query.bName,
                            intentId: intentId,
                            intentName: intentName,
                        },
                    });
                } else if (id && intentName != "咨询者问题机器人不识别") {
                    this.$router.push({
                        name: "intentQa",
                        query: {
                            bid: this.$route.query.id,
                            bName: this.$route.query.name,
                            intentId: intentId,
                            intentName: intentName,
                        },
                    });
                }
            }

        },
        //页面加载时滚动到指定意图
        rollToIntent() {
            let refApiKey = this.$router.currentRoute.query.refApiKey;
            var rollToId = "intentSpan" + this.$route.query.intentId;
            this.rollToIntentId = this.$route.query.intentId;
            if (
                this.$route.query.intentId &&
                refApiKey !==
                    apiKeys[process.env.NODE_ENV].SAMPLE_BOT_API_KEY
            ) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        document.querySelector("#" + rollToId).scrollIntoView({
                            behavior: "smooth", // 平滑过渡
                            block: "start", // 上边框与视窗顶部平齐。默认值
                        });
                    }, 500);
                });
                this.nodeTree.forEach((skillItem, skillIndex) => {
                    skillItem.children.forEach((classItem, classIndex) => {
                        classItem.children.forEach(
                            (intentItem, intentIndex) => {
                                if (
                                    intentItem.id == this.$route.query.intentId
                                ) {
                                    this.closeIntentNodeList(
                                        skillIndex,
                                        classIndex,
                                        intentIndex,
                                        true
                                    );
                                }
                            }
                        );
                    });
                });
                this.nodeTree = [...this.nodeTree];
            } else {
                if (this.$route.query.toBottom) {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            document
                                .querySelector("#" + "endNode")
                                .scrollIntoView({
                                    behavior: "smooth", // 平滑过渡
                                    block: "end", // 上边框与视窗顶部平齐。默认值
                                });
                        }, 500);
                    });
                }
            }
        },
        //初次登陆引导层
        guide() {
            //this.driver.defineSteps(steps)
            this.driver.defineSteps([
                {
                    element: "#start-info",
                    popover: {
                        title: "开始节点",
                        description:
                            "您已经进入机器人对话流程设计页面，机器人从开始节点进行启动，您可以点击开始按钮进行欢迎语等设置",
                        position: "right",
                    },
                    /* onNext: () => {
              this.configAction(0, 'start', 0, '开始')
            } */
                },
                {
                    element: "#skillName",
                    popover: {
                        title: "知识库",
                        description:
                            "机器人的知识库是通过层级结构进行存放和展示<br>比如：企业内部服务机器人，可以把财务、人力资源不同部门的知识存放在不同的知识库，方便进行分权限进行管理",
                        position: "right",
                    },
                },
                {
                    element: "#typeName",
                    popover: {
                        title: "意图分类",
                        description:
                            "知识库下的知识是以意图的形式进行管理，为了方便管理意图，对意图进行了分类",
                        position: "right",
                    },
                },
                {
                    element: "#intentNames",
                    popover: {
                        title: "意图",
                        description:
                            "一个机器人就是由多个意图组成，机器人的意图越多，也代表着机器人能够识别的问题越多，越聪明。<br><br><b>单轮意图</b><br>当我们给意图进行回答设置时，有的意图如果机器人识别到了，可以直接回复一个答案，我们称之为单轮意图<br><br><b>多轮意图</b><br>如果没法直接回复答案，需要和用户进行一些信息确认，我们称这个意图为多轮意图",
                        position: "right",
                    },
                    onNext: () => {
                        this.closeIntentNodeList(0, 0, 0);
                        setTimeout(() => {
                            this.guideNode();
                        }, 1000);
                    },
                },
                {
                    element: ".intent-node",
                    popover: {
                        title: "流程节点",
                        description:
                            "现在您看到的意图是：询问手机下载客户端的方法，因为手机一般分为安卓和苹果操作系统的手机，所以下载客户端的方法也不太一样，那我们就需要和用户确认，使用的手机类型是哪种<br><br>所以配置的方法为：<br>（1）增加一个节点用来判断手机类型<br>（2）继续增加两个节点，分别配置安卓和苹果手机的下载方法",
                        position: "right",
                    },
                },
            ]);
            this.driver.start();
        },
        guideNode() {
            //this.driver.defineSteps(steps)
            this.driver.defineSteps([
                {
                    element: ".intent-node",
                    popover: {
                        title: "流程节点",
                        description:
                            "现在您看到的意图是：询问手机下载客户端的方法，因为手机一般分为安卓和苹果操作系统的手机，所以下载客户端的方法也不太一样，那我们就需要和用户确认，使用的手机类型是哪种<br><br>所以配置的方法为：<br>（1）增加一个节点用来判断手机类型<br>（2）继续增加两个节点，分别配置安卓和苹果手机的下载方法<br><br>点击节点名称进入节点配置页面",
                        position: "right",
                    },
                },
            ]);
            this.driver.start();
        },
        // 获取元素高度
        getEleHeight(type) {
            // type 1 副元素高度 2 子元素高度
            this.$nextTick(() => {
                setTimeout(() => {
                    let that = this;
                    that._$(".left-border-replace").height(0);
                    that._$(".left-border-replace").each(function () {
                        that._$(this).height(
                            that._$(this).parent().height() -
                                that
                                    ._$(this)
                                    .parent()
                                    .next()
                                    .children()
                                    .last()
                                    .height() +
                                50
                        );
                    });
                }, 1);
            });
        },
        // 通过粘贴直接创建回复节点
        addCopyitem(type, data) {
            setTimeout(() =>{
                if(type == 'image') {
                    for (let index = 0; index < data.length; index++) {
                        setTimeout(() => {
                            const url = data[index];
                            this.addComponents('answer_image', () =>{
                                let count = this.actionList.length - 1
                                this.actionList[count].content.url = url
                            })
                        }, 10);
                    }
                }
                if(type == 'video') {
                    for (let index = 0; index < data.length; index++) {
                        setTimeout(() => {
                            let file = data[index]
                            this.addComponents('answer_video', () =>{
                                let count = this.actionList.length - 1
                                this.$refs['aVideo' + count][0].aliyunOssRequest({
                                    file: file
                                }, true)
                            })
                        }, 10);
                    }
                }
            }, 20)
        },
        addRichText(list, copyHtml) {
            this.addComponents('answer_rich_text', async () =>{
                    let index = this.actionList.length - 1
                    await this.$refs['aCkE_' + index][0].fileInsert(list)
                    this.$refs['aCkE_' + index][0].strToModel(copyHtml)
                })
        },
        // 获取用户分组list
        getAccountGroupList(){
             this.$http.get(this.requestUrl.accountGroup.getAccountGroupList).then(
                (res) => {
                    if (res.data.code === "0") {
                        this.custonerOptions = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                }
            );
        }


    },
    created() {
        // 点击添加节点
        Bus.$on("addNodeHandle", (intentId, parentId, indexArr) => {
            this.addNodeHandleFirst(intentId, parentId, indexArr, "child");
        });

        // 确认添加节点
        Bus.$on(
            "confirmAddNodeHandle",
            (intentId, parentId, indexArr, value) => {
                this.confirmAddNodeHandleFirst(
                    intentId,
                    parentId,
                    indexArr,
                    value,
                    "child"
                );
            }
        );

        // 放弃添加节点
        Bus.$on("concelAddNodeHandle", (intentId, parentId, indexArr) => {
            this.concelAddNodeHandleFirst(
                intentId,
                parentId,
                indexArr,
                "child"
            );
        });

        // 点击删除节点
        Bus.$on("deleteNodeHandle", (intentId, id, name) => {
            this.deleteNodeHandleFirst(intentId, id, name);
        });

        // 点击删除节点
        Bus.$on("configAction", (intentId, id, skillId, name, indexArr) => {
            this.configAction(intentId, id, skillId, name, indexArr);
        });

        // 确认移动节点
        Bus.$on("confirmMoveNodeNew", (indexArr) => {
            this.confirmMoveNodeNew(indexArr);
        });
        this.maxLengthLimit = localStorage.getItem('_mainId') === '50a9fb43e7084fdfa450e528c8770d4e' && this.$route.query.bid === '1951' ? 999 : 40;
    },
    mounted() {
        this.botVersion = localStorage.getItem("_bot_version");
        this.$nextTick(() => {
            this.showTip = true;
        });
        if (this.isPart == 1) {
            this.getOldAndNewIntentFlow();
        }
        this.getWebhookEntityTree();
        this.getWebhookBaseList();

        this.getWebhookList();
        this.allEntitys = this.loadAllEntity();

        this.driver = new Driver({
            className: "scoped-class", // className to wrap driver.js popover
            animate: true, // Animate while changing highlighted element
            opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
            padding: 10, // Distance of element from around the edges
            allowClose: true, // Whether clicking on overlay should close or not
            overlayClickNext: true, // Should it move to next step on overlay click
            doneBtnText: "完成", // Text on the final button
            closeBtnText: "关闭", // Text on the close button for this step
            nextBtnText: "下一步", // Next button text for this step
            prevBtnText: "上一步", // Previous button text for this step
        });
        //this.driver = new Driver()
        //页面加载完成后出现引导层
        let refApiKey = this.$router.currentRoute.query.refApiKey;
        let that = this;
        this.$nextTick(() => {
            // 确保dom异步加载完毕
          console.log('apiKeys',process.env)
            if (
                refApiKey ===
                apiKeys[process.env.NODE_ENV].SAMPLE_BOT_API_KEY
            ) {
                that.guidTaskId = setTimeout(() => {
                    this.guide();
                }, 4000);
            }
        });

        // 获取用户分组list
        this.getAccountGroupList()
        //
        if(!this.isBotLink){
            this.$http.get(
                this.requestUrl.bot.getBotInfo + "/" + this.$route.query.id
            ).then((res) => {
                if (res.code === "0") {
                    this.customerServiceOfflineIntentId = String(
                        res.data.setting.customerServiceOfflineIntentId
                    );
                } else {
                    this.$message.error(res.message);
                }
            });
        }

        if(this.$route.query.isHeathly == 1){
            this.activeBot = Number(this.$route.query.id);
            this.configAction(0, 'start', 0, '开始')
        }
    },
};
</script>
<style scoped lang="less">
@import "./../../assets/less/channel/ChatFlow.less";
@import "./../../assets/less/channel/qEditor.less";
.btn-list-cell-text-rich {
    display: flex !important;
    align-items: center !important;
    p {
        display: flex;
        align-items: center;
        .ask-component-placeholder{
            height: 16px;
            line-height: 16px;
            font-size: 12px;
            margin-right: 3px;
        }
    }
}

.el-autocomplete-suggestion {
    z-index: 3600 !important;
}

.ck-content {
    word-break: break-all;
    // ul,
    // ol,
    // li {
    //     list-style-type: none;
    // }
    ul {
        display: block !important;
        list-style-type: disc !important;
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        padding-inline-start: 40px !important;
    }
    ul,
    ul li {
        list-style-type: disc !important;
    }

    ol {
        display: block !important;
        list-style-type: decimal !important;
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        padding-inline-start: 40px !important;
    }

    ol,
    ol li {
        list-style-type: decimal !important;
    }
}
</style>
